import React, { useState, useEffect } from "react";

import {
  Select,
  Button,
  Row,
  Col,
  Form,
  Input,
  Modal,
  Breadcrumb,
  Card,
  Space,
} from "antd";

import {
  PlusCircleFilled,
  CarOutlined,
  CloudServerOutlined,
  ExperimentOutlined,
  GlobalOutlined,
  GroupOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { 
  addLocation,
  selectLocationList,
  selectSingleLocation
} from "../../redux/locationSlice";

import Device from "./Device";

const { Option } = Select;

const DeviceHeader = () => {
  let locations = useSelector(selectLocationList);
  let singleLocationData = useSelector(selectSingleLocation);
  const [updateList, setUpdateList] = useState(false);
  const [bluetooth, SetBlutooth] = useState(false);
  //const [selectLocation, setLocation] = useState(locations[0]?.id);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalAddDevice, setModalAddDevice] = useState(false);
  const [isModalAdd, setModalAdd] = useState(false);
  const [isModalForm, setModalForm] = useState(false);
  const [newDeviceData, setNewDeviceData] = useState({
    device_name: "",
    bluetooth_enabled: "",
    auto_detected: "",
    device_category_id: "",
    device_type_id: "",
    is_connected: "",
    location_id: ""
  })
  const [selectArea, setArea] = useState("");
  const [selectZone, setZone] = useState("");
  const [selectDevice, setDevice] = useState("");
  const [addZoneForm] = Form.useForm();
  const dispatch = useDispatch();
  //const filtered = locations.filter((x) => x.id === selectLocation);
  
  useEffect(() => {
    setNewDeviceData({
      ...newDeviceData,
      location_id: singleLocationData?.location_id ? singleLocationData?.location_id : locations[0]?.location_id
    })
  }, [])

  useEffect(() => {
    setNewDeviceData({
      ...newDeviceData,
      location_id: singleLocationData?.location_id
    })
  }, [singleLocationData])

  const onFinish = (event) => {
    dispatch(
      addLocation({
        id: 1,
        locationName: event.locationName,
        address: `${event.address_1} ${event.address_2}, ${event.city} ${event.state} ${event.post_code} `,
      })
    );
    setModalForm(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // function onSelectLocation(value) {
  //   setLocation(value);
  // }

  function handleAddDevice() {
    setIsModalVisible(false);
    setModalAdd(true);
    setNewDeviceData({
      ...newDeviceData,
      bluetooth_enabled: 1,
    })
  }

  function handleAdd() {
    setModalAdd(false);
    setModalAddDevice(true);
    setNewDeviceData({
      ...newDeviceData,
      auto_detected: 0
    })
  }

  const handleSensor = (e,a) => {
    console.log(a)
    console.log(e.target.outerText)
    setModalAddDevice(false);
    setModalForm(true);
    setNewDeviceData({
      ...newDeviceData,
      device_category_id: 2,
      device_type_id: a
    })
  };

  const handleController = (a) => {
    setModalAddDevice(false);
    setModalForm(true);
    setNewDeviceData({
      ...newDeviceData,
      device_category_id: 3,
      device_type_id: a
    })
  };

  const handleLight = (a) => {
    setModalAddDevice(false);
    setModalForm(true);
    setNewDeviceData({
      ...newDeviceData,
      device_category_id: 1,
      device_type_id: a
    })
  };
  
  //console.log("filtered", filtered);
  return (
    <div>
      <div class="topnav-scene">
        <div className="border-top"></div>
        <div style={{ padding: "0px 15px 15px 15px", marginLeft: "10px" }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              {singleLocationData?.location_name ? singleLocationData?.location_name : locations[0]?.location_name}
            </Breadcrumb.Item>
            <Breadcrumb.Item>Device</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col span={2}>
              <div className="header-title">Devices</div>
            </Col>
            <Col span={3}>
              <Button
                type="link"
                onClick={() => showModal()}
                icon={<PlusCircleFilled />}
              />
            </Col>
          </Row>
        </div>
      </div>
      <Device />
      <Modal width={400} centered visible={isModalVisible} footer={null}>
        <h4>Turn on Bluetooth to find Bluetooth devices nearby?</h4>

        <br />
        <Button
          style={{ width: "130px", margin: "10px 20px 0px 30px" }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          style={{
            width: "130px",
          }}
          type="primary"
          onClick={handleAddDevice}
        >
          Turn On
          {/* <Link to={`/devices/add_device`}>Turn On</Link> */}
        </Button>
      </Modal>

      <Button
        onClick={showModal}
        style={{
          width: "150px",
          margin: "26px 0px 0px 50px",
        }}
        type="link"
      >
        Add a device manually
      </Button>
      <Modal
        width={500}
        title="Select Device"
        centered
        visible={isModalAddDevice}
        footer={null}
        closable={false}
      >
        <Card>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <h2>Lighting</h2>
            </Col>
            <Col span={12}>
              <Button
                icon={<GroupOutlined />}
                style={{ width: "200px", height: "50px" }}
                onClick={(e) => handleLight(e,1)}
              >
                Ceiling Light
              </Button>
            </Col>
            <Col span={12}>
              <Button
                icon={<CarOutlined />}
                style={{ width: "200px", height: "50px" }}
                onClick={(e) => handleLight(e,2)}
              >
                Light Bulb
              </Button>
            </Col>
            <Col span={24}>
              <h2>Sensor</h2>
            </Col>
            <Col span={12}>
              <Button
                icon={<CloudServerOutlined />}
                style={{ width: "200px", height: "50px" }}
                onClick={(e) => handleSensor(e,1)}
              >
                Motion Sensor
              </Button>
            </Col>

            <Col span={12}>
              <Button
                icon={<ExperimentOutlined />}
                style={{ width: "200px", height: "50px" }}
                onClick={(e) => handleSensor(e,2)}
              >
                Light Sensor
              </Button>
            </Col>
            <Col span={24}>
              <h2>Gateway</h2>
            </Col>
            <Col span={12}>
              <Button
                icon={<GlobalOutlined />}
                style={{ width: "200px", height: "50px" }}
                onClick={(e) => handleController(e,1)}
              >
                Router
              </Button>
            </Col>
          </Row>
        </Card>
      </Modal>

      <Modal
        title="Create New Area"
        centered
        visible={isModalAdd}
        footer={null}
        closable={false}
      >
        <Card style={{ textAlign: "center", marginTop: "10%" }}>
          <Space direction="vertical" size={20}>
            <img src="https://i.ibb.co/jZL60MG/sensor.png" alt="sensor" />
            {/* <img src={Sensor} alt="sensor" /> */}
            <Button type="link">
              <h3
                style={{
                  color: "#1d83f7",
                  textDecoration: "underline",
                }}
              >
                What should I do if I cannot scan for a devices?
              </h3>
            </Button>
          </Space>
        </Card>

        <Button type="link" onClick={handleAdd}>
          Add a devices manually?
        </Button>
      </Modal>

      <Modal width={400} centered visible={isModalForm} footer={null}>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
        >
          Rename device
          <Form.Item name="zoneName">
            <Input placeholder="Zone Name" />
          </Form.Item>
          <Space>
            <Form.Item>
              <Button
                style={{ width: "130px", margin: "0px 20px 0px 20px" }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                style={{
                  width: "130px",
                }}
                type="primary"
                htmlType="submit"
              >
                Update
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </div>
  );
};

export default DeviceHeader;
