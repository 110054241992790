import axios from "axios"

const url = process.env.REACT_APP_APP_API_ENDPOINT

export const userCreateService = async ({
    token,
    email, 
    name, 
    role,
    password, 
    password_confirmation,
    setError,
    setLoading
    }) => {
    try {
        let result = await axios.post(url + '/api/v1/users', 
        { 
            email, 
            name, 
            role,
            password, 
            password_confirmation
        },{
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.data.success){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.errors.email[0])
    }
}

export const userListService = async ({
    token, 
    setError,
    setLoading
}) => {
    try {
        let result = await axios.get(url + '/api/v1/users', {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.data){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const userProfileService = async ({token, id, setLoading, setError}) => {
    try {
        let result = await axios.get(url + `/api/v1/users/${id}`, {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.data){
            console.log(result.data)
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const userDeleteService = async ({token, id, setLoading, setError}) => {
    try {
        let result = await axios.delete(url + `/api/v1/users/${id}`, {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.data){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const userRoleChangeService = async ({token, roles, id, setLoading, setError}) => {
    try {
        let result = await axios.post(url + `/api/v1/user/change-role/${id}`, 
        {
           roles
        },{
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.data){
            setLoading(false)
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const userLocationAssignService = async ({ token, user_id, location_id, setLoading, setError }) => {
    try {
        let result = await axios.post(url + '/api/v1/user/assign-location', 
        {
            user_id,
            location_id
        },{
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}