import axios from "axios"
const url = process.env.REACT_APP_APP_API_ENDPOINT

export const assignLocationService = async ({token,location_id, user_id, setError, setLoading}) => {
    try {
        let result = await axios.post( url + '/api/v1/user/assign-location', {
            location_id,
            user_id
        },
        {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result?.status){
           return result?.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const unAssignLocationService = async ({token,location_id, user_id, setError, setLoading}) => {
    try {
        let result = await axios.post( url + '/api/v1/user/unassign-location', {
            location_id,
            user_id
        },
        {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result?.status){
           return result?.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}


export const listOfUserLocationService = async ({ token, id, setError, setLoading }) => {
    try {
        let result = await axios.get( url + '/api/v1/list-assigned-location-users',
        {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            },
            params: { location_id: id }
        })
        if(result?.status){
           return result?.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.errors.location_id[0])
    }
}


export const listOfUserAreaService = async ({ token, id, setError, setLoading }) => {
    try {
        let result = await axios.get( url + '/api/v1/list-assigned-area-users',
        {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            },
            params: { area_id: id }
        })
        if(result?.status){
           return result?.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.errors.area_id[0])
    }
}

export const assignAreaService = async ({token, area_id, user_id, setError, setLoading}) => {
    try {
        let result = await axios.post( url + '/api/v1/user/assign-area', {
            area_id,
            user_id
        },
        {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result?.status){
           return result?.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const unAssignAreaService = async ({token, area_id, user_id, setError, setLoading}) => {
    try {
        let result = await axios.post( url + '/api/v1/user/unassign-area', {
            area_id,
            user_id
        },
        {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result?.status){
           return result?.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const listOfUserZoneService = async ({ token, id, setError, setLoading }) => {
    try {
        let result = await axios.get( url + '/api/v1/list-assigned-zone-users',
        {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            },
            params: { zone_id: id }
        })
        if(result?.status){
           return result?.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.errors.zone_id[0])
    }
}

export const assignZoneService = async ({token, zone_id, user_id, setError, setLoading}) => {
    try {
        let result = await axios.post( url + '/api/v1/user/assign-zone', {
            zone_id,
            user_id
        },
        {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result?.status){
           return result?.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const unAssignZoneService = async ({token, zone_id, user_id, setError, setLoading}) => {
    try {
        let result = await axios.post( url + '/api/v1/user/unassign-zone', {
            zone_id,
            user_id
        },
        {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result?.status){
           return result?.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}