import React, { useState, useEffect } from "react";
import axios from "axios";

// import { getToken } from "../../../utils/AuthService";
import { Form, Modal, Slider, Space, Dropdown, Table } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { addZone, renameZoneTitle } from "../../redux/zoneSlice";
import { Link } from "react-router-dom";

import { useHistory } from "react-router-dom";
import BarGraph from "../../components/Statistic/BarGraph";
import EditDeviceSetting from "../../components/DeviceComponent/EditDeviceSetting";

import DeviceSetting from "./../Location/LocationInfo/Device";
import "./Device.css";

const Device = () => {
  const history = useHistory();
  const columns = [
    {
      title: "Area",
      dataIndex: "area",
      filters: [
        {
          text: "Area #1",
          value: "Area #1",
        },
        {
          text: "Area #2",
          value: "Area #2",
        },
        {
          text: "Area #3",
          value: "Area #3",
        },
      ],
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.area.indexOf(value) === 0,
      sorter: (a, b) => a.area.length - b.area.length,
      sortDirections: ["descend"],
    },
    {
      title: "Zone",
      dataIndex: "zone",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.zone - b.zone,
    },
    {
      title: "Device Type",
      dataIndex: "device_type",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.device_type - b.device_type,
    },
    {
      title: "Energy Use Today",
      dataIndex: "energy_use",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.energy_use - b.energy_use,
    },
    {
      title: "Energy Use Last 7",
      dataIndex: "energy_use_7days",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.energy_use_7days - b.energy_use_7days,
    },
    {
      title: "Energy Use Last 30",
      dataIndex: "energy_use_30days",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.energy_use_30days - b.energy_use_30days,
    },
    {
      title: "Energy Use YTD",
      dataIndex: "energy_use_ytd",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.energy_use_ytd - b.energy_use_ytd,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <EditOutlined onClick={changePage} />
          <DeleteOutlined />
        </>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      area: "Area #3",
      zone: "Zone #1",
      device_type: "Dimmer A",
      energy_use: "49 kw.h",
      energy_use_7days: "49 kw.h",
      energy_use_30days: "49 kw.h",
      energy_use_ytd: "49 kw.h",
    },
    {
      key: "2",
      area: "Area #2",
      zone: "Zone #2",
      device_type: "Dimmer B",
      energy_use: "49 kw.h",
      energy_use_7days: "49 kw.h",
      energy_use_30days: "49 kw.h",
      energy_use_ytd: "49 kw.h",
    },
    {
      key: "3",
      area: "Area #1",
      zone: "Zone #3",
      device_type: "Dimmer C",
      energy_use: "49 kw.h",
      energy_use_7days: "49 kw.h",
      energy_use_30days: "49 kw.h",
      energy_use_ytd: "49 kw.h",
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    //console.log("params", pagination, filters, sorter, extra);
  }

  const changePage = () => {
    let path = `edit-device`;
    history.push(path);
  };

  return (
    <div className="content-layout">
      <h1 style={{ fontWeight: "bold", color: "#054792" }}>List of Devices</h1>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        onChange={onChange}
      />
    </div>
  );
};

export default Device;
