import { configureStore } from "@reduxjs/toolkit";
import deviceReducer from './deviceSlice';
import zoneReducer from "./zoneSlice";
import locationReducer from "./locationSlice";
import areaReducer from "./areaSlice";
import userReducer from "./userSlice";
import appUtilityReducer from "./appUtilitySlice"

export default configureStore({
  reducer: {
    devices: deviceReducer,
    zones: zoneReducer,
    locations: locationReducer,
    areas: areaReducer,
    user: userReducer,
    appUtility: appUtilityReducer
  },
});
