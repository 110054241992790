
import axios from "axios"
const url = process.env.REACT_APP_APP_API_ENDPOINT

export const zoneListService = async ({token, setLoading, setError }) => {
    try {
        let result = await axios.get(url + '/api/v1/zones', {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.status){
           return result.data
        }
    }catch(err){
        console.log(err.response)
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const zoneSingleService = async ({token, id, setLoading, setError }) => {
    try {
        let result = await axios.get(url + `/api/v1/zones/${id}`, {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.status){
           return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const zoneCreateService = async ({
    token,
    zone_name,
    area_id,
    setLoading,
    setError
    }) => {
    try {
        let result = await axios.post(url + '/api/v1/zones', 
        { 
            zone_name,
            area_id
        },{
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err?.response?.data.errors?.zone_name[0])
    }
}


export const zoneUpdateService = async ({
    token,
    id,
    zone_name,
    setError,
    setLoading
    }) => {
    try {
        let result = await axios.put(url + `/api/v1/zones/${id}`, 
        { 
            zone_name
        },{
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const zoneDeleteService = async ({ token,id,setLoading,setError }) => {
    try {
        let result = await axios.delete(url + `/api/v1/zones/${id}`, {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const zoneFindByIDService = async ({token,id,setLoading,setError}) => {
    try {
        let result = await axios.get(url + `/api/v1/zones/${id}`, {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const zoneSettingsCreate = async ({
    token,
    color_temperature,
    brightness_level,
    max_trim_level,
    zone_id
    }) => {
    try {
        let result = await axios.post(url + '/api/v1/zone-settings', 
        { 
            color_temperature,
            brightness_level,
            max_trim_level,
            zone_id
        },{
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        console.log(err.response)
        //setLoading(false)
        //setError(err?.response?.data.errors?.zone_name[0])
    }
}

export const zoneSettings = async ({ token, zone_id }) => {
    try {
        let result = await axios.get(url + `/api/v1/zone-settings`,{
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            },
            params: { zone_id}
        })
        if (result?.data?.status) {
            return result?.data
        }
    }catch (err) {
       // setLoading(false)
        console.log(err)
        //setError(err.response.data.message)
        //setError("Can't create area-settings")
    }
}

export const zoneOccupancyCreate = async ({ 
    token, 
    zone_id, 
    type, 
    vacant_time, 
    dim_to_level,
    hold_time_id 
}) => {
    try {
        let result = await axios.post(url + `/api/v1/zone-sensor-control`,{
                zone_id,
                type,
                vacant_time,
                dim_to_level,
                hold_time_id
            },{
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if (result?.data?.status) {
            return result?.data
        }
    }catch (err) {
       // setLoading(false)
        console.log(err.response)
        //setError(err.response.data.message)
        //setError("Can't create zone-settings")
    }
}

export const zoneDaylightCreate = async ({ 
    token, 
    zone_id, 
    type, 
    vacant_time, 
    dim_to_level,
    hold_time_id,
    sensitivity,
    lux_min,
    lux_max,
    max_trim_level
}) => {
    try {
        let result = await axios.post(url + `/api/v1/zone-sensor-control`,{
                zone_id,
                type,
                vacant_time,
                dim_to_level,
                hold_time_id,
                sensitivity,
                lux_min,
                lux_max,
                max_trim_level
            },{
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if (result?.data?.status) {
            return result?.data
        }
    }catch (err) {
       // setLoading(false)
        console.log(err.response)
        //setError(err.response.data.message)
        //setError("Can't create zone-settings")
    }
}

export const zoneOccupancyDaylightGet = async ({ token, type, zone_id }) => {
    try {
        let result = await axios.get(url + `/api/v1/zone-sensor-control`,{
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            },
            params: { 
                zone_id,
                type
            }
        })
        if (result?.data?.status) {
            return result?.data
        }
    }catch (err) {
       // setLoading(false)
        console.log(err.response.data.message)
        //setError(err.response.data.message)
        //setError("Can't create area-settings")
    }
}