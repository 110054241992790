import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { selectUser } from "../redux/userSlice";
import { getToken } from "./AuthService";

// handle the public routes
function PublicRoute({ component: Component, ...rest }) {
  const user = useSelector(selectUser);
  return (
    <Route
      {...rest}
      render={(props) =>
        !user ? <Component {...props} /> : <Redirect to={{ pathname: "/" }} />
      }
    />
  );
}

export default PublicRoute;
