import React, { useState } from "react";
import {  Button, Modal, Space } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";

import LocationCreateForm from "../../components/Location/LocationCreateForm";
const AddNewLocation = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Space direction="vertical" size={20}>
          <img src="https://i.ibb.co/0cSbn29/not-found.png" alt="not-found" />
          <h2 style={{ fontWeight: "bold" }}>No Location</h2>
          <p>There is no location, please add one</p>
          <Button
            type="primary"
            onClick={showModal}
            icon={<PlusCircleFilled />}
          >
            Add Location
          </Button>
        </Space>
      </div>

      <Modal width={400} centered visible={isModalVisible} footer={null}>
        <LocationCreateForm  
            setModal={setIsModalVisible} />
      </Modal>
    </>
  );
};

export default AddNewLocation;
