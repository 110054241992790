import React, { useState, useEffect } from "react";

import { 
  Select, 
  Button, 
  Row, 
  Col, 
  Form, 
  Input, 
  Modal, 
  Breadcrumb 
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import {  HomeOutlined } from "@ant-design/icons";

import "./Location.css";

import Location from "./Location";
import Area from "./LocationInfo/Area";
import Zone from "./LocationInfo/Zone";
import Device from "./LocationInfo/Device";
// Auth-service
import { getUser } from "../../utils/AuthService";

// Location-form UI
import LocationCreateForm from "../../components/Location/LocationCreateForm";
import AreaForm from "../../components/Area/AreaForm";
import ZoneForm from "../../components/Zone/ZoneForm";
import DeviceForm from "../../components/Device/DeviceForm";

// Redux function
import { selectUserType } from "../../redux/userSlice";
import { 
  locationController,
  onResetLocation,
  onResetArea,
  onResetZone,
  onLocationSelect,
  onAreaSelect,
  onZoneSelect
} from "../../redux/appUtilitySlice";
import { selectLocationList, selectSingleLocation } from "../../redux/locationSlice";
import { selectSingleArea } from "../../redux/areaSlice";
import { selectSingleZone } from "../../redux/zoneSlice" ;

const { Option } = Select;

const LocationHeader = () => {
  const [isUpdateModal, setIsUpdateModal] = useState(false)
  const [isAreaUpdateModal, setIsAreaUpdateModal] = useState(false)
  const [isZoneUpdateModal, setIsZoneUpdateModal] = useState(false)
  const [isDeviceUpdateModal, setIsDevicUpdateModal] = useState(false)
  const [selectBreadcrums, setBreadcrums] = useState("location");
  
  // const [addZoneForm] = Form.useForm();
   const dispatch = useDispatch();
  const user = getUser();
  let locations = useSelector(selectLocationList);
  let singleLocationData = useSelector(selectSingleLocation);
  let singleAreaData = useSelector(selectSingleArea);
  let singleZoneData = useSelector(selectSingleZone);
  let userType = useSelector(selectUserType);
  let controllerLocation = useSelector(locationController);
  const {selectedLocation, selectedArea, selectedZone, selectedDevice, hideDropdown} = controllerLocation;
  const filtered = locations?.filter((x) => x.id === selectedLocation);
  //console.log(singleAreaData)

  useEffect(()=>{
    if( selectedDevice ){
      setBreadcrums('device')
    }else if( selectedZone){
      setBreadcrums('zone')
    }else if(selectedArea){
      setBreadcrums('area')
    }else{
      setBreadcrums('location')
    }
  },[selectedLocation, selectedArea, selectedZone, selectedDevice ])

  const handleUpdate = () => {
    setIsUpdateModal(true)
  }

  const handleAreaUpdate = () => {
    setIsAreaUpdateModal(true)
  }

  const handleZoneUpdate = () => {
    setIsZoneUpdateModal(true)
  }

  const handleDeviceUpdate = () => {
    setIsDevicUpdateModal(true)
  }

  return (
    <div>
      <div className="topnav">
        <div className="border-top"></div>
        <div style={{ padding: "12px" }}>
          <Breadcrumb style={{ marginTop: "10px" }}>
            <>
              {hideDropdown === true && 
              selectedLocation !== null ? (
                <>
                  <Breadcrumb.Item 
                    onClick={() => {
                      setBreadcrums("location");
                      dispatch(onLocationSelect(selectedLocation))
                    }}
                  >
                    {filtered[0]
                      ? filtered[0]?.location_name
                      : locations[0]?.location_name}
                  </Breadcrumb.Item>
                </>
              ): ("")}
              { hideDropdown === true &&
                selectedArea !== null? (
                <>
                  <Breadcrumb.Item
                    onClick={() => {
                      setBreadcrums("area");
                      dispatch(onAreaSelect(selectedArea))
                    }}
                  >
                   {singleAreaData?.area_name}
                  </Breadcrumb.Item>
                </>
              ) : ("")}
              {
               hideDropdown === true && 
               selectedZone !== null ? (
                <>
                  <Breadcrumb.Item
                    onClick={() => {
                      setBreadcrums("zone");
                      dispatch(onZoneSelect(selectedZone))
                    }}
                  >
                    {singleZoneData?.zone_name}
                  </Breadcrumb.Item>
                </>
              ) : ("")}
            </>
          </Breadcrumb>
        </div>

        <div style={{ padding: "0px 15px 15px 15px", marginLeft: "10px" }}>
          <Row>
            <Col span={4}>
              {selectBreadcrums === "device" ? (
                <div className="header-title">Dimmer</div>
              ) : // <div className="header-title">#Location 1</div>
              selectBreadcrums === "area" ? (
                <div className="header-title">
                  {singleAreaData?.area_name}
                </div>
              ) : selectBreadcrums === "zone" ? (
                <div className="header-title">
                  {singleZoneData?.zone_name}
                </div>
              ) :
                (
                  <>
                    {
                       user?.role === userType?.admin || 
                       user?.role === userType?.location_owner  ?
                      (
                        <div className="header-title">
                          {filtered ? filtered[0]?.location_name
                          : locations[0]?.location_name}
                        </div>
                      ) : ("")
                    }
                  </>
                )
              }
            </Col>
            <Col span={3}>
              {
                selectBreadcrums === "device" ? (
                  <div className="header-title">
                     { 
                        user?.role === userType?.admin || 
                        user?.role === userType?.location_owner || 
                        user?.role === userType?.area_owner ||
                        user?.role === userType?.zone_owner
                        ?
                        (<Button 
                          onClick={()=> handleDeviceUpdate()}
                        >Edit</Button>) : ("")
                      }
                  </div>
                ) : selectBreadcrums === "area" ? (
                  <div className="header-title">
                     { 
                        user?.role === userType?.admin || 
                        user?.role === userType?.location_owner  ?
                        (<Button 
                          onClick={()=> handleAreaUpdate()}
                        >Edit</Button>) : ("")
                      }
                  </div>
                ) : selectBreadcrums === "zone" ? (
                    <div className="header-title">
                      { 
                         user?.role === userType?.admin || 
                         user?.role === userType?.location_owner || 
                         user?.role === userType?.area_owner ?
                        (<Button 
                          onClick={()=> handleZoneUpdate()}
                        >Edit</Button>) : ("")
                      }
                    </div>
                ) : (
                  <div className="header-title">
                    {user?.role === userType?.admin && 
                    locations?.length > 0 ?
                      (<Button 
                        onClick={()=> handleUpdate()}
                      >Edit</Button>) : ("")
                    }
                  </div>
                )
              }
            </Col>
          </Row>
          {selectBreadcrums === "location" && 
            locations?.length > 0 ? (
            <p>
              <HomeOutlined />
              &nbsp;&nbsp;
              {filtered[0] ? filtered[0]?.city : locations[0]?.city}
            </p>
          ) : (
            ""
          )}
        </div>

      </div>
      {selectBreadcrums === "device" ? (
        <Device locationInfo={filtered[0] ? filtered[0]: locations[0]} />
      ) : selectBreadcrums === "area" ? (
        <Area locationInfo={filtered[0] ? filtered[0] : locations[0]} />
      ) : selectBreadcrums === "zone" ? (
        <Zone locationInfo={filtered[0] ? filtered[0] : locations[0]} />
      ) : (
        <Location locationInfo={singleLocationData} />
      )}
        <Modal
          title="Location Update"
          width={400}
          centered
          visible={isUpdateModal}
          footer={null}
          closable={false}
          style={{
            borderRadius: "8px",
          }}
        >
          {  user?.role === userType?.admin &&
            <LocationCreateForm 
              tableRecord = {filtered[0] ? filtered[0] : locations[0]}
              setModal={setIsUpdateModal}
            />
          }
        </Modal>
        <Modal
          title="Area Update"
          width={400}
          centered
          visible={isAreaUpdateModal}
          footer={null}
          closable={false}
          style={{
            borderRadius: "8px",
          }}
        >
          <AreaForm 
            tableRecord={singleAreaData} 
            setModal={setIsAreaUpdateModal} 
          />
        </Modal>
        <Modal
          title="Zone Update"
          width={400}
          centered
          visible={isZoneUpdateModal}
          footer={null}
          closable={false}
          style={{
            borderRadius: "8px",
          }}
        >
          <ZoneForm 
            tableRecord={singleZoneData} 
            setModal={setIsZoneUpdateModal} 
          />
        </Modal>
        <Modal
          title="Device Update"
          width={400}
          centered
          visible={isDeviceUpdateModal}
          footer={null}
          closable={false}
          style={{
            borderRadius: "8px",
          }}
        >
          <DeviceForm 
            tableRecord={[]} 
            setModal={setIsZoneUpdateModal} 
          />
        </Modal>
    </div>
  );
};

export default LocationHeader;
