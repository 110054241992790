import React, { useState } from "react";
import axios from "axios";

import moment from "moment";
// import { getToken } from "../../../utils/AuthService";
import { Table, Select, Dropdown, Button, Menu, Switch } from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
  UpOutlined,
  SearchOutlined,
  DashOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";

import "./Scene.css";
const { Option } = Select;

const Scene = () => {
  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
      render: (record) => {
        if (record === "On") {
          return <Switch defaultChecked onChange={onChange} />;
        } else if (record === "Off") {
          return <Switch onChange={onChange} />;
        }
      },
    },
    {
      title: "Demand Name",
      dataIndex: "demand_name",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "Area ",
      dataIndex: "area",
      onFilter: (value, record) => record.area.indexOf(value) === 0,
    },
    {
      title: "Scene Details",
      key: "scene_details",
      render: (text, record) => (
        <Dropdown overlay={selector(record)}>
          {/* < rotate={90} /> */}
          <Button type="default">Adjust</Button>
        </Dropdown>
      ),
    },
  ];

  let selector = (record) => (
    <Menu>
      <Menu.Item key="1">Edit</Menu.Item>
      <Menu.Item key="2">Delete</Menu.Item>
    </Menu>
  );
  function onChange(checked) {
    console.log(`switch to ${checked}`);
  }
  const data = [
    {
      key: "1",
      status: "On",
      demand_name: "All On - Office",
      area: "Entire Area #1",
    },
    {
      key: "2",
      status: "Off",
      demand_name: "All On - Office",
      area: "Entire Area #1",
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }
  return (
    <div className="content-layout">
      <h1 style={{ fontWeight: "bold", color: "#054792" }}>List of Scenes</h1>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        onChange={onChange}
      />
    </div>
  );
};

export default Scene;
