import React, { useState } from "react";
import axios from "axios";

// import { getToken } from "../../../utils/AuthService";
import {
  Form,
  Card,
  Table,
  Dropdown,
  Row,
  Col,
  DatePicker,
  Button,
  Select,
  Menu,
  Space,
  Slider,
  Radio,
  Switch,
  Input,
} from "antd";
import moment from "moment";
import { DashOutlined, PlusCircleFilled } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import { TagsOutlined } from "@ant-design/icons";

const { Option } = Select;
const DeviceControl = () => {
  const [sense, setOnSensor] = useState("");
  const [addZoneForm] = Form.useForm();

  function formatter(value) {
    return `${value}%`;
  }

  const onSensor = (e) => {
    setOnSensor(e.target.value);
  };
  return (
    <>
      <h1 style={{ fontWeight: "bold", color: "#054792" }}>Control Zones</h1>
      <Card>
        <span style={{ fontWeight: "bold" }}>Zone A</span>
        <br />
        <span style={{ fontSize: "10px" }}>Area #1</span>
        <DashOutlined
          rotate={90}
          style={{
            fontSize: "20px",
            color: "#1d83f7",
            margin: "-10px 0px 0px 1000px",
            position: "absolute",
            fontWeight: "bold",
          }}
        />
      </Card>
      <br />
      <Card>
        <span style={{ fontWeight: "bold" }}>Zone B</span>
        <br />
        <span style={{ fontSize: "10px" }}>Area #1</span>
        <DashOutlined
          rotate={90}
          style={{
            fontSize: "20px",
            color: "#1d83f7",
            margin: "-10px 0px 0px 1000px",
            position: "absolute",
            fontWeight: "bold",
          }}
        />
      </Card>
      <br />
      <br />
      <Row gutter={[30, 0]}>
        <Col span={16}>
          <h1 style={{ fontWeight: "bold", color: "#054792" }}>
            Lightning Device Control
          </h1>
          <Card>
            <h1>Setting</h1>
            <h4>
              Color Temperature &nbsp;&nbsp;
              <Select defaultValue="100" style={{ width: 120 }}>
                <Option value="100">100</Option>
                <Option value="200">200</Option>
                <Option value="300">300</Option>
              </Select>
            </h4>
            <Form
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              autoComplete="off"
              style={{ marginTop: "50px", marginLeft: "10px" }}
            >
              BRIGHTNESS
              <Form.Item name="brightness">
                <Slider
                  className="slider"
                  // defaultValue={props.locationInfo.brightness}
                  tipFormatter={formatter}
                />
              </Form.Item>
              MAX TRIM LEVEL
              <Form.Item name="max_trim_level">
                <Slider
                  className="slider"
                  // defaultValue={props.locationInfo.max_trim_level}
                  tipFormatter={formatter}
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={8}>
          <h1 style={{ fontWeight: "bold", color: "#054792" }}>Schedule</h1>
          <Card>
            <span style={{ fontWeight: "bold" }}>No Schedule</span>
            <PlusCircleFilled
              style={{
                fontSize: "20px",
                color: "#1d83f7",
                margin: "0px 0px 0px 200px",
                position: "absolute",
                fontWeight: "bold",
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DeviceControl;
