import React, { useState, useEffect, useMemo  } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Layout,
    Menu,
    Dropdown,
    Select,
    Form,
    Button,
    Modal,
    Input,
    Row,
    Col,
    Card,
    Spin
} from "antd";

// Authentication-Service
import { getToken, getUser } from "../../utils/AuthService";

// Location-Service
import { 
    locationCreateService,
    locationUpdateService,
    locationListService
} from "../../services/location.service";


// Redux-Functoins
import {
    addLocation,
    setLocationList,
    selectLocationList,
    selectTimeZoneList,
    setTimeZoneList,
    updateLocation
} from "../../redux/locationSlice";


const LocationCreateForm = ({ setModal, tableRecord = null }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [locationFrom] = Form.useForm();
    let USTimezones = useSelector(selectTimeZoneList);
    const dispatch = useDispatch();
    const token = getToken();
    const user = getUser()
    const tableRecordData = useMemo(() => tableRecord = tableRecord, [tableRecord]);
    

    const handleLocationCreate = async (values) => {
        setLoading(true)
        const { city, lane_1, lane_2, location_name, state, timezone, zip } = values;
        locationFrom.resetFields();
        const result = await locationCreateService({
            token,
            city,
            lane_1,
            lane_2,
            location_name,
            state,
            timezone,
            zip,
            setError,
            setLoading
        })
        if (result?.status) {
            const result = await locationListService({
                token,
                setError,
                setLoading
            })
            if(result?.status){
                dispatch(
                  setLocationList(result?.data)
                )
                setLoading(false)
                setModal(false)
            }
        }
    }

    const handleCancel = () => {
        setModal(false)
        locationFrom.resetFields();
    };

    const handleLocationUpdate = async(value) => {
        locationFrom.resetFields();
        const { 
            city,
            lane_1,
            lane_2,
            location_name,
            state,
            timezone,
            zip
        } = value;

        const result = await locationUpdateService({
            id: tableRecordData.id,
            token,
            city,
            lane_1,
            lane_2,
            location_name,
            state,
            timezone,
            zip,
            setError,
            setLoading
        })
        if (result?.data) {
            const result = await locationListService({
                token,
                setError,
                setLoading
            })
            if(result?.status){
                dispatch(
                    setLocationList(result?.data)
                )
                setLoading(false)
                setModal(false)
            }
        }
    }


    if (error) {
        setTimeout(
            () => setError(null), 
            3000
        );
        return (
            <>
                <p style={{
                    color: "red",
                    height: "50vh",
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    fontSize: '18px'
                }}>
                    {error}
                </p>
                <Button
                    style={{
                        width: "130px",
                        margin: "30px auto",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </>
        )
    }

    if (tableRecord !== null) {
        return (
            <>
                {loading === false ? (
                    <Form form={locationFrom} onFinish={(value) => handleLocationUpdate(value)}>
                        New Location Name*
                        <Form.Item
                            name="location_name"
                            rules={[
                                {
                                    required: true,
                                    message: "Enter location name",
                                }
                            ]}
                        >
                            <Input defaultValue={tableRecordData.location_name} placeholder="Insert location" />
                        </Form.Item>
                        Lane One*
                        <Form.Item
                            name="lane_1"
                            rules={[
                                {
                                    required: true,
                                    message: "Lane one",
                                }
                            ]}
                        >
                            <Input defaultValue={tableRecordData.lane_1} placeholder="Insert lane one" />
                        </Form.Item>
                        Lane Two*
                        <Form.Item
                            name="lane_2"
                            rules={[
                                {
                                    required: true,
                                    message: "Lane Two",
                                }
                            ]}
                        >
                            <Input defaultValue={tableRecordData.lane_2} placeholder="Insert lane two" />
                        </Form.Item>
                        City
                        <Form.Item
                            name="city"
                            rules={[
                                {
                                    required: true,
                                    message: "City",
                                }
                            ]}
                        >
                            <Input defaultValue={tableRecordData.city} placeholder="Insert city name" />
                        </Form.Item>
                        Zip
                        <Form.Item
                            name="zip"
                            rules={[
                                {
                                    required: true,
                                    message: "Zip",
                                }
                            ]}
                        >
                            <Input defaultValue={tableRecordData.zip} placeholder="Insert zip code" />
                        </Form.Item>
                        Timezone
                        <Form.Item
                            name="timezone"
                            rules={[
                                {
                                    required: true,
                                    message: "Timezone",
                                }
                            ]}
                        >
                            <Select
                                style={{
                                    width: '100%',
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                }}
                                placeholder="Select Timezone"
                            >
                                {USTimezones?.map((item,index) =>
                                    <Select.Option value={item.value} key={index}>
                                        <span>{item.name}</span>
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                        State
                        <Form.Item
                            name="state"
                            rules={[
                                {
                                    required: true,
                                    message: "State",
                                }
                            ]}
                        >
                            <Input defaultValue={tableRecordData.state} placeholder="Insert state" />
                        </Form.Item>
                        <Button
                            style={{
                                width: "130px",
                                margin: "0px 20px 0px 30px",
                                borderRadius: "8px",
                            }}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{
                                width: "130px",
                                borderRadius: "8px",
                            }}
                            type="primary"
                            htmlType="submit"
                        >
                            Update
                        </Button>
                    </Form>
                ) : (
                    <Card bordered={true} hoverable={false}>
                        <Spin
                            size="large"
                            tip="Loading..."
                            spinning={loading}
                            style={{
                                height: "50vh",
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}
                        />
                    </Card>
                )}
            </>
        )
    }

    return (
        <>
            {loading === false ? (
                <Form form={locationFrom} onFinish={(val) => handleLocationCreate(val)}>
                    New Location Name*
                    <Form.Item
                        name="location_name"
                        rules={[
                            {
                                required: true,
                                message: "Enter location name",
                            }
                        ]}
                    >
                        <Input placeholder="Insert location" />
                    </Form.Item>
                    Lane One*
                    <Form.Item
                        name="lane_1"
                        rules={[
                            {
                                required: true,
                                message: "Lane one",
                            }
                        ]}
                    >
                        <Input placeholder="Insert lane one" />
                    </Form.Item>
                    Lane Two*
                    <Form.Item
                        name="lane_2"
                        rules={[
                            {
                                required: true,
                                message: "Lane Two",
                            }
                        ]}
                    >
                        <Input placeholder="Insert lane two" />
                    </Form.Item>
                    City
                    <Form.Item
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: "City",
                            }
                        ]}
                    >
                        <Input placeholder="Insert city name" />
                    </Form.Item>
                    Zip
                    <Form.Item
                        name="zip"
                        rules={[
                            {
                                required: true,
                                message: "Zip",
                            }
                        ]}
                    >
                        <Input placeholder="Insert zip code" />
                    </Form.Item>
                    Timezone
                    <Form.Item
                        name="timezone"
                        rules={[
                            {
                                required: true,
                                message: "Timezone",
                            }
                        ]}
                    >
                        <Select
                            style={{
                                width: '100%',
                                marginLeft: "0px",
                                marginRight: "0px",
                            }}
                            placeholder="Select Timezone"
                        >
                            {USTimezones?.map((item,index) =>
                                <Select.Option value={item.value} key={index}>
                                    <span>{item.name}</span>
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                    State
                    <Form.Item
                        name="state"
                        rules={[
                            {
                                required: true,
                                message: "State",
                            }
                        ]}
                    >
                        <Input placeholder="Insert state" />
                    </Form.Item>
                    <Button
                        style={{
                            width: "130px",
                            margin: "0px 20px 0px 30px",
                            borderRadius: "8px",
                        }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            width: "130px",
                            borderRadius: "8px",
                        }}
                        type="primary"
                        htmlType="submit"
                    >
                        Create
                    </Button>
                </Form>
            ) : (
                <Card bordered={true} hoverable={false}>
                    <Spin
                        size="large"
                        tip="Loading..."
                        spinning={loading}
                        style={{
                            height: "50vh",
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    />
                </Card>
            )}
        </>
    )
}

export default LocationCreateForm

