import React, { useState } from "react";
import axios from "axios";

// Ant Design
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card,
  Spin,
  Checkbox,
  Typography,
} from "antd";
import {
  UserOutlined,
  KeyOutlined,
  LoginOutlined,
  LockOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";
import "antd/dist/antd.css";

// File
import "./login.css";
import { setUserSession } from "../../utils/AuthService";

const { Title, Text } = Typography;

export default function Register(props) {
  let [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const db_base_url = process.env.REACT_APP_API_URL;

  const handleSubmit = (values) => {
    setLoading(true);
    axios
      .post("/v1/auth/login", {
        email: values.email,
        password: values.password,
      })
      .then((response) => {
        setLoading(false);
        setUserSession(response.data.tokens, response.data.user);
        props.history.push("/");
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response.status === 401) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong. Please try again later.");
        }
      });
  };

  return (
    <Row type="flex" justify="space-around" align="middle" className="wrapper">
      <Col sm={24} md={10} lg={6}>
        <div style={{ textAlign: "center", color: "white" }}>
          <h2 style={{ color: "white", fontWeight: "bold" }}>
            Set Your Favourites!
          </h2>
          <p>
            Gather the lamp you use the most so that you can access them
            quickly.
          </p>
        </div>
        {loading === false ? (
          <Card
            bordered={true}
            hoverable={false}
            style={{ padding: "20px", borderRadius: "10px" }}
          >
            <h2 style={{ fontWeight: "bold" }}> Hello, Friend!</h2>
            <p>Please sign up to continue.</p>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={(val) => handleSubmit(val)}
            >
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Enter your name",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  style={{ width: "250px" }}
                  placeholder="Username"
                />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Enter your email",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  style={{ width: "280px" }}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  style={{ width: "280px" }}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>
                  I've read and agreed to the{" "}
                  <Link to="/area">User Agreement</Link> and{" "}
                  <Link to="/area">Private Policy</Link>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                {error && (
                  <>
                    <small style={{ color: "red" }}>{error}</small>
                    <br />
                  </>
                )}
                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  <LoginOutlined /> Login
                </Button>
                <br />
                <br />
                <Text>
                  Already have an account? <Link to="/login">Sign in.</Link>
                </Text>
              </Form.Item>
            </Form>
          </Card>
        ) : (
          <Card bordered={true} hoverable={false}>
            <Spin size="large" tip="Loading..." spinning={loading} />
          </Card>
        )}
      </Col>
    </Row>
  );
}
