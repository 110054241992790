import { Form, Input, Button, Space, Select, Card } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const sights = {
  Beijing: ["Tiananmen", "Great Wall"],
  Shanghai: ["Oriental Pearl", "The Bund"],
};

const AddAutomation = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Received values of form:", values);
  };

  const handleChange = () => {
    form.setFieldsValue({ sights: [] });
  };

  return (
    <Card className="card-device-large-open">
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
      >
        <h1>When sensor indicated |Vacancy|</h1> <br />
        <Form.List name="sights">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Space key={index} align="baseline">
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        label="Sight"
                        name={[field.name, "sight"]}
                        fieldKey={[field.fieldKey, "sight"]}
                        rules={[{ required: true, message: "Missing sight" }]}
                      >
                        <Select
                          disabled={!form.getFieldValue("area")}
                          style={{ width: 130 }}
                        >
                          {(sights[form.getFieldValue("area")] || []).map(
                            (item, index) => (
                              <Option key={index} value={item}>
                                {item}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label="Price"
                    name={[field.name, "price"]}
                    fieldKey={[field.fieldKey, "price"]}
                    rules={[{ required: true, message: "Missing price" }]}
                  >
                    <Input />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}

              <Form.Item>
                {/* <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add sights
                </Button> */}
                <Card style ={{
                   border: '2px dashed"',
                   borderColor: '#1d83f7',
                   width: '830px',
                   margin: '20px 0px 0px 0px',
                   height: '60px',
                }}>
                  <Space>
                    <PlusCircleOutlined
                      onClick={() => add()}
                      style={{
                        fontSize: "25px",
                        color: "#1d83f7",
                        margin: "15px 0px 0px 0px",
                      }}
                    />
                    <h1 className="title-add-action">Add Action</h1>
                  </Space>
                </Card>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddAutomation;
