import { BrowserRouter, Switch, Route } from "react-router-dom";

import "./App.css";
import LoginPage from "./pages/LoginPage/login";
import LayoutPage from "./layout/layout";
import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";
import Register from "./pages/LoginPage/register";
import ForgotPassword from "./pages/LoginPage/ForgotPassword";
import ResetPassword from "./pages/LoginPage/ResetPassword";
import ChangePassword from "./pages/LoginPage/ChangePassword";

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div>
          <Switch>
            <PublicRoute path="/login" component={LoginPage} />
            <PublicRoute path="/register" component={Register} />
            <PublicRoute path="/forgot_password" component={ForgotPassword} />
            <PublicRoute path="/reset_password" component={ResetPassword} />
            <PrivateRoute path="/change_password" component={ChangePassword} />
            <PrivateRoute path="*" component={LayoutPage} />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}
