import React, { useState, useEffect  } from "react";
import { 
  Table, 
  Card,
  Spin,
  Button,
  Modal
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import { useLocation  } from "react-router-dom";

// Assign-service
import { 
  unAssignLocationService,
  listOfUserLocationService
} from "../../services/assign.service";
import { getToken } from "../../utils/AuthService";

// Redux-Functoins
import { 
  setAssignedUser,
  selectsetAssignedUserList,
  setAssignedLocationId
} from "../../redux/locationSlice";

const AssignedLocation = ({setPageName}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [tableRecord, setTableRecord] = useState(null)
  const [isOpenRemoveModal, setOpenRemoveModal] = useState(false)
  const location = useLocation();
  const token = getToken()
  const dispatch = useDispatch()
  const assigndUser = useSelector(selectsetAssignedUserList)

  useEffect( async () => {
    setLoading(true)
    setPageName('location-assigned')
    const result = await listOfUserLocationService({
      token,
      id: location.state.id,
      setError,
      setLoading
    })
    if(result?.data){
      dispatch(
        setAssignedUser(
          result?.data?.user
        )
      )
    }else{
      dispatch(
        setAssignedUser(
         []
        )
      )
    }

    dispatch(
      setAssignedLocationId(
        location.state.id
      )
    )
    setLoading(false)
  }, [location]);


  const handleRemoveAssignedUser = (record) => {
    setTableRecord(record)
    setOpenRemoveModal(true)
  }

  const handleRemoveEntry = async () => {
    setLoading(true)
    const result = await unAssignLocationService({
        token,
        user_id: tableRecord?.id, 
        location_id: location?.state?.id,
        setLoading, 
        setError 
    })
    if(result?.status){
      const result = await listOfUserLocationService({
        token,
        id: location.state.id,
        setError,
        setLoading
      })
      if(result?.data){
        dispatch(
          setAssignedUser(
            result?.data?.user
          )
        )
      }else{
        dispatch(
          setAssignedUser(
           []
          )
        )
      }
    }
    setLoading(false)
    setOpenRemoveModal(false)
    setTableRecord(null)
  }

  const assignedUserTableColumn = [
    {
      title: "Name",
      dataIndex: "name"
    },
    {
      title: "Role",
      dataIndex: "role"
    },
    {
      title: "Email",
      dataIndex: "email"
    },
    {
      title: "Users Action",
      key: "usersAction",
      render: (text, record) => (
          <Button type="default" onClick={() => handleRemoveAssignedUser(record)}>Remove</Button>
      )
    }
  ]

  if(error){
    setTimeout(
      () => setError(null), 
      3000
    );
    return(
        <p style={{ 
        color: "red",
        height: "100vh", 
        display: 'flex', 
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '18px'
        }}>
        {error}
        </p>
    )
  }

  return (
    <div>
      <div className="content-layout">
        <h1 style={{ fontWeight: "bold", color: "#054792" }}>List of location assigned users</h1>
        {loading === false ? (
            <Table
              columns={assignedUserTableColumn}
              dataSource={assigndUser}
              pagination={false}
            />
          ) : (
            <Card bordered={true} hoverable={false}>
                <Spin 
                    size="large" 
                    tip="Loading..." 
                    spinning={loading}
                    style={{ 
                    height: "50vh", 
                    display: 'flex', 
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center'
                    }}
                />
            </Card>
          )}
      </div>
      <Modal
          width={310}
          centered
          title="Please Confirm"
          visible={isOpenRemoveModal}
          footer={null}
          closable={false}
          okText="Save"
          onCancel={() => {
              setOpenRemoveModal(false)
          }}
          onOk={() => {
              setOpenRemoveModal(false)
          }}
          style={{
          borderRadius: "8px",
          }}
      >
          <Button
              style={{
                  width: "100px",
                  margin: "0px 20px 0px 20px",
                  borderRadius: "8px",
              }}
              onClick={() => {
                  setTableRecord(null)
                  setOpenRemoveModal(false)
              }}
              >
              Cancel
          </Button>
          <Button
              style={{
                  width: "100px",
                  borderRadius: "8px",
              }}
              type="primary"
              onClick={handleRemoveEntry}
              >
              Remove
          </Button>
        </Modal>
    </div>
  );
};

export default AssignedLocation;
