import React, { useState, useMemo  } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Form,
    Button,
    Input,
    Card,
    Spin
} from "antd";

// Authentication-Service
import { getToken, getUser } from "../../utils/AuthService";

// API-Service
import { areaCreateService, areaUpdateService, areaSingleService } from "../../services/area.service";
import { locationSingleService } from "../../services/location.service";

// Redux-Functoins
import { addArea, updateArea, setSingleArea } from "../../redux/areaSlice";
import { userProfile, selectUserProfile } from "../../redux/userSlice";
import { locationController, onLocationSelect } from "../../redux/appUtilitySlice";
import { setSingleLocation } from "../../redux/locationSlice";

const AreaForm = ({ setModal, tableRecord = null }) => {
    
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [areaForm] = Form.useForm();
    
    const dispatch = useDispatch();
    const { selectedLocation } = useSelector(locationController);
    const token = getToken();
    const user = getUser();
    const tableRecordData = useMemo(() => tableRecord = tableRecord, [tableRecord]);

    //const assignedLocation = useSelector(selectUserProfile)
    // useEffect(async() => {
    //     setLoading(true)
    //     const result = await profileService({
    //       token,
    //       setLoading,
    //       setError
    //     })
    //     if(result?.status){
    //         dispatch(
    //             userProfile(result?.user)
    //         )
    //         setLoading(false)
    //     }
    // },[])

    const handleAreaCreate = async (values) => {
        setLoading(true)
        const { 
            area_name
        } = values;
        areaForm.resetFields();

        const result = await areaCreateService({
            token,
            location_id: selectedLocation,
            area_name,
            setError,
            setLoading
        })
        if (result?.data) {
            const result = await locationSingleService({
                setError,
                setLoading,
                token,
                id: selectedLocation
            })
            if(result?.status){
                dispatch(setSingleLocation(result?.data))
               // dispatch(onLocationSelect(selectedLocation))
            }
            setLoading(false)
            setModal(false);
        }
    }

    const handleCancel = () => {
        setModal(false)
        areaForm.resetFields();
    };

    const handleAreaUpdate = async(values) => {
        areaForm.resetFields();
        setLoading(true);
        const { 
            area_name
        } = values;
        const result = await areaUpdateService({
            id: tableRecordData.id,
            token,
            area_name,
            setError,
            setLoading
        });
        if (result?.data) {
            const result = await locationSingleService({
                setError,
                setLoading,
                token,
                id: selectedLocation
            })
            if(result?.status){
                dispatch(setSingleLocation(result?.data))
                // dispatch(onLocationSelect(selectedLocation))
            }
            setLoading(false)
            setModal(false);
            {/*const result = await areaSingleService({
                setError,
                setLoading,
                token,
                id: tableRecordData.id
            })
            if(result?.status){
                dispatch(setSingleArea(result?.data))
                setLoading(false)
                setModal(false);
            }
            dispatch(
                updateArea(
                    result?.data
                )
            );
            setLoading(false)
            setModal(false);*/}
        }
    }

    if (error) {
        setTimeout(
            () => setError(null), 
            3000
        );
        return (
            <>
                <p style={{
                    color: "red",
                    height: "50vh",
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    fontSize: '18px'
                }}>
                    {error}
                </p>
                <Button
                    style={{
                        width: "130px",
                        margin: "30px auto",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </>
        )
    }

    if (tableRecord !== null) {
        return (
            <>
                {loading === false ? (
                <Form form={areaForm} onFinish={(val) => handleAreaUpdate(val)}>
                    Update Area Name*
                    <Form.Item
                        name="area_name"
                        rules={[
                            {
                                required: true,
                                message: "Enter area name",
                            }
                        ]}
                    >
                        <Input defaultValue={tableRecord.area_name} placeholder="Insert area" />
                    </Form.Item>
                    
                    <Button
                        style={{
                            width: "130px",
                            margin: "0px 20px 0px 30px",
                            borderRadius: "8px",
                        }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            width: "130px",
                            borderRadius: "8px",
                        }}
                        type="primary"
                        htmlType="submit"
                    >
                        Update
                    </Button>
                </Form>
        ) : (
            <Card bordered={true} hoverable={false}>
                <Spin
                    size="large"
                    tip="Loading..."
                    spinning={loading}
                    style={{
                        height: "50vh",
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                />
            </Card>
        )}
            </>
        )
    }

    return (
        <>
            {loading === false ? (
                <Form form={areaForm} onFinish={(val) => handleAreaCreate(val)}>
                    New Area Name*
                    <Form.Item
                        name="area_name"
                        rules={[
                            {
                                required: true,
                                message: "Enter area name",
                            }
                        ]}
                    >
                        <Input placeholder="Give area name" />
                    </Form.Item>
                    
                    <Button
                        style={{
                            width: "130px",
                            margin: "0px 20px 0px 30px",
                            borderRadius: "8px",
                        }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            width: "130px",
                            borderRadius: "8px",
                        }}
                        type="primary"
                        htmlType="submit"
                    >
                        Create
                    </Button>
                </Form>
            ) : (
                <Card bordered={true} hoverable={false}>
                    <Spin
                        size="large"
                        tip="Loading..."
                        spinning={loading}
                        style={{
                            height: "50vh",
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    />
                </Card>
            )}
        </>
    )
}

export default AreaForm

