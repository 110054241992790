import React, { useState } from "react";
// Ant Design
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card,
  Spin
} from "antd";

import {
  LoginOutlined,
  LockOutlined,
} from "@ant-design/icons";

import { Link, useHistory } from "react-router-dom";

import "antd/dist/antd.css";


// File
import "./login.css";
import { changePasswordService } from "../../services/authentication.service";
import { getToken } from "../../utils/AuthService";

export default function ChangePassword() {
  let [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const token = getToken();
  const handleChangePassword = ({old_password, password, password_confirmation}) => {
    setLoading(true)
    changePasswordService({
      token,
      old_password,
      password,
      password_confirmation,
      setLoading,
      setError,
      history
    })
  };

  return (
    <Row type="flex" justify="space-around" align="middle" className="wrapper">
      <Col sm={24} md={10} lg={6}>
        <div style={{ textAlign: "center", color: "white" }}>
          <h2 style={{ color: "white", fontWeight: "bold" }}>
            Set Your Favourites!
          </h2>
          <p>
            Gather the lamp you use the most so that you can access them
            quickly.
          </p>
        </div>
        {loading === false ? (
          <Card
            bordered={true}
            hoverable={false}
            style={{ padding: "20px", borderRadius: "10px" }}
          >
            <h2 style={{ fontWeight: "bold" }}>You can update password from here</h2>
            <p>Please update your password to continue.</p>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={(val) => handleChangePassword(val)}
            >
              <Form.Item
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  style={{ width: "250px" }}
                  type="password"
                  placeholder="Old Password"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  style={{ width: "250px" }}
                  type="password"
                  placeholder="New Password"
                />
              </Form.Item>
              <Form.Item
                name="password_confirmation"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  style={{ width: "250px" }}
                  type="password"
                  placeholder="Confirm Password"
                />
              </Form.Item>
              <Form.Item>
                {error && (
                  <>
                    <p style={{ color: "red" }}>{error}</p>
                    <br />
                  </>
                )}
                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: "95%" }}
                >
                  <LoginOutlined /> Reset
                </Button>
                <br />
                <br />
                <Button className="login-form-button" style={{ width: "95%" }}>
                  <Link to="/">Go Back</Link>
                </Button>
              </Form.Item>
            </Form>
          </Card>
        ) : (
          <Card bordered={true} hoverable={false}>
            <Spin size="large" tip="Loading..." spinning={loading} />
          </Card>
        )}
      </Col>
    </Row>
  );
}
