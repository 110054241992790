import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Select,
    Form,
    Button,
    Card,
    Spin
} from "antd";

// Authentication-Service
import { getToken } from "../../utils/AuthService";

// Assign-Service
import { assignZoneService, listOfUserZoneService } from "../../services/assign.service";
import { userListService } from "../../services/user.service";

// Redux-Functoins
import { setUserList, selectUserList } from "../../redux/userSlice";
import { selectZoneAssignedId, setAssignedUser } from "../../redux/zoneSlice";

const AssignFormZone = ({ setModal }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [assignForm] = Form.useForm();
    
    const dispatch = useDispatch();
    const token = getToken();

    const userListData = useSelector(selectUserList);
    const ZoneId = useSelector(selectZoneAssignedId);

    useEffect(async() => {
        setLoading(true)
        const result = await userListService({
          token,
          setLoading,
          setError
        })
        if(result?.data){
          dispatch(
            setUserList(result?.data)
          )
        }
        setLoading(false)
    },[])

    const handleAssignLocation = async (values) => {
        setLoading(true)
        const { 
            user_id
        } = values;
        assignForm.resetFields();
        const result = await assignZoneService({
            token,
            user_id,
            zone_id: ZoneId,
            setError,
            setLoading
        })

        if (result?.data) {
            const result = await listOfUserZoneService({
                token,
                id: ZoneId,
                setError,
                setLoading
            })
            if(result?.data){
                dispatch(
                  setAssignedUser(
                    result?.data.user
                  )
                )
            }else{
                dispatch(setAssignedUser([]))
            }
        }
        setLoading(false)
        setModal(false)
    }

    const handleCancel = () => {
        setModal(false)
        assignForm.resetFields();
    };

    if (error) {
        setTimeout(
            () => setError(null), 
            3000
        );
        return (
            <>
                <p style={{
                    color: "red",
                    height: "50vh",
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    fontSize: '18px'
                }}>
                    {error}
                </p>
                <Button
                    style={{
                        width: "130px",
                        margin: "30px auto",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </>
        )
    }

    return (
        <>
            {loading === false ? (
                <Form form={assignForm} onFinish={(val) => handleAssignLocation(val)}>
                    Select Users*
                    <Form.Item
                        name="user_id"
                        rules={[
                            {
                                required: true,
                                message: "Please select location",
                            }
                        ]}
                    >
                        <Select 
                            mode="multiple"
                            style={{
                                width: '100%',
                                marginLeft: "0px",
                                marginRight: "0px",
                            }}
                            placeholder="Select Users"
                        >
                        {userListData?.map((item) =>
                            <Select.Option value={item.id} key={item.id}>
                                <span>{item.name}</span>
                            </Select.Option>
                        )}
                            
                        </Select> 
                        
                    </Form.Item>
                    <Button
                        style={{
                            width: "130px",
                            margin: "0px 20px 0px 30px",
                            borderRadius: "8px",
                        }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            width: "130px",
                            borderRadius: "8px",
                        }}
                        type="primary"
                        htmlType="submit"
                    >
                        Assign
                    </Button>
                </Form>
            ) : (
                <Card bordered={true} hoverable={false}>
                    <Spin
                        size="large"
                        tip="Loading..."
                        spinning={loading}
                        style={{
                            height: "50vh",
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    />
                </Card>
            )}
        </>
    )
}

export default AssignFormZone