import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// Ant-Design
import {
  Layout,
  Menu,
  Dropdown,
  Select,
  Form,
  Button,
  Modal,
  Input,
  Row,
  Col,
  Card,
  Spin
} from "antd";
import {
  DownOutlined,
  TeamOutlined,
  ApartmentOutlined,
  AppstoreOutlined,
  MobileOutlined,
  PieChartOutlined,
  FileOutlined,
  DesktopOutlined,
  PlusCircleFilled,
  UserOutlined
} from "@ant-design/icons";
import "antd/dist/antd.css";

// UI-Files
import "./layout.css";
import "../App.css";
import Support from "../pages/Support/Support";
import User from "../pages/Setting/User";
import Dashboard from "../pages/Dashboard/Dashboard";
import LocationHeader from "../pages/Location/LocationHeader";
import ScheduleHeader from "../pages/Schedule/ScheduleHeader";
import SceneHeader from "../pages/Scene/SceneHeader";
import DeviceHeader from "../pages/Device/DeviceHeader";
import Device from "./../pages/Location/LocationInfo/Device";

// From-UI
import CreateUserForm from "../pages/Setting/CreateUserForm";
import LocationCreateForm from "../components/Location/LocationCreateForm";
import AreaForm from "../components/Area/AreaForm";
import ZoneForm from "../components/Zone/ZoneForm";
import DeviceForm from "../components/Device/DeviceForm";
import UserUpdateForm from "../components/User/UserUpdateForm";

// Assigned-UI
import AssignedLocation from "../pages/AssignedLocation/AssignedLocation";
import AssignLocationForm from "../pages/AssignedLocation/AssignFromLocation";
import AssignedArea from "../pages/AssignedArea/AssignedArea";
import AssignFormArea from "../pages/AssignedArea/AssignFromArea";
import AssignedZone from "../pages/AssignedZone/AssignedZone";
import AssignFormZone from "../pages/AssignedZone/AssignFromZone";

// Authentication-Service
import { withAuth } from "../utils/Custom-hooks/HOCs";
import { getUser, getToken } from "../utils/AuthService";

// API-service
import {
  logoutService
} from "../services/authentication.service";
import { 
  locationListService, 
  locationSingleService 
} from "../services/location.service";
import { 
  areaListService,
  areaSingleService 
} from "../services/area.service";
import { 
  zoneSingleService,
  zoneListService
} from "../services/zone.service";

// Redux-Functoins
import { selectUser, selectUserType } from "../redux/userSlice";
import { setLocationList, selectLocationList } from "../redux/locationSlice";
import { setSingleLocation, selectSingleLocation } from "../redux/locationSlice";
import { setAreaList, setSingleArea, selectSingleArea, selectAreaList } from "../redux/areaSlice";
import { setZoneList, setSingleZone, selectZoneList, selectSingleZone } from "../redux/zoneSlice";

import { 
  locationController, 
  onResetLocation,
  onLocationSelect,
  onResetArea,
  onAreaSelect,
  onResetZone,
  onZoneSelect,
  onDeviceSelect
 } from "../redux/appUtilitySlice";

const { Header, Content, Footer, Sider } = Layout;
const { Option } = Select;
const { SubMenu } = Menu;

const DashboardMenu = (props) => {
  const { session } = props;
  
  let locations = useSelector(selectLocationList);
  let areas = useSelector(selectAreaList); 
  let zones = useSelector(selectZoneList); 
  
  let singleLocationData = useSelector(selectSingleLocation);
  let singleAreaData = useSelector(selectSingleArea);
  let singleZoneData = useSelector(selectSingleZone);
  let controllerLocation = useSelector(locationController);
  let userType = useSelector(selectUserType);
  const {
    selectedLocation, 
    selectedArea, 
    selectedZone, 
    selectedDevice, 
    isLocationAddButton,
    isAreaAddButton,
    isZoneAddButton,
    isAreaBox,
    isZoneBox,
    isDeviceBox,
    hideDropdown
  } = controllerLocation;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  //const [selectLocation, setLocation] = useState(locations[0]?.id);
  // const [selectArea, setArea] = useState(null);
  // const [selectZone, setZone] = useState(null);
  // const [isLocationAddButton, setLocationAddButton] = useState(true);
  // const [isAreaAddButton, setAreaAddButton] = useState(false);
  // const [isZoneAddButton, setZoneAddButton] = useState(false);
  // const [isAreaBox, setAreaBox] = useState(false);
  // const [isZoneBox, setZoneBox] = useState(false);

  const [pageName, setNameMenu] = useState("");
  const [hideButton, setHideButton] = useState(false);
  //const [hideDropdown, setHideDropdown] = useState(false);
  const [isAddLocationModalVisible, setIsAddLocationModalVisible] = useState(false);
  const [isAddAreaModal, setAddAreaModal] = useState(false);
  const [isUserFromModalVisible, setIsUserFromModalVisible] = useState(false);
  const [isModalAddDevice, setModalAddDevice] = useState(false);
  const [isModalAddZone, setModalAddZone] = useState(false);
  const [isModalUserUpdate, setModalUserUpdate] = useState(false);
  const [isAssinLocation, setAssignLocation] = useState(false);
  const [isAssinArea, setAssignArea] = useState(false);
  const [isAssinZone, setAssignZone] = useState(false);
  
  // const [addZoneForm] = Form.useForm();
  // const [addDeviceForm] = Form.useForm();
  // const [addLocationFrom] = Form.useForm();
  // const [addUserFrom] = Form.useForm();

  const user = getUser();
  const token = getToken();
  const history = useHistory();
  const dispatch = useDispatch();
  const storeUser = useSelector(selectUser)?.user;
  

  const { email: userEmail, name: userName, id: userId } = storeUser || {};
  const { email: userEmailLocal, name: userNameLocal, id: userIdLocal } = user || {};


  let url = window.location.href.split("/");
  let paramId = url[url.length - 1];

  const filtered = locations?.filter((x) => x.id === selectedLocation);
  const filteredAreas = areas?.filter((x) => x.id === selectedArea);
  //console.log(singleZoneData)

  //console.log(locations)

  useEffect(async() => {
    if(locations?.length === 0 || areas?.length === 0 || zones?.length === 0){
      setLoading(true)
      if(user?.role === userType?.admin || 
        user?.role === userType?.location_owner){
        const result = await locationListService({
          token,
          setError,
          setLoading
        })
        dispatch(
          setLocationList(result?.data)
        )
      }

      if(user?.role === userType?.admin || 
        user?.role === userType?.location_owner || 
        user?.role === userType?.area_owner ){
        const result = await areaListService({
          token,
          setError,
          setLoading
        })
        dispatch(
          setAreaList(result?.data)
        )
      }

      if(user?.role === userType?.admin || 
        user?.role === userType?.location_owner || 
        user?.role === userType?.area_owner || 
        user?.role === userType?.zone_owner){
        const result = await zoneListService({
          token,
          setError,
          setLoading
        })
        dispatch(
          setZoneList(result?.data)
        )
      }
      setLoading(false)
    }
  },[])
  
  useEffect(() => {
    if (!session) {
      return;
    }
  },[session])

  useEffect(async() => {
    if(pageName === 'location'){
      if(user?.role === userType?.admin || user?.role === userType?.location_owner){
        const result = await locationSingleService({
          setError,
          setLoading,
          token,
          id: locations[0]?.id
        })
        if(result?.status){
          dispatch(setSingleLocation(result?.data))
        }
        dispatch(onResetLocation(locations[0] ? locations[0]?.id : filtered[0]?.id ))
      }

      if(user?.role === userType?.area_owner){
        const result = await areaSingleService({
          setError,
          setLoading,
          token,
          id: areas[0]?.id
        })

        if(result?.status){
          dispatch(setSingleArea(result?.data))
        }
        dispatch(onResetArea(areas ? areas[0]?.id : filteredAreas[0]?.id ))
      }

      if(user?.role === userType?.zone_owner){
        const result = await zoneSingleService({
          setError,
          setLoading,
          token,
          id: zones[0]?.id
        })
        if(result?.status){
          dispatch(setSingleZone(result?.data))
        }
        dispatch(onResetZone(zones ? zones[0]?.id : '' ))
      }
    }
  },[pageName])

  useEffect(async() => {
    if(singleLocationData?.dashboard_data?.list_of_areas?.areas?.length > 0){
      const result = await areaSingleService({
        setError,
        setLoading,
        token,
        id: singleLocationData?.dashboard_data?.list_of_areas?.areas[0]?.id
      })
      if(result?.status){
        dispatch(setSingleArea(result?.data))
      }
    }
  },[singleLocationData])


  useEffect(async() => {
    if(singleAreaData?.dashboard_data?.list_of_zones?.zones?.length > 0){
      const result = await zoneSingleService({
        setError,
        setLoading,
        token,
        id: singleAreaData?.dashboard_data?.list_of_zones?.zones[0]?.id
      })
      if(result?.status){
        dispatch(setSingleZone(result?.data))
      }
    }
  },[singleAreaData])
  
  useEffect(async() => {
    if(singleZoneData?.dashboard_data?.list_of_devices?.devices?.length > 0){
      console.log('singleZoneData from useEffect',singleZoneData)
      // const result = await zoneSingleService({
      //   setError,
      //   setLoading,
      //   token,
      //   id: singleAreaData?.dashboard_data?.list_of_zones?.zones[0]?.id
      // })
      // if(result?.status){
      //   dispatch(setSingleZone(result?.data))
      // }
    }
  },[singleZoneData])

  const showAddLocationModal = () => {
    setIsAddLocationModalVisible(true);
  };

  const showAddAreaModal = () => {
    setAddAreaModal(true);
  };

  const showModalAddZone = () => {
    setModalAddZone(true);
  };

  const showModalAddDevice = () => {
    setModalAddDevice(true);
  };

  const showModalUserUpdate = () => {
    setModalUserUpdate(true)
  }

  const showModalAssignLocatioToUser = () => {
    setAssignLocation(true)
  }

  const showModalAssignAreaToUser = () => {
    setAssignArea(true)
  }

  const showModalAssignZoneToUser = () => {
    setAssignZone(true)
  }
  
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const handleLogout = () => {
    logoutService({
      token,
      dispatch,
      history,
      setError,
      setLoading
    })
  };

  const handleChangePassword = () => {
    history.push('/change_password')
  }

  if(error){
    setTimeout(
      () => setError(null), 
      3000
    );
  }

  const menus = (
    <Menu>
      <Menu.Item onClick={() => showModalUserUpdate()}>
        <span>Profile Update</span>
      </Menu.Item>
      <Menu.Item onClick={handleChangePassword}>
        <span>Change Password</span>
      </Menu.Item>
      <Menu.Item key="8" onClick={handleLogout}>
        <span>Logout</span>
        {error && (
          <>
            <p style={{ color: "red" }}>{error}</p>
          </>
        )}
      </Menu.Item>
    </Menu>
  );

  async function onSelectLocation (value) {
    setLoading(true)
    dispatch(onLocationSelect(value))
    const result = await locationSingleService({
      setError,
      setLoading,
      token,
      id: value
    })
    if(result?.status){
      dispatch(setSingleLocation(result?.data))
    }
    setLoading(false)
    //setHideDropdown(true)
    // setLocation(value)
    // setLocationAddButton(false)
    // //area-controller
    // setArea(null)
    // setAreaBox(true)
    // setAreaAddButton(true)

    // //zone-controller
    // setZoneBox(false)
    // setZoneAddButton(false)
  }

  async function onSelectArea(value) {
    setLoading(true)
    dispatch(onAreaSelect(value))
    const result = await areaSingleService({
      setError,
      setLoading,
      token,
      id: value
    })
    if(result?.status){
      dispatch(setSingleArea(result?.data))
    }
    setLoading(false)
    //setHideDropdown(true)
    // setArea(value)
    
    // setAreaAddButton(false)
    
    // //zone-controller
    // setZone(null)
    // setZoneBox(true)
    // setZoneAddButton(true)
  }

  async function onSelectZone(value) {
    setLoading(true)
    dispatch(onZoneSelect(value))
    const result = await zoneSingleService({
      setError,
      setLoading,
      token,
      id: value
    })
    if(result?.status){
      dispatch(setSingleZone(result?.data))
    }
    setLoading(false)
    //setHideDropdown(true)
    //setZone(value)
    //setZoneAddButton(false)
  }

  async function onSelectDevice(value) {
    setLoading(true)
    dispatch(onDeviceSelect(value))
    console.log(value)
    // const result = await zoneSingleService({
    //   setError,
    //   setLoading,
    //   token,
    //   id: value
    // })
    // if(result?.status){
    //   dispatch(setSingleZone(result?.data))
    // }
    setLoading(false)
    //setHideDropdown(true)
    //setZone(value)
    //setZoneAddButton(false)
  }
 
  return (
    <div>
      <Router>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <div className="logo">
              <span className="logo-text">LOGO</span>
              <div
                className={collapsed ? "border-line-collapsed" : "border-line"}
              />
            </div>
            <Menu theme="dark" mode="inline">
              {user?.role !== userType?.super_admin && 
                <>
                  <Menu.Item
                    key="1"
                    icon={<AppstoreOutlined />}
                    onClick={() => {
                      setNameMenu("dashboard");
                    }}
                  >
                    <Link to={`/`}>
                      <span>Dashboard</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key="3"
                    icon={<ApartmentOutlined />}
                    onClick={() => {
                      setNameMenu("location");
                    }}
                  >
                    <Link to={`/location`}>
                      <span>Location</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key="4"
                    icon={<PieChartOutlined />}
                    className={collapsed ? "" : "ulter-padding"}
                    onClick={() => {
                      setNameMenu("device");
                    }}
                  >
                    <Link to={`/device`}>
                      <span>Device</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key="5"
                    icon={<MobileOutlined />}
                    className={collapsed ? "" : "ulter-padding"}
                    onClick={() => {
                      setNameMenu("scene");
                    }}
                  >
                    <Link to={`/scene`}>
                      <span>Scene</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key="6"
                    icon={<DesktopOutlined />}
                    className={collapsed ? "" : "ulter-padding"}
                    onClick={() => {
                      setNameMenu("schedule");
                    }}
                  >
                    <Link to={`/schedule`}>
                      <span>Schedule</span>
                    </Link>
                  </Menu.Item>
                </>
              }
              {/* <SubMenu key="sub1" icon={<TeamOutlined />} title="Setting">
                <Menu.Item
                  className={collapsed ? "" : "ulter-padding"}
                  style={{ width: "200px", paddingLeft: "50px" }}
                  key="7"
                  onClick={() => {
                    setNameMenu("user");
                  }}
                >
                  <Link to={`/user`}>
                    <span>User</span>
                  </Link>
                </Menu.Item>
              </SubMenu> */}
              <Menu.Item 
                key="7"
                icon={<UserOutlined />}
                onClick={() => {
                  setNameMenu("user");
                }}
              >
                <Link to={`/user`}>
                  <span>User</span>
                </Link>
              </Menu.Item>
              {user?.role !== userType?.super_admin &&
                <>
                  <Menu.Item
                    key="8"
                    icon={<FileOutlined />}
                    onClick={() => {
                      setNameMenu("support");
                    }}
                  >
                    <Link to={`/support`}>
                      <span>Support</span>
                    </Link>
                  </Menu.Item>
                </>
              }
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: 0 }}>
              {/* <HomeFilled
                style={{
                }}
              />
              <Dropdown overlay={menu}>
                <a>
                  Building 1 <DownOutlined />
                </a>
              </Dropdown> */}
              {pageName === "support" || paramId === "support" ? (
                <h1 className="pageName">Support</h1>
              ) : pageName === "location" || paramId === "location" ? (
                <>
                 {user?.role === userType?.admin || 
                  user?.role === userType?.location_owner ? (
                  <Select
                    showSearch
                    style={{
                      width: 150,
                      marginLeft: "20px",
                      marginRight: "10px",
                    }}
                    placeholder="Select Location"
                    optionFilterProp="children"
                    onChange={onSelectLocation}
                    defaultValue={locations?.length !=0 ? locations[0]?.location_name : ''}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      locations?.map((item, index) => (
                        <Option key={index} value={item.id}>{item?.location_name}</Option>
                      ))
                    }
                  </Select>) : ("")}

                  {user?.role === userType?.admin &&
                    isLocationAddButton === true ?
                    (<Button
                      type="link"
                      onClick={() => showAddLocationModal()}
                      icon={<PlusCircleFilled />}
                    />) : ("")
                  }

                  { (hideDropdown === true && isAreaBox === true) && 
                  (user?.role === userType?.admin || 
                  user?.role === userType?.location_owner || 
                  user?.role === userType?.area_owner) ? (
                      <Select
                        showSearch
                        style={{
                          width: 150,
                          marginLeft: "20px",
                          marginRight: "10px",
                        }}
                        placeholder="Select Area" 
                        optionFilterProp="children" 
                        onChange={onSelectArea}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={selectedArea}
                      >
                        { user?.role === userType?.admin || 
                          user?.role === userType?.location_owner ? 
                          (
                            singleLocationData?.dashboard_data?.list_of_areas?.areas?.map((item, index) =>(
                            <Option key={index} value={item.id}>{item.area_name}</Option>))
                          ) : ("")
                        }
                        {  user?.role === userType?.area_owner ? 
                          (
                            areas?.map((item, index) =>(
                            <Option key={index} value={item.id}>{item.area_name}</Option>))
                          ) : ("")
                        }
                      </Select>
                    ) : ("")
                  }

                  {(
                    user?.role === userType?.admin || 
                    user?.role === userType?.location_owner
                  ) && isAreaAddButton === true ?
                    (<Button
                      type="link"
                      onClick={() => showAddAreaModal()}
                      icon={<PlusCircleFilled />}
                    />) : ("")
                  }

                  {
                    hideDropdown === true &&
                    isZoneBox === true ? (
                      <Select
                        showSearch
                        style={{
                          width: 150,
                          marginLeft: "20px",
                          marginRight: "10px",
                        }}
                        placeholder="Select Zone"
                        optionFilterProp="children"
                        onChange={onSelectZone}
                        //defaultValue={filtered[0].zoneName}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={selectedZone}
                      >
                       {user?.role === userType?.admin || 
                        user?.role === userType?.location_owner || 
                        user?.role === userType?.area_owner ? 
                          (
                            singleAreaData?.dashboard_data?.list_of_zones?.zones.map((item, index) =>(
                            <Option key={index} value={item.id}>{item.zone_name}</Option>))
                          ) : ("")
                        }
                        {  user?.role === userType?.zone_owner ? 
                          (
                            zones?.map((item, index) =>(
                            <Option key={index} value={item.id}>{item.zone_name}</Option>))
                          ) : ("")
                        }
                      </Select>
                      
                    ) : ("")
                  }

                  {(
                   user?.role === userType?.admin || 
                   user?.role === userType?.location_owner || 
                   user?.role === userType?.area_owner
                  ) && isZoneAddButton === true ?
                    (<Button
                      type="link"
                      onClick={() => showModalAddZone()}
                      icon={<PlusCircleFilled />}
                    />) : ("")
                  }

                  {
                    hideDropdown === true &&
                    isDeviceBox === true ? (
                      <Select
                        showSearch
                        style={{
                          width: 150,
                          marginLeft: "20px",
                          marginRight: "10px",
                        }}
                        placeholder="Select Device"
                        optionFilterProp="children"
                        onChange={onSelectDevice}
                        //defaultValue={filtered[0].zoneName}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={selectedDevice}
                      >
                      {singleZoneData?.dashboard_data?.list_of_devices?.devices.map((item, index) =>(
                        <Option key={index} value={item.id}>{item.device_name}</Option>))
                      }
                      </Select>
                      
                    ) : ("")
                  }


                  {/* {hideDropdown === true &&
                    filtered[0].areas?.length > 0 &&
                    filtered[0].zoneName !== "" &&
                    filtered[0].deviceName !== "" ? (
                    <>
                      <Select
                        showSearch
                        style={{
                          width: 150,
                          marginLeft: "20px",
                          marginRight: "10px",
                        }}
                        placeholder="Select Area"
                        optionFilterProp="children"
                        onChange={onSelectLocation}
                        defaultValue={filtered[0].city}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          filtered.map((item, index) =>(
                            <Option key={index} value={item.id}>{item.city}</Option>)
                          )
                        }
                      </Select>

                      <Select
                        showSearch
                        style={{
                          width: 150,
                          marginLeft: "20px",
                          marginRight: "10px",
                        }}
                        placeholder="Select Zone"
                        optionFilterProp="children"
                        onChange={onSelectLocation}
                        defaultValue={filtered[0].zoneName}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {filtered.map((item, index) => (
                          <Option key={index} value={item.id}>{item.zoneName}</Option>
                        )
                        )}
                      </Select>
                      <Select
                        showSearch
                        style={{
                          width: 150,
                          marginLeft: "20px",
                          marginRight: "10px",
                        }}
                        placeholder="Select Device"
                        optionFilterProp="children"
                        onChange={onSelectLocation}
                        defaultValue={filtered[0].deviceName}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {filtered.map((item, index) => (
                          <Option key={index} value={item.id}>{item.deviceName}</Option>
                        )
                        )}
                      </Select>
                    </>
                  ) :
                    hideDropdown === true &&
                      filtered[0].areaName !== "" &&
                      filtered[0].zoneName !== "" ? (
                      <>
                        <Select
                          showSearch
                          style={{
                            width: 150,
                            marginLeft: "20px",
                            marginRight: "10px",
                          }}
                          placeholder="Select Area"
                          optionFilterProp="children"
                          // onChange={onSelectLocation}
                          defaultValue={filtered[0].areaName}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {filtered.map((item, index) => (
                            <Option key={index} value={item.id}>{item.areaName}</Option>
                          ))}
                        </Select>
                        <Select
                          showSearch
                          style={{
                            width: 150,
                            marginLeft: "20px",
                            marginRight: "10px",
                          }}
                          placeholder="Select Zone"
                          optionFilterProp="children"
                          // onChange={onSelectLocation}
                          defaultValue={filtered[0].zoneName}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {filtered.map((item, index) => (
                            <Option key={index} value={item.id}>{item.zoneName}</Option>
                          )
                          )}
                        </Select>
                      </>
                    ) : 
                      hideDropdown === true && 
                      filtered[0].areaName !== "" ? (
                        <Select
                          showSearch
                          style={{
                            width: 150,
                            marginLeft: "20px",
                            marginRight: "10px",
                          }}
                          placeholder="Select Area"
                          optionFilterProp="children"
                          // onChange={onSelectLocation}
                          defaultValue={filtered[0].areaName}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {filtered.map((item, index) => (
                            <Option key={index} value={item.id}>{item.areaName}</Option>
                          )
                          )}
                        </Select>
                      ) : (
                        ""
                      )} */}
                  
                

                  {/* {user?.role === 'location-owner' &&
                    <>
                      {hideButton === true &&
                        filtered[0].areaName !== "" &&
                        filtered[0].zoneName !== "" &&
                        filtered[0].deviceName !== "" ? (
                        ""
                      ) : hideButton === true &&
                        filtered[0].areaName !== "" &&
                        filtered[0].zoneName !== "" ? (
                        <Button
                          type="link"
                          onClick={() => showModalAddDevice()}
                          icon={<PlusCircleFilled />}
                        />
                      ) : hideButton === true &&
                        filtered[0].areaName !== "" ? (
                        <Button
                          type="link"
                          onClick={() => showModalAddZone()}
                          icon={<PlusCircleFilled />}
                        />
                      ) : (
                        <div style={{
                          marginLeft: "10px",
                          paddingLeft: "15px",
                        }}>
                          <span style={{
                           fontSize: "18px",
                           fontWeight: '600'
                          }}>
                            Add Area 
                          </span>
                          <Button
                            type="link"
                            onClick={() => showAddAreaModal()}
                            icon={<PlusCircleFilled />}
                          />
                        </div>
                      )}
                    </>
                  }

                  {user?.role === 'area-owner' &&
                    <>
                      {hideButton === true &&
                        filtered[0].areaName !== "" &&
                        filtered[0].zoneName !== "" &&
                        filtered[0].deviceName !== "" ? (
                        ""
                      ) : hideButton === true &&
                        filtered[0].areaName !== "" &&
                        filtered[0].zoneName !== "" ? (
                        <Button
                          type="link"
                          onClick={() => showModalAddDevice()}
                          icon={<PlusCircleFilled />}
                        />
                      ) : hideButton === true &&
                        filtered[0].areaName !== "" ? (
                        <Button
                          type="link"
                          onClick={() => showModalAddZone()}
                          icon={<PlusCircleFilled />}
                        />
                      ) : (
                        <div style={{
                          marginLeft: "10px",
                          paddingLeft: "15px",
                        }}>
                          <span style={{
                           fontSize: "18px",
                           fontWeight: '600'
                          }}>
                           Add Zone 
                          </span>
                        <Button
                          type="link"
                          onClick={() => showModalAddZone()}
                          icon={<PlusCircleFilled />}
                        />
                        </div>
                      )}
                    </>
                  }

                  {user?.role === 'zone-owner' &&
                    <>
                      {hideButton === true &&
                        filtered[0].areaName !== "" &&
                        filtered[0].zoneName !== "" &&
                        filtered[0].deviceName !== "" ? (
                        ""
                      ) : hideButton === true &&
                        filtered[0].areaName !== "" &&
                        filtered[0].zoneName !== "" &&
                        user?.role == 'zone-owner' ? (
                        <Button
                          type="link"
                          onClick={() => showModalAddDevice()}
                          icon={<PlusCircleFilled />}
                        />
                      ) : (
                        <div style={{
                          marginLeft: "10px",
                          paddingLeft: "15px",
                        }}>
                          <span style={{
                           fontSize: "18px",
                           fontWeight: '600'
                          }}>
                           Add Device 
                          </span>
                        <Button
                          type="link"
                          onClick={() => showModalAddDevice()}
                          icon={<PlusCircleFilled />}
                        />
                        </div>
                      )}
                    </>
                  } */}

                </>
              ) : pageName === "device" || paramId === "device" ? (
                <Select
                  showSearch
                  style={{ width: 150, marginLeft: "20px" }}
                  placeholder="Select Location"
                  optionFilterProp="children"
                  onChange={onSelectLocation}
                  defaultValue={locations ? locations[0]?.location_name : ''}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    locations?.map((item, index) =>(
                      <Option key={index} value={item.id}>{item?.location_name}</Option>
                    ))
                  }
                </Select>
              ) : pageName === "scene" || paramId === "scene" ? (
                <Select
                  showSearch
                  style={{ width: 150, marginLeft: "20px" }}
                  placeholder="Select Location"
                  optionFilterProp="children"
                  onChange={onSelectLocation}
                  defaultValue="Location #1"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Location #1">Location #1</Option>
                </Select>
              ) : pageName === "schedule" || paramId === "schedule" ? (
                <Select
                  showSearch
                  style={{ width: 150, marginLeft: "20px" }}
                  placeholder="Select Location"
                  optionFilterProp="children"
                  onChange={onSelectLocation}
                  defaultValue="Location #1"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Location #1">Location #1</Option>
                </Select>
              ) : pageName === "user" || paramId === "user" ? (
                <div className="pageName">
                  <span>User</span>
                    <Button
                      type="link"
                      onClick={() => setIsUserFromModalVisible(true)}
                      icon={<PlusCircleFilled />}
                    />
                </div>
              ) : pageName === "location-assigned" || paramId === "location-assigned" ? (
                <div style={{
                  marginLeft: "10px",
                  paddingLeft: "15px",
                }}>
                  <span style={{
                   fontSize: "18px",
                   fontWeight: '600'
                  }}>
                   Assign Location 
                  </span>
                  <Button
                    type="link"
                    onClick={() => showModalAssignLocatioToUser()}
                    icon={<PlusCircleFilled />}
                  />
                </div>
              ) : pageName === "area-assigned" || paramId === "area-assigned" ? (
                <div style={{
                  marginLeft: "10px",
                  paddingLeft: "15px",
                }}>
                  <span style={{
                   fontSize: "18px",
                   fontWeight: '600'
                  }}>
                   Assign Area
                  </span>
                  <Button
                    type="link"
                    onClick={() => showModalAssignAreaToUser()}
                    icon={<PlusCircleFilled />}
                  />
                </div>
              ) :  pageName === "zone-assigned" || paramId === "zone-assigned" ? (
                <div style={{
                  marginLeft: "10px",
                  paddingLeft: "15px",
                }}>
                  <span style={{
                   fontSize: "18px",
                   fontWeight: '600'
                  }}>
                   Assign Zone
                  </span>
                  <Button
                    type="link"
                    onClick={() => showModalAssignZoneToUser()}
                    icon={<PlusCircleFilled />}
                  />
                </div>
              ) : (
                <h1 className="pageName">Dashboard</h1>
              )}
              <Dropdown overlay={menus} style={{}}>
                <a className="user-dropdown">
                  {/* {storeUser ? storeUser.name : ''} <DownOutlined /> */}
                  {userName || userNameLocal} <DownOutlined />
                </a>
              </Dropdown>
            </Header>
            <Content>
              <div style={{ padding: 0, minHeight: 0 }}>
                <Switch>
                  <Route exact path="/">
                    {loading === false ? (
                      <Dashboard />
                    ) : (
                      <Card bordered={true} hoverable={false}>
                        <Spin
                          size="large"
                          tip="Loading..."
                          spinning={loading}
                          style={{
                            height: "100vh",
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}
                        />
                      </Card>
                    )}
                  </Route>
                  <Route exact path="/location">
                    {loading === false ? (
                      <LocationHeader />
                    ) : (
                      <Card bordered={true} hoverable={false}>
                        <Spin
                          size="large"
                          tip="Loading..."
                          spinning={loading}
                          style={{
                            height: "100vh",
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}
                        />
                      </Card>
                    )}
                  </Route>
                  <Route exact path="/location-assigned">
                    <AssignedLocation setPageName={setNameMenu}/>
                  </Route>
                  <Route exact path="/area-assigned">
                    <AssignedArea setPageName={setNameMenu}/>
                  </Route>
                  <Route exact path="/zone-assigned">
                    <AssignedZone setPageName={setNameMenu}/>
                  </Route>
                  <Route exact path="/device">
                    <DeviceHeader />
                  </Route>
                  <Route exact path="/scene">
                    <SceneHeader />
                  </Route>
                  <Route exact path="/schedule">
                    <ScheduleHeader />
                  </Route>
                  <Route exact path="/user">
                    <User />
                  </Route>
                  <Route exact path="/support">
                    <Support />
                  </Route>
                  <Route exact path="/edit-device">
                    {/* <Support /> */}
                    <Device />
                  </Route>
                </Switch>
              </div>
            </Content>
          </Layout>
        </Layout>
      </Router>
      <Modal
         title="Zone Create Form"
        width={400}
        centered
        visible={isModalAddZone}
        footer={null}
        closable={false}
        style={{
          borderRadius: "8px",
        }}
      >
        <ZoneForm
          setModal={setModalAddZone}
         />
      </Modal>

      <Modal
        width={400}
        centered
        visible={isModalAddDevice}
        footer={null}
        closable={false}
        style={{
          borderRadius: "8px",
        }}
      >
        <DeviceForm setModal={setModalAddDevice} />
      </Modal>

      <Modal
        title="Location Create Form"
        width={400}
        centered
        visible={isAddLocationModalVisible}
        footer={null}
        closable={false}
        style={{
          borderRadius: "8px",
        }}
      >
        <LocationCreateForm 
          setModal={setIsAddLocationModalVisible}
        />
      </Modal>

      <Modal
        title="Assign location to user"
        width={400}
        centered
        visible={isAssinLocation}
        footer={null}
        closable={false}
        style={{
          borderRadius: "8px",
        }}
      >
        <AssignLocationForm 
          setModal={setAssignLocation}
        />
      </Modal>

      <Modal 
        title="Assign area to user"
        width={400}
        centered
        visible={isAssinArea}
        footer={null}
        closable={false}
        style={{
          borderRadius: "8px",
        }}
      >
        <AssignFormArea 
          setModal={setAssignArea}
        />
      </Modal>

      <Modal
        title="Assign zone to user"
        width={400}
        centered
        visible={isAssinZone}
        footer={null}
        closable={false}
        style={{
          borderRadius: "8px",
        }}
      >
        <AssignFormZone 
          setModal={setAssignZone}
        />
      </Modal>

      <Modal
        title="Area Create Form"
        width={400}
        centered
        visible={isAddAreaModal}
        footer={null}
        closable={false}
        style={{
          borderRadius: "8px",
        }}
      >
        <AreaForm 
          setModal={setAddAreaModal} 
        /> 
      </Modal>

      <Modal
        title="User Profile Update"
        width={400}
        centered
        visible={isModalUserUpdate}
        footer={null}
        closable={false}
        style={{
          borderRadius: "8px",
        }}
      >
        <UserUpdateForm setModal={setModalUserUpdate} /> 
      </Modal>

      <Modal
        title="User Create Form"
        width={400}
        centered
        visible={isUserFromModalVisible}
        footer={null}
        closable={false}
        style={{
          borderRadius: "8px",
        }}
      >
        <CreateUserForm setIsUserFromModalVisible={setIsUserFromModalVisible} />
      </Modal>
    </div>
  );
}

export default withAuth(DashboardMenu)