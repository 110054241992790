import React, { useState, useEffect } from "react";
import {
    Card,
    Table,
    Dropdown,
    Button,
    Menu,
    Spin,
    Modal
} from "antd";

import {Link, useHistory} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// Redux-Function
import { 
    setLocationList,
    selectLocationList,
    deleteLocation
} from "../../redux/locationSlice";
import { selectUserType } from "../../redux/userSlice";
// API-service
import { 
  locationListService, 
  locationDeleteService 
} from "../../services/location.service";

// Auth-service
import { getToken, getUser } from "../../utils/AuthService";

// Location-Form UI
import LocationCreateForm from "./LocationCreateForm";

const LocationTable = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [tableRecord, setTableRecord] = useState(null)
    const [isOpendeleteModal, setOpendeleteModal] = useState(false)
    const [isUpdateModal, setIsUpdateModal] = useState(false)

    const dispatch = useDispatch()
    const history =  useHistory();
    const token = getToken()
    const user = getUser()
    const locations = useSelector(selectLocationList)
    const userType = useSelector(selectUserType);
    // useEffect(async() => {
    //     setLoading(true)
    //     const result = await locationListService({
    //       token,
    //       setError,
    //       setLoading
    //     })
    //     dispatch(
    //       setLocationList(result?.data)
    //     )
    //     setLoading(false)
    // },[])

  const selector = (record) => (
    <Menu>
      <Menu.Item onClick={() => handleUpdate(record)} key="1">
        Edit
      </Menu.Item>
      <Menu.Item onClick={() => handleDelete(record)} key="2">
        Delete
      </Menu.Item>
    </Menu>
  );

  const locationColumn = [
      {
        title: "Location",
        dataIndex: "location_name"
      },
      {
        title: "City",
        dataIndex: "city",
      },
      {
        title: "State",
        dataIndex: "state"
      },
      {
        title: "Lane One",
        dataIndex: "lane_1"
      },{
        title: "Zip",
        dataIndex: "zip",
      },
      {
        title: "Time Zone",
        dataIndex: "timezone"
      }
  ]
  
  const adminAssingedColumn =  {
    title: "Users Action",
    key: "usersAction",
    render: (text, record) => (
      <Button type="default" onClick={() => handleAssignedUsers(record)}>Assigned Users</Button>
    )
  }

  const adminCrudColumn = {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (text, record) => (
      <Dropdown overlay={selector(record)}>
        <Button type="default">Action</Button>
      </Dropdown>
    )
  }

  if( user?.role === userType?.admin ){
    locationColumn.push(adminAssingedColumn, adminCrudColumn)
  }

  const handleAssignedUsers = (record) => {
    history.push({
      pathname: "/location-assigned",
      state: record
    })
  }

  const handleDelete = (record) => {
      setTableRecord(record)
      setOpendeleteModal(true)
  };

  const handleDeleteEntry = async () => {
      setLoading(true)
      const result = await locationDeleteService({
          token,
          id: tableRecord?.id,
          setLoading,
          setError
      })
      if(result?.status){
          const result = await locationListService({
            token,
            setError,
            setLoading
          })
          if(result?.status){
            dispatch(
              setLocationList(result?.data)
            )
            setLoading(false)
            setTableRecord(null)
            setOpendeleteModal(false)
          }
      }
   
  }

  const handleUpdate = (record) => {
    setTableRecord(record)
    setIsUpdateModal(true)
    
  }

  if(error){
    setTimeout(
      () => setError(null), 
      3000
    );
      return(
          <p style={{ 
          color: "red",
          height: "100vh", 
          display: 'flex', 
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          fontSize: '18px'
          }}>
          {error}
          </p>
      )
  }

  return (
      <div>
          {loading === false ? (
                <Table
                columns={locationColumn}
                dataSource={locations}
                pagination={false}
              />
          ) : (
              <Card bordered={true} hoverable={false}>
                  <Spin 
                      size="large" 
                      tip="Loading..." 
                      spinning={loading}
                      style={{ 
                      height: "50vh", 
                      display: 'flex', 
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center'
                      }}
                  />
              </Card>
          )}
          <Modal
              width={310}
              centered
              title="Please Confirm"
              visible={isOpendeleteModal}
              footer={null}
              closable={false}
              okText="Save"
              onCancel={() => {
                  setOpendeleteModal(false)
              }}
              onOk={() => {
                  setOpendeleteModal(false)
              }}
              style={{
              borderRadius: "8px",
              }}
          >
              <Button
                  style={{
                      width: "100px",
                      margin: "0px 20px 0px 20px",
                      borderRadius: "8px",
                  }}
                  onClick={() => {
                      setTableRecord(null)
                      setOpendeleteModal(false)
                  }}
                  >
                  Cancel
              </Button>
              <Button
                  style={{
                      width: "100px",
                      borderRadius: "8px",
                  }}
                  type="primary"
                  onClick={handleDeleteEntry}
                  >
                  Delete
              </Button>
          </Modal>
          <Modal
            title="Location Update"
            width={400}
            centered
            visible={isUpdateModal}
            footer={null}
            closable={false}
            style={{
              borderRadius: "8px",
            }}
          >
            <LocationCreateForm 
              tableRecord = {tableRecord}
              setModal={setIsUpdateModal}
            />
          </Modal>
      </div>
  )
}

export default LocationTable;