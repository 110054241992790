import axios from "axios"

const url = process.env.REACT_APP_APP_API_ENDPOINT

export const roleListService = async ({token, setLoading, setError}) => {
    try {
        let result = await axios.get(url + '/api/v1/roles', {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.data){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const roleCreateService = async ({token, name, setLoading, setError}) => {
    try {
        let result = await axios.post(url + '/api/v1/roles', 
            {
                name
            },{
                headers: {
                    Token: token
                }
            })
            if(result.status){
                return result.data
            }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const roleViewByIDService = async ({token, id, setLoading, setError}) => {
    try {
        let result = await axios.get(url + `/api/v1/roles/${id}`, {
            headers: {
                Token: token
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const roleDeleteService = async ({ token, id, setLoading, setError }) => {
    try {
        let result = await axios.delete(url + `/api/v1/roles/${id}`, {
            headers: {
                Token: token
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const rolePermissionChange = async ({token,id,setLoading, setError}) => {
    try {
        let result = await axios.post(url + `/api/v1/role/change-permission/${id}`, {
            headers: {
                Token: token
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}