import React, { useState, useEffect } from "react";
import {
    Card,
    Table,
    Dropdown,
    Button,
    Menu,
    Spin,
    Modal
} from "antd";
import { useHistory} from "react-router-dom";
import moment from "moment"
import { useDispatch, useSelector } from "react-redux";

// Redux-settings
import { 
    setZoneList,
    setSingleZone,
    deleteZone,
    selectZoneList
 } from "../../redux/zoneSlice";
import { 
    onAreaSelect, 
    onZoneSelect,
    locationController
} from "../../redux/appUtilitySlice";
import { setSingleArea } from "../../redux/areaSlice";
import { selectUserType } from "../../redux/userSlice";

// API service
import { 
    zoneDeleteService,
    zoneSingleService,
    zoneListService,
 } from "../../services/zone.service";
 import { areaSingleService } from "../../services/area.service";
 
// Auth service
import { getToken, getUser } from "../../utils/AuthService";

// Zone-form 
import ZoneForm from "./ZoneForm";

const ZoneTable = ({zoneData=null}) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [tableRecord, setTableRecord] = useState(null)
    const [isOpendeleteModal, setOpendeleteModal] = useState(false)
    const [isUpdateModal, setIsUpdateModal] = useState(false)

    const history =  useHistory();
    const dispatch = useDispatch()
    const token = getToken()
    const user = getUser()
    const userType = useSelector(selectUserType);
    const zones = useSelector(selectZoneList)
    const { selectedArea } = useSelector(locationController)

    useEffect(() => {
        if(zoneData){
            dispatch(
                setZoneList(zoneData)
            )
        }
    },[zoneData])
   
    const zoneColumn = [
        {
            title: "Zone",
            dataIndex: "zone_name",
            render: (text, record) => (
                <span 
                style={{textDecoration: "underline", cursor: "pointer"}} 
                onClick={async() =>{ 
                    setLoading(true)
                    dispatch(onZoneSelect(record?.id))
                    const result = await zoneSingleService({
                        setError,
                        setLoading,
                        token,
                        id: record?.id
                    })
                    if(result?.status){
                        dispatch(setSingleZone(result?.data))
                    }
                    setLoading(false)
                }}>
                    {text}
                </span>
            ),
            filters: [
                {
                text: "Zone #1",
                value: "Zone #1",
                },
                {
                text: "Zone #2",
                value: "Zone #2",
                },
                {
                text: "Zone #3",
                value: "Zone #3",
                },
            ],
            onFilter: (value, record) => record.zone_name.indexOf(value) === 0,
            sorter: (a, b) => {
                a = a.zone_name || "";
                b = b.zone_name || "";
                return a.localeCompare(b);
            },
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Created",
            dataIndex: "created_at",
            render: (text) => { return (<p>{moment(text).format("YYYY-MM-DD HH:mm:ss a")}</p>)},
            filters: [
                {
                text: "Zone #1",
                value: "Zone #1",
                },
                {
                text: "Zone #2",
                value: "Zone #2",
                },
                {
                text: "Zone #3",
                value: "Zone #3",
                },
            ],
            onFilter: (value, record) => record.zone_name.indexOf(value) === 0,
            sorter: (a, b) => {
                a = a.zone_name || "";
                b = b.zone_name || "";
                return a.localeCompare(b);
            },
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Updated",
            dataIndex: "updated_at",
            render: (text) => { return (<p>{moment(text).format("YYYY-MM-DD HH:mm:ss a")}</p>)},
            filters: [
                {
                text: "Zone #1",
                value: "Zone #1",
                },
                {
                text: "Zone #2",
                value: "Zone #2",
                },
                {
                text: "Zone #3",
                value: "Zone #3",
                },
            ],
            onFilter: (value, record) => record.zone_name.indexOf(value) === 0,
            sorter: (a, b) => {
                a = a.zone_name || "";
                b = b.zone_name || "";
                return a.localeCompare(b);
            },
            sortDirections: ["descend", "ascend"],
        }
    ]

    const handleDelete = (record) => {
        setTableRecord(record)
        setOpendeleteModal(true)
    };

    const handleDeleteEntry = async () => {
        setLoading(true)
        const result = await zoneDeleteService({
            token,
            id: tableRecord?.id,
            setLoading,
            setError
        })
        if(result?.status){
            dispatch(
                deleteZone(tableRecord?.id)
            )
            setOpendeleteModal(false)
            // const result = await areaSingleService({
            //     setError,
            //     setLoading,
            //     token,
            //     id: selectedArea
            // })
            // if(result?.status){
            //     dispatch(setSingleArea(result?.data))
            //     dispatch(onAreaSelect(selectedArea))
            // }
        }
        setLoading(false)
        setTableRecord(null)
       
    }

    const handleUpdate = (record) => {
      setTableRecord(record)
      setIsUpdateModal(true)
    }

    const selector = (record) => (
      <Menu>
        <Menu.Item onClick={() => handleUpdate(record)} key="1">
          Edit
        </Menu.Item>
        <Menu.Item onClick={() => handleDelete(record)} key="2">
          Delete
        </Menu.Item>
      </Menu>
    );

    const handleAssignedUsers = (record) => {
        history.push({
          pathname: "/zone-assigned",
          state: record
        })
    }

    const adminAssingedColumn =  {
        title: "Users Action",
        key: "usersAction",
        render: (text, record) => (
            <Button type="default" onClick={() => handleAssignedUsers(record)}>Assigned Users</Button>
        )
    }

    const adminCrudColumn =  {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Dropdown overlay={selector(record)}>
            {/* < rotate={90} /> */}
            <Button type="default">Actions</Button>
          </Dropdown>
        ),
    }

    if( user?.role === userType?.admin || 
        user?.role === userType?.location_owner ||
        user?.role === userType?.area_owner 
        ){
        zoneColumn.push(adminAssingedColumn, adminCrudColumn)
    }

    if(error){
        setTimeout(
            () => setError(null), 
            3000
        );
        return(
            <p style={{ 
            color: "red",
            height: "100vh", 
            display: 'flex', 
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            fontSize: '18px'
            }}>
            {error}
            </p>
        )
    }

    return (
        <div>
            {loading === false ? (
                 <Table
                 columns={zoneColumn}
                 dataSource={zones}
                 pagination={false}
               />
            ) : (
                <Card bordered={true} hoverable={false}>
                    <Spin 
                        size="large" 
                        tip="Loading..." 
                        spinning={loading}
                        style={{ 
                        height: "50vh", 
                        display: 'flex', 
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center'
                        }}
                    />
                </Card>
            )}
            <Modal
                width={310}
                centered
                title="Please Confirm"
                visible={isOpendeleteModal}
                footer={null}
                closable={false}
                okText="Save"
                onCancel={() => {
                    setOpendeleteModal(false)
                }}
                onOk={() => {
                    setOpendeleteModal(false)
                }}
                style={{
                borderRadius: "8px",
                }}
            >
                <Button
                    style={{
                        width: "100px",
                        margin: "0px 20px 0px 20px",
                        borderRadius: "8px",
                    }}
                    onClick={() => {
                        setTableRecord(null)
                        setOpendeleteModal(false)
                    }}
                    >
                    Cancel
                </Button>
                <Button
                    style={{
                        width: "100px",
                        borderRadius: "8px",
                    }}
                    type="primary"
                    onClick={handleDeleteEntry}
                    >
                    Delete
                </Button>
            </Modal>
            <Modal
              title="Zone Update"
              width={400}
              centered
              visible={isUpdateModal}
              footer={null}
              closable={false}
              style={{
                borderRadius: "8px",
              }}
            >
              <ZoneForm 
                tableRecord = {tableRecord}
                setModal={setIsUpdateModal}
              />
            </Modal>
        </div>
    )
}

export default ZoneTable;