import React, { useState } from "react";
// Ant Design
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card,
  Spin,
  Checkbox,
} from "antd";

import {
  UserOutlined,
  LoginOutlined,
  LockOutlined,
} from "@ant-design/icons";

import { Switch, Route, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";


// File
import "./login.css";
import { loginService } from "../../services/authentication.service";
import Register from "./register";

// Redux-function
import { selectUserType } from "../../redux/userSlice";

export default function Login(props) {
  let [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const history =  useHistory();
  let userType = useSelector(selectUserType);

  const handleLogin = (values) => {
    loginService({
      email: values.email,
      password: values.password,
      setLoading, 
      setError,
      dispatch,
      userType,
      history
    })
  };

  if(error){
    setTimeout(
      () => setError(null), 
      3000
    );
  }

  return (
    <Row type="flex" justify="space-around" align="middle" className="wrapper">
      <Col sm={24} md={10} lg={6}>
        <div style={{ textAlign: "center", color: "white" }}>
          <h2 style={{ color: "white", fontWeight: "bold" }}>
            Set Your Favourites!
          </h2>
          <p>
            Gather the lamp you use the most so that you can access them
            quickly.
          </p>
        </div>
        {loading === false ? (
          <Card
            bordered={true}
            hoverable={false}
            style={{ padding: "20px", borderRadius: "10px" }}
          >
            <h2 style={{ fontWeight: "bold" }}> Hello, Welcome Back!</h2>
            <p>Please login to continue.</p>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={(val) => handleLogin(val)}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Enter your email",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  style={{ width: "250px" }}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  style={{ width: "250px" }}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
                <Link to="/forgot_password">Forgot password</Link>
              </Form.Item>

              <Form.Item>
                {error && (
                  <>
                    <p style={{ color: "red" }}>{error}</p>
                    <br />
                  </>
                )}
                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: "95%" }}
                >
                  <LoginOutlined /> Login
                </Button>
                <br />
                <br />
                {/* <Text>
                      Not registered yet? <Link to="/register">Create an Account</Link>
                    </Text> */}
              </Form.Item>
            </Form>

            <Switch>
              <Route exact path="/register">
                <Register />
              </Route>
            </Switch>
          </Card>
        ) : (
          <Card bordered={true} hoverable={false}>
            <Spin size="large" tip="Loading..." spinning={loading} />
          </Card>
        )}
      </Col>
    </Row>
  );
}
