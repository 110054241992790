import React, { useState, useEffect } from "react";
import { 
  Table, 
  Button, 
  Dropdown, 
  Menu,
  Modal,
  Card, 
  Spin,
  Select
} from "antd";

import { PlusCircleFilled, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { 
  userRoleChangeService, 
  userDeleteService,
  userListService
} from "../../services/user.service";

import { roleListService } from "../../services/role.service";

import { getToken } from "../../utils/AuthService";
import { 
  setUserList,
  selectUserList, 
  deleteUser,
  updateUser,
  getUserRoleList,
  selectUserRoleList
  } from "../../redux/userSlice";

const User = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isEdititng, setIsEdititng] = useState(false)
  const [userRole, setUserRole] = useState(null)
  const [tableRecord, setTableRecord] = useState(null)
  const [isOpendeleteModal, setOpendeleteModal] = useState(false)
  
  const token = getToken();
  const dispatch = useDispatch();

  useEffect(async() => {
    setLoading(true)
    const result = await userListService({
      token,
      setLoading,
      setError
    })
    if(result?.data){
      dispatch(
        setUserList(result?.data)
      )
      setLoading(false)
    }
  },[])

  useEffect(async() => {
    setLoading(true)
    const result = await roleListService({
      token,
      setError,
      setLoading
    })
    if(result?.data){
      dispatch(
        getUserRoleList(result?.data)
      )
      setLoading(false)
    }
  },[])


  let userListData = useSelector(selectUserList);
  let userRoleListData = useSelector(selectUserRoleList)?.map(item => item.name);
  

  const handleEditing = (record) => {
    setIsEdititng(true)
    setTableRecord(record)
  }

  const handleDelete = (record) => {
    setTableRecord(record)
    setOpendeleteModal(true)
  }

  const handleDeleteEntry = async () => {
    setLoading(true)

    const result = await userDeleteService({
      token,
      id: tableRecord?.id,
      setLoading,
      setError
    })

    if(result?.success){
      const result = await userListService({
        token,
        setLoading,
        setError
      })
      if(result?.data){
        dispatch(setUserList(result?.data))
      }
      // dispatch(
      //   deleteUser(tableRecord?.id,)
      // )
    }

    setLoading(false)
    setTableRecord(null)
    setOpendeleteModal(false)
  }
  const onUpdateRole = (value) => {
    const result= [];
    value.map((item) => result?.push(userRoleListData[item]))
    setUserRole([...result])
  }
  const handleUpdate = async() => {
    setIsEdititng(false)
    setLoading(true)
    const result = await userRoleChangeService({
      token,
      id: tableRecord?.id,
      roles: userRole,
      setLoading,
      setError
    })
    if(result?.success){
      // dispatch(
      //   updateUser({
      //     roles: userRole,
      //     id: tableRecord?.id
      //   })
      // )
      const result = await userListService({
        token,
        setLoading,
        setError
      })
      if(result?.data){
        dispatch(setUserList(result?.data))
      }
    }
    setTableRecord(null)
  }

  let selector = (record) => (
    <Menu>
      <Menu.Item 
        key="1"
        onClick={() => handleEditing(record)}
       >
         Edit
      </Menu.Item>
      <Menu.Item 
        key="2"
        onClick={() => handleDelete(record)}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const singleUserRoles = (record) => {
    const result = <p style={{marginBottom: 0}}>
     {
      record.roles?.map((item,index) => (
            <span key={index}>{item?.name ? item?.name : item}, </span> 
        ))
      }
    </p>
    return result
}

const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      key: "roles",
      render: (record) => (
        singleUserRoles(record)
      ) 
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Dropdown overlay={selector(record)}>
          <Button type="default">Action</Button>
        </Dropdown>
      )
    },
  ];

  
  if(error){
    setTimeout(
      () => setError(null), 
      3000
    );
    return(
      <p style={{ 
        color: "red",
        height: "100vh", 
        display: 'flex', 
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '18px'
      }}>
        {error}
      </p>
    )
  }

  return (
    <div className="content-layout">
      <h2 className="zone-title">User List</h2>
      {loading === false ? (
        <Table
          style={{ borderRadius: "10px" }}
          columns={columns}
          dataSource={userListData}
          pagination={false}
        />
      ):(
        <Card bordered={true} hoverable={false}>
          <Spin 
            size="large" 
            tip="Loading..." 
            spinning={loading}
            style={{ 
              height: "50vh", 
              display: 'flex', 
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          />
        </Card>
      )}

      <Modal
        width={310}
        centered
        title="Update User Role"
        visible={isEdititng}
        footer={null}
        closable={false}
        okText="Save"
        onCancel={() => {
          setIsEdititng(false)
        }}
        onOk={() => {
          setIsEdititng(false)
        }}
        style={{
          borderRadius: "8px",
        }}
      >
        <Select
            mode="multiple"
            style={{
                width: '100%',
                marginLeft: "0px",
                marginRight: "0px",
            }}
            placeholder="Change User Role"
            onChange={onUpdateRole}
        >
            {userRoleListData?.map((item,index) => 
                <Select.Option key={index}>
                <span>{item}</span>
                </Select.Option>
            )}
        </Select>
      
        <Button
            style={{
              width: "100px",
              margin: "30px 20px 0px 20px",
              borderRadius: "8px",
            }}
            onClick={() => {
              setIsEdititng(false)
              setUserRole(null)
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              width: "100px",
              borderRadius: "8px",
            }}
            type="primary"
            onClick={handleUpdate}
          >
            Save
          </Button>
      </Modal>
      <Modal
        width={310}
        centered
        title="Please Confirm"
        visible={isOpendeleteModal}
        footer={null}
        closable={false}
        okText="Save"
        onCancel={() => {
          setIsEdititng(false)
        }}
        onOk={() => {
          setIsEdititng(false)
        }}
        style={{
          borderRadius: "8px",
        }}
      >
        <Button
            style={{
              width: "100px",
              margin: "0px 20px 0px 20px",
              borderRadius: "8px",
            }}
            onClick={() => {
              setTableRecord(null)
              setOpendeleteModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              width: "100px",
              borderRadius: "8px",
            }}
            type="primary"
            onClick={handleDeleteEntry}
          >
            Delete
          </Button>
      </Modal>
    </div>
  );
};

export default User;
