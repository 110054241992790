import React, { useState, useEffect, useMemo  } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Form,
    Button,
    Modal,
    Input,
    Row,
    Col,
    Card,
    Spin
} from "antd";

// Authentication-Service
import { getToken } from "../../utils/AuthService";

// API-Services
import { 
    deviceCreateService,
    deviceUpdateService
} from "../../services/device.service";
import { zoneSingleService } from "../../services/zone.service";

// Redux-Functoins
import { addDevice, updateDevice } from "../../redux/deviceSlice";
import { locationController } from "../../redux/appUtilitySlice";
import { setSingleZone } from "../../redux/zoneSlice";

const DeviceForm = ({ setModal, tableRecord = null }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deviceForm] = Form.useForm();
    const { selectedLocation, selectedZone, selectedArea } = useSelector(locationController)

    const dispatch = useDispatch();
    const token = getToken();
    const tableRecordData = useMemo(() => tableRecord = tableRecord, [tableRecord]);

    const handleDeviceCreate = async (values) => {
        setLoading(true)
        const { 
            device_name
        } = values;
        deviceForm.resetFields();

        const result = await deviceCreateService({
            token,
            zone_id: selectedZone,
            device_name,
            setError,
            setLoading
        })
        if (result?.data) {
            dispatch(addDevice(
                result?.data
            ));
            setLoading(false)
            setModal(false);
        }
    }

    const handleCancel = () => {
        setModal(false)
        deviceForm.resetFields();
    };

    const handleDeviceUpdate = async(values) => {
        deviceForm.resetFields();
        setLoading(true);
        const { 
            device_name
        } = values;
        const result = await deviceUpdateService({
            id: tableRecordData.id,
            token,
            device_name,
            setError,
            setLoading
        });
        if (result?.data) {
            const result = await zoneSingleService({
                setError,
                setLoading,
                token,
                id: selectedArea
            })
            if(result?.status){
                dispatch(setSingleZone(result?.data))
            }
            // dispatch(
            //     updateDevice(
            //         result?.data
            //     )
            // );
            setLoading(false)
            setModal(false);
        }
    }

    if (error) {
        setTimeout(
            () => setError(null), 
            3000
        );
        return (
            <>
                <p style={{
                    color: "red",
                    height: "50vh",
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    fontSize: '18px'
                }}>
                    {error}
                </p>
                <Button
                    style={{
                        width: "130px",
                        margin: "30px auto",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </>
        )
    }

    if (tableRecord !== null) {
        return (
            <>
                {loading === false ? (
                <Form form={deviceForm} onFinish={(val) => handleDeviceUpdate(val)}>
                    Update Device Name*
                    <Form.Item
                        name="device_name"
                        rules={[
                            {
                                required: true,
                                message: "Enter device name",
                            }
                        ]}
                    >
                        <Input defaultValue={tableRecord.device_name} placeholder="Update device name" />
                    </Form.Item>
                
                    <Button
                        style={{
                            width: "130px",
                            margin: "0px 20px 0px 30px",
                            borderRadius: "8px",
                        }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            width: "130px",
                            borderRadius: "8px",
                        }}
                        type="primary"
                        htmlType="submit"
                    >
                        Update
                    </Button>
                </Form>
        ) : (
            <Card bordered={true} hoverable={false}>
                <Spin
                    size="large"
                    tip="Loading..."
                    spinning={loading}
                    style={{
                        height: "50vh",
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                />
            </Card>
        )}
            </>
        )
    }

    return (
        <>
            {loading === false ? (
                <Form form={deviceForm} onFinish={(val) => handleDeviceCreate(val)}>
                    New Device Name*
                    <Form.Item
                        name="device_name"
                        rules={[
                            {
                                required: true,
                                message: "Enter device name",
                            }
                        ]}
                    >
                        <Input placeholder="Give device name" />
                    </Form.Item>
                    
                    <Button
                        style={{
                            width: "130px",
                            margin: "0px 20px 0px 30px",
                            borderRadius: "8px",
                        }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            width: "130px",
                            borderRadius: "8px",
                        }}
                        type="primary"
                        htmlType="submit"
                    >
                        Create
                    </Button>
                </Form>
            ) : (
                <Card bordered={true} hoverable={false}>
                    <Spin
                        size="large"
                        tip="Loading..."
                        spinning={loading}
                        style={{
                            height: "50vh",
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    />
                </Card>
            )}
        </>
    )
}

export default DeviceForm

