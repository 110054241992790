import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Table,
  Dropdown,
  Row,
  Col,
  DatePicker,
  Button,
  Select,
  Menu,
  Modal,
  Space,
  Slider,
  Radio,
  Switch,
  Input,
} from "antd";
import moment from "moment";
import { SlidersOutlined, PlusCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import BarGraph from "../../../components/Statistic/BarGraph";
import RedAlert from "./../../../assets/images/red_alert.png";
import BlueAlert from "./../../../assets/images/blue_alert.png";
import GreyAlert from "./../../../assets/images/grey_alert.png";
// import AddZoneArea from "./AddZoneArea";

// Auth-Service
import { getToken } from "../../../utils/AuthService";

// API-Service
import { 
  areaSettings, 
  areaSettingsCreate,
  areaOccupancyCreate,
  areaDaylightCreate,
  areaOccupancyDaylightGet
 } from "../../../services/area.service";

// Redux Settings
import { locationController } from "../../../redux/appUtilitySlice";
import { selectMasterOverrrideList } from "../../../redux/locationSlice";
import { 
  selectSingleArea,
  setAreaSettings, 
  selectAreaSettings,
  setAreaOccupancy,
  selectAreaOccupancy,
  setAreaDaylight,
  selectAreaDaylight
} from "../../../redux/areaSlice";
// Form-UI
import ZoneForm from "../../../components/Zone/ZoneForm";

// Table-UI
import ZoneTable from "../../../components/Zone/ZoneTable";

// Custom-Hooks
import useDebounce from "../../../utils/Custom-hooks/useDebounce";

const { RangePicker } = DatePicker;
const { Option } = Select;
const Area = (props) => {
  let { selectedLocation } = useSelector(locationController);
  let masterOverrideData = useSelector(selectMasterOverrrideList);
  let singleAreaData = useSelector(selectSingleArea);
  const masterOverrideFilteredData = masterOverrideData?.filter((x) => x?.location_id === selectedLocation)

  const token = getToken();
  const dispatch = useDispatch();

  // Settings and Control Functioning

  let areaSettingsStoreValue = useSelector(selectAreaSettings);
  let areaOccupencyStoreValue = useSelector(selectAreaOccupancy);
  let areaDaylightStoreValue = useSelector(selectAreaDaylight);

  const [sense, setOnSensor] = useState("occupancy");

  const [isCreateSettings, setIsCreateSettings] = useState(false);
  const [isCreateOccupency, setIsCreateOccupency] = useState(false);
  const [isCreateDaylight, setIsCreateDaylight] = useState(false);

  const [areaSettingsValue, setAreaSettingsValue] = useState({
    brightness_level: areaSettingsStoreValue.brightness_level,
    max_trim_level: areaSettingsStoreValue.max_trim_level,
    color_temperature: areaSettingsStoreValue.color_temperature
  })
  const [areaOccupencyValue, setAreaOccupencyValue] = useState({
    vacant_time: areaOccupencyStoreValue.vacant_time, 
    dim_to_level: areaOccupencyStoreValue.dim_to_level, 
    hold_time_id: areaOccupencyStoreValue.hold_time_id 
  })

  const [areaDaylightValue, setAreaDaylightValue] = useState({
    vacant_time: areaDaylightStoreValue.vacant_time, 
    dim_to_level: areaDaylightStoreValue.dim_to_level, 
    hold_time_id: areaDaylightStoreValue.hold_time_id,
    sensitivity: areaDaylightStoreValue.sensitivity,
    lux_min: areaDaylightStoreValue.lux_min,
    lux_max: areaDaylightStoreValue.lux_max,
    max_trim_level: areaDaylightStoreValue.max_trim_level
  })

  const debouncedAreaSettings = useDebounce(areaSettingsValue, 3000)
  const debouncedAreaOccupency = useDebounce(areaOccupencyValue, 3000)
  const debouncedAreaDaylight = useDebounce(areaDaylightValue, 4000)

  const [addZoneForm] = Form.useForm();
  const [isModalAddZone, setModalAddZone] = useState(false);
  
 

useEffect( async() => {
  const result = await areaSettings({
    token,
    area_id: singleAreaData?.id
  })
  if(result?.status){
    dispatch(setAreaSettings(
      {
        brightness_level: result?.data?.brightness_level,
        max_trim_level: result?.data?.max_trim_level,
        color_temperature: result?.data?.color_temperature
      }
    ))
  }
},[])

useEffect(async() => {
  if(sense === 'occupancy'){
    const result = await areaOccupancyDaylightGet({
      token,
      area_id: singleAreaData?.id,
      type: sense
    })
    if(result?.status){
      dispatch(
        setAreaOccupancy({
          vacant_time: result?.data?.vacant_time,
          dim_to_level: result?.data?.dim_to_level,
          hold_time_id: result?.data?.hold_time_id
        })
      )
    }
  }
  if(sense === 'daylight'){
    const result = await areaOccupancyDaylightGet({
      token,
      area_id: singleAreaData?.id,
      type: sense
    })
    if(result?.status){
      dispatch(
        setAreaDaylight({
          vacant_time: result?.data?.vacant_time,
          dim_to_level: result?.data?.dim_to_level,
          hold_time_id: result?.data?.hold_time_id,
          sensitivity: result?.data?.sensitivity,
          lux_min: result?.data?.lux_min,
          lux_max: result?.data?.lux_max,
          max_trim_level: result?.data?.max_trim_level,
        })
      )
    }
  }
},[sense])

useEffect(async() => {
  if(isCreateSettings){
    if(debouncedAreaSettings){
      const {
        brightness_level,
        max_trim_level,
        color_temperature
      } = debouncedAreaSettings;
      const result = await areaSettingsCreate({
        token,
        area_id : singleAreaData?.id,
        brightness_level,
        max_trim_level,
        color_temperature
      })
      if(result?.status){
        const result = await areaSettings({
          token,
          area_id: singleAreaData?.id
        })
        if(result?.status){
          dispatch(setAreaSettings(
            {
              brightness_level: result?.data?.brightness_level,
              max_trim_level: result?.data?.max_trim_level,
              color_temperature: result?.data?.color_temperature
            }
          ))
        }
      }
    }
  }
},[debouncedAreaSettings])

useEffect(async() => {
  if(isCreateOccupency){
    if(debouncedAreaOccupency){
      const {
        vacant_time,
        dim_to_level,
        hold_time_id
      } = debouncedAreaOccupency;
      const result = await areaOccupancyCreate({
        token,
        area_id : singleAreaData?.id,
        type: sense,
        vacant_time,
        dim_to_level,
        hold_time_id
      })
      if(result?.status){
        const result = await areaOccupancyDaylightGet({
          token,
          area_id: singleAreaData?.id,
          type: sense
        })
        if(result?.status){
          dispatch(
            setAreaOccupancy({
              vacant_time: result?.data?.vacant_time,
              dim_to_level: result?.data?.dim_to_level,
              hold_time_id: result?.data?.hold_time_id
            })
          )
        }
      }
    }
  }
},[debouncedAreaOccupency])

useEffect(async() => {
  if(isCreateDaylight){
    if(debouncedAreaDaylight){
      const {
        vacant_time,
        dim_to_level,
        hold_time_id,
        sensitivity,
        lux_min,
        lux_max,
        max_trim_level
      } = debouncedAreaDaylight;
      const result = await areaDaylightCreate({
        token,
        area_id : singleAreaData?.id,
        type: sense,
        vacant_time,
        dim_to_level,
        hold_time_id,
        sensitivity,
        lux_min,
        lux_max,
        max_trim_level
      })
      if(result?.status){
        const result = await areaOccupancyDaylightGet({
          token,
          area_id: singleAreaData?.id,
          type: sense
        })
        if(result?.status){
          dispatch(
            setAreaDaylight({
              vacant_time: result?.data?.vacant_time,
              dim_to_level: result?.data?.dim_to_level,
              hold_time_id: result?.data?.hold_time_id,
              sensitivity: result?.data?.sensitivity,
              lux_min: result?.data?.lux_min,
              lux_max: result?.data?.lux_max,
              max_trim_level: result?.data?.max_trim_level,
            })
          )
        }
      }
    }
  }
},[debouncedAreaDaylight])

const showModalAddZone = () => {
  setModalAddZone(true);
};

const columns = [
    {
      title: "Area",
      dataIndex: "areaName",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Area #1",
          value: "Area #1",
        },
        {
          text: "Area #2",
          value: "Area #2",
        },
        {
          text: "Area #3",
          value: "Area #3",
        },
      ],
      sorter: (a, b) => {
        a = a.areaName || "";
        b = b.areaName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Zone",
      dataIndex: "zoneName",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Zone A",
          value: "Zone A",
        },
        {
          text: "Zone B",
          value: "Zone B",
        },
        {
          text: "Zone C",
          value: "Zone C",
        },
      ],
      sorter: (a, b) => {
        a = a.zoneName || "";
        b = b.zoneName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Device",
      dataIndex: "deviceName",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Dimmer",
          value: "Dimmer",
        },
        {
          text: "Light Sensor",
          value: "Light Sensor",
        },
        {
          text: "Controller",
          value: "Controller",
        },
      ],
      sorter: (a, b) => {
        a = a.deviceName || "";
        b = b.deviceName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Not Connected",
          value: "Not Connected",
        },
        {
          text: "Malfunction",
          value: "Malfunction",
        },
      ],
      sorter: (a, b) => {
        a = a.status || "";
        b = b.status || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
      render: (record) => {
        if (record === "Connected") {
          return (
            <>
              <img src={BlueAlert} style={{ height: "23px" }} /> Connected
            </>
          );
        } else if (record === "Not Connected") {
          return (
            <>
              <img src={RedAlert} style={{ height: "23px" }} /> Not Connected
            </>
          );
        } else if (record === "Mulfunction") {
          return (
            <>
              <img src={GreyAlert} style={{ height: "23px" }} /> Mulfunction
            </>
          );
        }
      },
    },
    {
      title: "Days Out",
      dataIndex: "days_out",
      sorter: (a, b) => {
        a = a.days_out || "";
        b = b.days_out || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Dropdown overlay={selector(record)}>
          {/* < rotate={90} /> */}
          <Button type="default">Check Device</Button>
        </Dropdown>
      ),
    },
  ];

  const location = [
    {
      title: "Area",
      dataIndex: "areaName",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Area #1",
          value: "Area #1",
        },
        {
          text: "Area #2",
          value: "Area #2",
        },
        {
          text: "Area #3",
          value: "Area #3",
        },
      ],
      sorter: (a, b) => {
        a = a.areaName || "";
        b = b.areaName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Total Zones",
      dataIndex: "totalZone",
      sorter: (a, b) => {
        a = a.totalZone || "";
        b = b.totalZone || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Energy Use Today",
      dataIndex: "energy_use",
      sorter: (a, b) => {
        a = a.energy_use || "";
        b = b.energy_use || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Energy Use Last 7",
      dataIndex: "energy_use_7days",
      sorter: (a, b) => {
        a = a.energy_use_7days || "";
        b = b.energy_use_7days || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Energy Use Last 30",
      dataIndex: "energy_use_30days",
      sorter: (a, b) => {
        a = a.energy_use_30days || "";
        b = b.energy_use_30days || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Energy Use YTD",
      dataIndex: "energy_use_ytd",
      sorter: (a, b) => {
        a = a.energy_use_ytd || "";
        b = b.energy_use_ytd || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
  ];

  function formatter(value) {
    return `${value}%`;
  }

  let max_trim_level = 100;
  let brightness_level = 100;

  if(masterOverrideFilteredData.length > 0){
    max_trim_level = masterOverrideFilteredData[0]?.max_trim_level;
    brightness_level = masterOverrideFilteredData[0]?.brightness_level;
  }

function onSelectTemperature (value){
  setIsCreateSettings(true)
  setAreaSettingsValue({
    ...areaSettingsValue,
    color_temperature: parseInt(value)
  })
}

function onSelectOccupencyHoldTime(value){
  setIsCreateOccupency(true)
  setAreaOccupencyValue({
    ...areaOccupencyValue,
    hold_time_id: value
  })
}

function onSelectDaylightHoldTime(value){
  setIsCreateDaylight(true)
  setAreaDaylightValue({
    ...areaDaylightValue,
    hold_time_id: value
  })
}

  let selector = (record) => (
    <Menu>
      <Menu.Item key="1">Edit</Menu.Item>
      <Menu.Item onClick={() => console.log("Hello world")} key="2">
        Delete
      </Menu.Item>
    </Menu>
  );
  
  const onSensor = (e) => {
    setOnSensor(e.target.value);
  };

  const holdTimeData = [
    {
      value: 1,
      title: "Always On"
    },
    {
      value: 2,
      title: "Always Off"
    },
    {
      value: 3,
      title: "Timer"
    }
  ]

  return (
    <div>
      <div className="content-layout">
        <>
          <h1>{singleAreaData.area_name}</h1>
          <Row gutter={[30, 0]}>
            <Col span={12}>
              <Card>
                <h1>Setting</h1>
                <h4>
                  Color Temperature &nbsp;&nbsp;
                  <Select 
                    defaultValue={areaSettingsValue.color_temperature}
                    style={{ width: 120 }} 
                    onChange={onSelectTemperature} 
                  >
                    <Option value="50">50</Option>
                    <Option value="70">70</Option>
                    <Option value="100">100</Option>
                  </Select>
                </h4>
                <Form
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  autoComplete="off"
                  style={{ marginTop: "50px", marginLeft: "10px" }}
                >
                  BRIGHTNESS
                  <Form.Item name="brightness">
                    <Slider
                      className="slider"
                      max={brightness_level}
                      // defaultValue={props.locationInfo.brightness}
                      defaultValue={areaSettingsValue.brightness_level}
                      tipFormatter={formatter}
                      onChange={(value) => {
                        setIsCreateSettings(true)
                        setAreaSettingsValue({
                          ...areaSettingsValue,
                          brightness_level: parseInt(value)
                        })
                      }}
                    />  
                  </Form.Item>
                  MAX TRIM LEVEL
                  <Form.Item name="max_trim_level">
                    <Slider
                      className="slider"
                      max={max_trim_level}
                      // defaultValue={props.locationInfo.max_trim_level}
                      defaultValue={areaSettingsValue.max_trim_level}
                      tipFormatter={formatter}
                      onChange={(value) => {
                        setIsCreateSettings(true)
                        setAreaSettingsValue({
                          ...areaSettingsValue,
                          max_trim_level: parseInt(value)
                        })
                      }}
                    />
                  </Form.Item>
                </Form>
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <h1>Sensor Control</h1>
                <Form form={addZoneForm}>
                  <Radio.Group
                    defaultValue="Last State"
                    onChange={onSensor}
                    size="large"
                  >
                    <Radio.Button
                      style={{
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                      value="occupancy"
                    >
                      Occupancy
                    </Radio.Button>
                    <Radio.Button
                      style={{
                        borderTopRightRadius: "10px",
                        borderEndEndRadius: "10px",
                      }}
                      value="daylight"
                    >
                      Daylight
                    </Radio.Button>
                  </Radio.Group>
                  <br />
                  <br />
                  {sense === "daylight" ? (
                    <>
                      <span className="input-title">SENSITIVITY</span>
                      <Slider
                        className="input-sensitivity"
                        min={0} 
                        max={100} 
                        tipFormatter={formatter}
                        defaultValue={areaDaylightStoreValue?.sensitivity}
                        onChange={(value) => {
                          setIsCreateDaylight(true)
                          setAreaDaylightValue({
                            ...areaDaylightValue,
                            sensitivity: parseInt(value)
                          })
                        }}
                      />

                      <span className="input-title">MAX TRIM LEVEL</span>
                      <Slider 
                        className="input-timeout" 
                        min={0} 
                        max={100} 
                        tipFormatter={formatter} 
                        defaultValue={areaDaylightStoreValue?.max_trim_level}
                        onChange={(value) => {
                          setIsCreateDaylight(true)
                          setAreaDaylightValue({
                            ...areaDaylightValue,
                            max_trim_level: parseInt(value)
                          })
                        }}
                      />

                      <Row gutter={[6, 6]} style={{ marginTop: "20px" }}>
                        <Col span={7}>
                          <Form.Item name="zone_area">
                            <span className="input-title">LUX MIN</span>
                            <Input 
                              defaultValue={areaDaylightStoreValue?.lux_min}
                              onChange={(e) => {
                                setIsCreateDaylight(true)
                                setAreaDaylightValue({
                                  ...areaDaylightValue,
                                  lux_min: e.target.value
                                })
                              }}
                            className="input-device-id" />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item name="zone_area">
                            <span className="input-title">LUX MAX</span>
                            <Input 
                              defaultValue={areaDaylightStoreValue?.lux_max}
                              onChange={(e) => {
                                setIsCreateDaylight(true)
                                setAreaDaylightValue({
                                  ...areaDaylightValue,
                                  lux_max: e.target.value
                                })
                              }}
                              className="input-device-id" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[6, 6]} style={{ marginTop: "10px" }}>
                        <Col span={7}>
                          <Form.Item name="zone_area">
                            <span className="input-title">VACANT TIME</span>
                            <Input 
                            className="input-device-id" 
                            defaultValue={areaDaylightStoreValue?.vacant_time}
                            onChange={(e) => {
                              setIsCreateDaylight(true)
                              setAreaDaylightValue({
                                ...areaDaylightValue,
                                vacant_time: e.target.value
                              })
                            }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item name="zone_area">
                            <span className="input-title">DIME TO LEVEL</span>
                            <Input 
                              className="input-device-id" 
                              defaultValue={areaDaylightStoreValue?.dim_to_level}
                              onChange={(e) => {
                                setIsCreateDaylight(true)
                                setAreaDaylightValue({
                                  ...areaDaylightValue,
                                  dim_to_level: e.target.value
                                })
                              }}
                             />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <span className="input-title">HOLD TIME</span>
                          <Form.Item name="zone_area">
                            <Select 
                              showSearch
                              defaultValue={areaDaylightStoreValue?.hold_time_id}
                              style={{ width: 120 }} 
                              onChange={onSelectDaylightHoldTime} 
                            
                            >
                              {holdTimeData.map((item,index) => (
                                <Option key={index} value={item.value}>{item.title}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row gutter={[6, 6]} style={{ marginTop: "20px" }}>
                      <Col span={7}>
                        <Form.Item name="zone_area">
                          <span className="input-title">VACANT TIME</span>
                          <Input
                            onChange={(e) => {
                              setIsCreateOccupency(true)
                              setAreaOccupencyValue({
                                ...areaOccupencyValue,
                                vacant_time: e.target.value
                              })
                            }}
                            defaultValue={areaOccupencyStoreValue?.vacant_time}
                          className="input-device-id" />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item name="zone_area">
                          <span className="input-title">DIME TO LEVEL</span>
                          <Input 
                            onChange={(e) => {
                              setIsCreateOccupency(true)
                              setAreaOccupencyValue({
                                ...areaOccupencyValue,
                                dim_to_level: e.target.value
                              })
                            }}
                            defaultValue={areaOccupencyStoreValue?.dim_to_level}
                          className="input-device-id" />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item name="zone_area">
                          <span className="input-title">HOLD TIME</span>
                          <Select 
                            showSearch
                            defaultValue={areaOccupencyStoreValue?.hold_time_id}
                            style={{ width: 120 }} 
                            onChange={onSelectOccupencyHoldTime} 
                          >
                            {holdTimeData.map((item,index) => (
                              <Option key={index} value={item.value}>{item.title}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Form>
              </Card>
            </Col>
          </Row>
          <br />
          <br />
          <div>
            <h1 style={{ fontWeight: "bold", color: "#054792" }}>
              Areas Energy Usage
            </h1>

            <Row>
              <Col span={5}>
                <Card
                  style={{
                    borderStartStartRadius: "10px",
                    borderEndStartRadius: "10px",
                    height: "100%",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Current demand</h5>
                    <h1>20 kw.h</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card style={{ height: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Last 7 days</h5>
                    <h1>25 kw.h</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card style={{ height: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Last 30 days</h5>
                    <h1>5 kw.h</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card
                  style={{
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>
                      YTD <br />
                      (2020 Total Consumption)
                    </h5>
                    <h1>49 kw.h</h1>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>

          <br />

          <div>
            <h1 style={{ fontWeight: "bold", color: "#054792" }}>
              Areas Energy Costs
            </h1>
            <Row>
              <Col span={5}>
                <Card
                  style={{
                    borderStartStartRadius: "10px",
                    borderEndStartRadius: "10px",
                    height: "100%",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Current demand</h5>
                    <h1>$20.00</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card style={{ height: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Last 7 days</h5>
                    <h1>$50.00</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card style={{ height: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Last 30 days</h5>
                    <h1>$135.00</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card
                  style={{
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>
                      YTD <br />
                      (2020 Total Consumption)
                    </h5>
                    <h1>$230.00</h1>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          <br />
          {/* <div>
            <h1 style={{ fontWeight: "bold", color: "#054792" }}>
              Attention Required
            </h1>
            <Table
              columns={columns}
              dataSource={locations}
              pagination={false}
            />
          </div> */}
          <br />
          <div>
            <div style={{
                display: "flex",
                justifyContent: "space-between"
              }}>
                <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                  List of Zones
                </h1>
                <div>
                  <h1 style={{ fontWeight: "bold", color: "#096dd9", display: "inline-flex" }}>
                    Add Zones
                  </h1>
                  <Button
                  type="link"
                  onClick={() => showModalAddZone()}
                  icon={<PlusCircleFilled />} />
                </div>
            </div>
            <ZoneTable zoneData={singleAreaData?.dashboard_data?.list_of_zones?.zones} />
          </div>
          <br/>
          <div>
            <Row>
              <Col span={21}>
                <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                  Summary of Total Energy Use
                </h1>
              </Col>
              <Col span={3}>
                <a style={{ fontWeight: "bold", textAlign: "end" }}>
                  Export Report
                </a>
              </Col>
            </Row>
            <Card>
              <Row>
                <Col span={12}>
                  <h5>SELECT DATE RANGE</h5>
                  <RangePicker
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  />
                </Col>
                <Col span={4}>
                  <h5>AREA</h5>
                  <Select defaultValue="Area #1" style={{ width: 120 }}>
                    <Option value="Area #1">Area #1</Option>
                    <Option value="Area #2">Area #2</Option>
                    <Option value="Area #3">Area #3</Option>
                  </Select>
                </Col>
                <Col span={4}>
                  <h5>ZONE</h5>
                  <Select defaultValue="ZONE #1" style={{ width: 120 }}>
                    <Option value="ZONE #1">ZONE #1</Option>
                    <Option value="ZONE #2">ZONE #2</Option>
                    <Option value="ZONE #3">ZONE #3</Option>
                  </Select>
                </Col>
                <Col span={4}>
                  <h5>DEVICE</h5>
                  <Select defaultValue="DEVICE #1" style={{ width: 120 }}>
                    <Option value="DEVICE #1">DEVICE #1</Option>
                    <Option value="DEVICE #2">DEVICE #2</Option>
                    <Option value="DEVICE #3">DEVICE #3</Option>
                  </Select>
                </Col>
              </Row>
              <br />
              <br />
              <BarGraph />
            </Card>
          </div>
        </>
      </div>
      <Modal
         title="Zone Create Form"
        width={400}
        centered
        visible={isModalAddZone}
        footer={null}
        closable={false}
        style={{
          borderRadius: "8px",
        }}
      >
        <ZoneForm
          setModal={setModalAddZone}
         />
      </Modal>
    </div>
  );
};

export default Area;
