import React, { useState, useEffect } from "react";
import {
    Card,
    Table,
    Dropdown,
    Button,
    Menu,
    Spin,
    Modal
} from "antd";

import { useDispatch, useSelector } from "react-redux";

// Auth service
import { getToken } from "../../utils/AuthService";

// API service
import { 
    deviceListService,
    deviceDeleteService
} from "../../services/device.service";
 
// Redux-settings
import { 
    setDeviceList,
    deleteDevice,
    selectDeviceList
} from "../../redux/deviceSlice";
import { onDeviceSelect } from "../../redux/appUtilitySlice";
// Device-form 
import DeviceForm from "./DeviceForm";

const DeviceTable = ({deviceData=null}) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [tableRecord, setTableRecord] = useState(null)
    const [isOpendeleteModal, setOpendeleteModal] = useState(false)
    const [isUpdateModal, setIsUpdateModal] = useState(false)

    const dispatch = useDispatch()
    const token = getToken()
    const devices = useSelector(selectDeviceList)


    useEffect(() => {
        if(deviceData){
            dispatch(
                setDeviceList(deviceData)
            )
        }
        // const result = await deviceListService({
        //     token,
        //     setError,
        //     setLoading
        // })
        // if(result?.data){
        //     dispatch(
        //         setDeviceList(result.data)
        //     )
        //     setLoading(false)
        // }
    },[deviceData])

    const deviceColumn = [
        {
            title: "Device",
            dataIndex: "device_name",
            render: (text, record) => (
                <span 
                style={{textDecoration: "underline", cursor: "pointer"}} 
                onClick={async() =>{ 
                    setLoading(true)
                    dispatch(onDeviceSelect(record?.id))
                    // const result = await zoneSingleService({
                    //     setError,
                    //     setLoading,
                    //     token,
                    //     id: record?.id
                    // })
                    // if(result.status){
                    //     dispatch(setSingleZone(result?.data))
                    // }
                    setLoading(false)
                }}>
                    {text}
                </span>
            ),
            filters: [
                {
                text: "Device #1",
                value: "Device #1",
                },
                {
                text: "Device #2",
                value: "Device #2",
                },
                {
                text: "Device #3",
                value: "Device #3",
                },
            ],
            onFilter: (value, record) => record.device_name.indexOf(value) === 0,
            sorter: (a, b) => {
                a = a.device_name || "";
                b = b.device_name || "";
                return a.localeCompare(b);
            },
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Area Name",
            dataIndex: "area_name",
            filters: [
                {
                text: "Area #1",
                value: "Area #1",
                },
                {
                text: "Area #2",
                value: "Area #2",
                },
                {
                text: "Area #3",
                value: "Area #3",
                },
            ],
            onFilter: (value, record) => record.area_name.indexOf(value) === 0,
            sorter: (a, b) => {
                a = a.area_name || "";
                b = b.area_name || "";
                return a.localeCompare(b);
            },
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Location Name",
            dataIndex: "location_name",
            filters: [
                {
                text: "Location #1",
                value: "Location #1",
                },
                {
                text: "Location #2",
                value: "Location #2",
                },
                {
                text: "Location #3",
                value: "Location #3",
                },
            ],
            onFilter: (value, record) => record.location_name.indexOf(value) === 0,
            sorter: (a, b) => {
                a = a.location_name || "";
                b = b.location_name || "";
                return a.localeCompare(b);
            },
            sortDirections: ["descend", "ascend"],
        },
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <Dropdown overlay={selector(record)}>
              <Button type="default">Action</Button>
            </Dropdown>
          ),
        }
    ]

    const handleDelete = (record) => {
        setTableRecord(record)
        setOpendeleteModal(true)
    };

    const handleDeleteEntry = async () => {
        setLoading(true)
        const result = await deviceDeleteService({
            token,
            id: tableRecord?.id,
            setLoading,
            setError
        })
        if(result?.status){
            dispatch(
                deleteDevice(tableRecord?.id)
            )
            setLoading(false)
            setTableRecord(null)
            setOpendeleteModal(false)
        }
    }

    const handleUpdate = (record) => {
      setTableRecord(record)
      setIsUpdateModal(true)
    }

    const selector = (record) => (
      <Menu>
        <Menu.Item onClick={() => handleUpdate(record)} key="1">
          Edit
        </Menu.Item>
        <Menu.Item onClick={() => handleDelete(record)} key="2">
          Delete
        </Menu.Item>
      </Menu>
    );

    if(error){
        setTimeout(
            () => setError(null), 
            3000
          );
        return(
            <p style={{ 
            color: "red",
            height: "100vh", 
            display: 'flex', 
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            fontSize: '18px'
            }}>
            {error}
            </p>
        )
    }

    return (
        <div>
            {loading === false ? (
                 <Table
                 columns={deviceColumn}
                 dataSource={devices}
                 pagination={false}
               />
            ) : (
                <Card bordered={true} hoverable={false}>
                    <Spin 
                        size="large" 
                        tip="Loading..." 
                        spinning={loading}
                        style={{ 
                        height: "50vh", 
                        display: 'flex', 
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center'
                        }}
                    />
                </Card>
            )}
            <Modal
                width={310}
                centered
                title="Please Confirm"
                visible={isOpendeleteModal}
                footer={null}
                closable={false}
                okText="Save"
                onCancel={() => {
                    setOpendeleteModal(false)
                }}
                onOk={() => {
                    setOpendeleteModal(false)
                }}
                style={{
                borderRadius: "8px",
                }}
            >
                <Button
                    style={{
                        width: "100px",
                        margin: "0px 20px 0px 20px",
                        borderRadius: "8px",
                    }}
                    onClick={() => {
                        setTableRecord(null)
                        setOpendeleteModal(false)
                    }}
                    >
                    Cancel
                </Button>
                <Button
                    style={{
                        width: "100px",
                        borderRadius: "8px",
                    }}
                    type="primary"
                    onClick={handleDeleteEntry}
                    >
                    Delete
                </Button>
            </Modal>
            <Modal
              title="Device Update Form"
              width={400}
              centered
              visible={isUpdateModal}
              footer={null}
              closable={false}
              style={{
                borderRadius: "8px",
              }}
            >
              <DeviceForm 
                tableRecord = {tableRecord}
                setModal={setIsUpdateModal}
              />
            </Modal>
        </div>
    )
}

export default DeviceTable;