import React, { useEffect, useState  } from "react"
import axios from "axios"
import { getToken } from "../AuthService"
const url = process.env.REACT_APP_APP_API_ENDPOINT

export const useAuth = () => {
    const [session, setSession] = useState(false)
    const [loading, setLoading] = useState(false)
    const token = getToken();
    useEffect( () => {
        async function fetchSession() {
            try {
                setLoading(true)
                
                const result = await axios.get(url+'/api/v1/verify/token', {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Accept": "application/json"
                    }
                })

                if(result.status){
                    setLoading(false)
                    setSession(true);
                }
            }catch(err){
                setLoading(false);
            }
        }
        fetchSession()
    },[])
    return[session, loading]
}