import Cookie from "js-cookie"

// save the token and user into the cookies and expired in 7 days
export const setUserSession = (token, user) => {
  Cookie.set('user', JSON.stringify(user), { expires: 7 });
  Cookie.set('token', JSON.stringify(token), { expires: 7 });
}

// update user session
export const updateUserSession = (user) => {
  Cookie.remove('user');
  Cookie.set('user', JSON.stringify(user), { expires: 7 });
 
}


// return the user data from the cookies
export const getUser = () => {
  const userStr = Cookie.get('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}
  
// return the token from the cookies
export const getToken = () => {
  return Cookie.getJSON('token') || null;
}
  
// remove the token and user from the cookies
export const removeUserSession = () => {
  Cookie.remove('token');
  Cookie.remove('user');
}

// save the temporary token into the cookies and expired in 7 days
export const setTemporarySession = (token) => {
  Cookie.set('temporary_session', JSON.stringify(token), { expires: 7 });
}

// return the token from the cookies
export const getTemporarySession = () => {
  const TemporaryStr = Cookie.get('temporary_session');
  if (TemporaryStr) return JSON.parse(TemporaryStr);
  else return null;
}

// remove the temporary token
export const removeTemporarySession = () => {
  Cookie.remove('temporary_session');
}