import axios from "axios"

const url = process.env.REACT_APP_APP_API_ENDPOINT


export const areaListService = async ({ token, setError, setLoading }) => {
    try {
        let result = await axios.get(url + '/api/v1/areas', {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if (result?.status) {
            return result?.data
        }
    } catch (err) {
        console.log(err)
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const areaSingleService = async ({ token, id, setError, setLoading }) => {
    try {
        let result = await axios.get(url + `/api/v1/areas/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if (result?.status) {
            return result?.data
        }
    } catch (err) {
        setLoading(false)
        setError(err.response.data.message)
    }
}


export const areaCreateService = async ({
    token,
    area_name,
    location_id,
    setLoading,
    setError
}) => {
    try {
        let result = await axios.post(url + '/api/v1/areas',
            {
                area_name,
                location_id
            }, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if (result?.status) {
            return result?.data
        }
    } catch (err) {
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const areaUpdateService = async ({
    token,
    id,
    area_name,
    setError,
    setLoading
}) => {
    try {
        let result = await axios.put(url + `/api/v1/areas/${id}`,
            {
                area_name
            }, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if (result?.status) {
            return result?.data
        }
    } catch (err) {
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const areaDeleteService = async ({ token, id, setLoading, setError }) => {
    try {
        let result = await axios.delete(url + `/api/v1/areas/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if (result?.data?.status) {
            return result?.data
        }
    } catch (err) {
        setLoading(false)
        setError(err.response.data.message)
    }
}

// export const areaFindByID = async ({ token, id, setError, setLoading }) => {
//     try {
//         let result = await axios.get(url + `api/v1/areas/${id}`, {
//             headers: {
//                 Token: token
//             }
//         })
//         result = result?.data?.json()
//         return result
//     } catch (err) {
//         setLoading(false)
//         setError(err.response.data.message)
//     }
// }

export const areaSettingsCreate = async ({ 
    token,
    area_id, 
    brightness_level,
    max_trim_level,
    color_temperature }) => {
    try {
        let result = await axios.post(url + `/api/v1/area-settings`, {
            area_id, 
            brightness_level,
            max_trim_level,
            color_temperature
        },{
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if (result?.data?.status) {
            return result?.data
        }
    }catch (err) {
       // setLoading(false)
        console.log(err)
        //setError(err.response.data.message)
        //setError("Can't create area-settings")
    }
}

export const areaSettings = async ({ token, area_id }) => {
    try {
        let result = await axios.get(url + `/api/v1/area-settings`,{
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            },
            params: { area_id }
        })
        if (result?.data?.status) {
            return result?.data
        }
    }catch (err) {
       // setLoading(false)
        console.log(err)
        //setError(err.response.data.message)
        //setError("Can't create area-settings")
    }
}

export const areaOccupancyCreate = async ({ 
    token, 
    area_id, 
    type, 
    vacant_time, 
    dim_to_level,
    hold_time_id 
}) => {
    try {
        let result = await axios.post(url + `/api/v1/area-sensor-control`,{
                area_id,
                type,
                vacant_time,
                dim_to_level,
                hold_time_id
            },{
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if (result?.data?.status) {
            return result?.data
        }
    }catch (err) {
       // setLoading(false)
        console.log(err.response)
        //setError(err.response.data.message)
        //setError("Can't create area-settings")
    }
}

export const areaDaylightCreate = async ({ 
    token, 
    area_id, 
    type, 
    vacant_time, 
    dim_to_level,
    hold_time_id,
    sensitivity,
    lux_min,
    lux_max,
    max_trim_level
}) => {
    try {
        let result = await axios.post(url + `/api/v1/area-sensor-control`,{
                area_id,
                type,
                vacant_time,
                dim_to_level,
                hold_time_id,
                sensitivity,
                lux_min,
                lux_max,
                max_trim_level
            },{
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if (result?.data?.status) {
            return result?.data
        }
    }catch (err) {
       // setLoading(false)
        console.log(err.response)
        //setError(err.response.data.message)
        //setError("Can't create area-settings")
    }
}

export const areaOccupancyDaylightGet = async ({ token, type, area_id }) => {
    try {
        let result = await axios.get(url + `/api/v1/area-sensor-control`,{
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            },
            params: { 
                area_id,
                type
            }
        })
        if (result?.data?.status) {
            return result?.data
        }
    }catch (err) {
       // setLoading(false)
        console.log(err.response.data.message)
        //setError(err.response.data.message)
        //setError("Can't create area-settings")
    }
}
