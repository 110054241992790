import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user ",
  initialState: {
    user: null,
    userList: null,
    userRoleList: null,
    userProfile: null,
    userType: {
      super_admin : "super-admin",
      admin : "admin",
      location_owner : "location-owner",
      area_owner : "area-owner",
      zone_owner : "zone-owner"
    }
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
    userProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    updateUserPofile: (state, action) => {
      state.user.user.name = action.payload.name;
      state.user.user.email = action.payload.email;
    },
    setUserList: (state, action) => {
      state.userList = action.payload;
    },
    getUserRoleList: (state, action) => {
      state.userRoleList = action.payload;
    },
    createUser: (state, action) => {
      state.userList.push(action.payload);
    },
    updateUser: (state, action) => {
      state.userList.map(item => {
        if(item.id == action.payload.id){
          item.roles = action.payload.roles
        }
      });
    },
    deleteUser: (state, action) => {
      state.userList = state.userList?.filter(item => item.id !== action.payload);
    }
  },
});

export const {
  login,
  logout, 
  updateUserPofile, 
  setUserList,
  createUser, 
  updateUser, 
  deleteUser,
  getUserRoleList,
  userProfile
} = userSlice.actions;

export const selectUser = (state) => state.user.user;
export const selectUserType = (state) => state.user.userType;
export const selectUserList = (state) => state.user.userList;
export const selectUserRoleList = (state) => state.user.userRoleList;
export const selectUserProfile = (state) => state.user.userProfile;

export default userSlice.reducer;
