import React, { useState } from "react";

import { Space, Tag, Button } from "antd";

import { TagsOutlined } from "@ant-design/icons";

const Support = () => {
  return (
    <>
      <div style={{ textAlign: "center", marginTop: "100px" }}>
        <Space direction="vertical" size={20}>
          <img src="https://i.ibb.co/SKsLLVZ/support.png" alt="support" />
          <h2>Are you facing any problem?</h2>
          <h3>
            Our support will reply as soon as possible after you send us a
            message?
          </h3>
          <Button icon={<TagsOutlined />} type="primary">
            Open a Ticket
          </Button>
          <h3>or you can contact at</h3>
          <Space direction="horizontal" size={30}>
            <Tag color="blue">Email : support@domain.com </Tag>
            <Tag color="blue">Phone No : 012-3456789</Tag>
          </Space>
        </Space>
      </div>
    </>
  );
};

export default Support;
