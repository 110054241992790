import React, { useState } from "react";

import { Select, Button, Row, Col, Form, Input, Modal, Breadcrumb } from "antd";

import { PlusCircleFilled, HomeOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { addLocation, selectLocationList } from "../../redux/locationSlice";

import "./Scene.css";
import Scene from "./Scene";
import AddScene from "./AddScene";

const { Option } = Select;

const SceneHeader = () => {
  let locations = useSelector(selectLocationList);
  const [selectLocation, setLocation] = useState(locations[0].id);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectArea, setArea] = useState("");
  const [selectZone, setZone] = useState("");
  const [selectDevice, setDevice] = useState("");
  const [addZoneForm] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (event) => {
    console.log(event);
    dispatch(
      addLocation({
        id: 1,
        locationName: event.locationName,
        address: `${event.address_1} ${event.address_2}, ${event.city} ${event.state} ${event.post_code} `,
      })
    );
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function onSelectLocation(value) {
    setLocation(value);
  }

  const filtered = locations.filter((x) => x.id === selectLocation);
  return (
    <div>
      <div class="topnav-scene">
        <div className="border-top"></div>
        <div style={{ padding: "0px 15px 15px 15px", marginLeft: "10px" }}>
          <Breadcrumb>
            <Breadcrumb.Item>Location #1</Breadcrumb.Item>
            <Breadcrumb.Item>Scene</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col span={2}>
              <div className="header-title">Scenes</div>
            </Col>
            <Col span={3}>
              <AddScene />
            </Col>
          </Row>
        </div>
      </div>

      <Scene />

      <Modal
        width={400}
        centered
        visible={isModalVisible}
        footer={null}
        closable={false}
        style={{
          borderRadius: "8px",
        }}
      >
        <Form form={addZoneForm} onFinish={onFinish}>
          New Location Name*
          <Form.Item name="locationName">
            <Input placeholder="Insert location name" />
          </Form.Item>
          Address*
          <Form.Item name="address_1">
            <Input placeholder="Line 1" />
          </Form.Item>
          <Form.Item name="address_2">
            <Input placeholder="Line 2" />
          </Form.Item>
          <Row gutter={[20, 0]}>
            <Col span={12}>
              City*
              <Form.Item name="city">
                <Input placeholder="Insert city " />
              </Form.Item>
            </Col>
            <Col span={12}>
              State / Province*
              <Form.Item name="state">
                <Input placeholder="Insert state " />
              </Form.Item>
            </Col>
          </Row>
          Zip / Postal Code*
          <Form.Item name="post_code">
            <Input placeholder="Insert Zip / Post Code" />
          </Form.Item>
          Time Zone*
          <Form.Item name="time_zone">
            <Input placeholder="Time Zone " />
          </Form.Item>
          <Button
            style={{
              width: "130px",
              margin: "0px 20px 0px 30px",
              borderRadius: "8px",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            style={{
              width: "130px",
              borderRadius: "8px",
            }}
            type="primary"
            htmlType="submit"
          >
            Create
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default SceneHeader;
