import React, { useState } from "react";
import axios from "axios";

import moment from "moment";
// import { getToken } from "../../../utils/AuthService";
import {
  Form,
  Button,
  Switch,
  Card,
  Menu,
  Select,
  Input,
  Space,
  Modal,
  Row,
  Col,
  Radio,
  TimePicker,
  Checkbox,
  Dropdown,
} from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
  UpOutlined,
  SearchOutlined,
  DashOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import "./Schedule.css";
const { Option } = Select;

const Schedule = () => {
  const [updateList, setUpdateList] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [eventValue, setEventValue] = useState("");
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [addZoneForm] = Form.useForm();

  const plainOptions = ["Zone 1", "Zone 2", "Zone 3"];

  const onChange = (list) => {
    console.log(list);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e) => {
    console.log(e.target.checked);
    // setCheckedList(e.target.checked ? plainOptions : String[]:any)
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onFinish = (values) => {
    console.log(values);
  };

  const onSubmit = (values) => {
    setEventValue(
      values.time_picker.format("LT").concat("," + values.day_status)
    );
    setIsModalVisible(false);
    setIsModalVisible2(true);
  };

  const handleUpdateList = () => {
    console.log(updateList);
    setUpdateList(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showModal2 = () => {
    setIsModalVisible2(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalVisible2(false);
  };

  const handleChange = (value) => {
    console.log(value);
  };

  const menu = (record) => (
    <Menu>
      <Menu.Item key="1">
        <Link to={"/view-order/" + record}>View</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to={"/edit-order/" + record}>Edit</Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <div className="content-layout">
        <Row>
          <Col span={21}>
            <h1 style={{ fontWeight: "bold", color: "#054792" }}>
              Schedule Name
            </h1>
          </Col>
          <Col span={3}>
            <a style={{ fontWeight: "bold", textAlign: "end" }}>
              + Add Schedule
            </a>
          </Col>
        </Row>
        <Card>
          <Row>
            <Col span={22}>
              <h1 style={{ fontWeight: "bold" }}>Weekend</h1>
            </Col>
            <Col span={2}>
              <Switch defaultChecked onChange={onChange} />
              <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>
                  <DashOutlined rotate={90} />
                </a>
              </Dropdown>
            </Col>
          </Row>
        </Card>
        <br />

        <div>
          <Row>
            <Col span={21}>
              <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                Schedule Event
              </h1>
            </Col>
            <Col span={3}>
              <a style={{ fontWeight: "bold", textAlign: "end" }}>
                + Add Event
              </a>
            </Col>
          </Row>
          <Card>
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              autoComplete="off"
            >
              <br />
              <br />
              <Form.List name="schedule_event">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <div key={key}>
                        When this time
                        <Select
                          style={{ width: "80px" }}
                          onChange={handleChange}
                          defaultValue="On"
                        >
                          <Option value="On">On</Option>
                          <Option value="Off">Off</Option>
                        </Select>{" "}
                        <br />
                        <br />
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: "8px" }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "first"]}
                            fieldKey={[fieldKey, "first"]}
                            rules={[
                              { required: true, message: "Missing first name" },
                            ]}
                          >
                            <Input placeholder="Select Time" />
                          </Form.Item>{" "}
                          <Button type="primary" onClick={showModal2}>
                            Edit
                          </Button>
                          <h3>SET</h3>
                          <Form.Item
                            {...restField}
                            name={[name, "last"]}
                            fieldKey={[fieldKey, "last"]}
                            rules={[
                              { required: true, message: "Missing last name" },
                            ]}
                          >
                            <Input placeholder="Select Zone/Area/Device" />
                          </Form.Item>
                          <Button type="primary" onClick={showModal}>
                            Edit
                          </Button>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add field
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>

        <Modal
          title={<h1 className="form-title">Device Settings</h1>}
          centered
          visible={isModalVisible2}
          footer={null}
          closable={false}
          getContainer={false}
        >
          <Form form={addZoneForm} onFinish={onSubmit}>
            <Card className="form-device-setting">
              <Row gutter={[6, 6]}>
                <Col span={24}>
                  <span className="input-title">DEVICE MODE</span>
                  <Form.Item name="place_time">
                    <Radio.Group defaultValue="Time" size="large">
                      <Radio.Button
                        style={{
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                        value="Time"
                      >
                        Time
                      </Radio.Button>
                      <Radio.Button value="Sunrise">Sunrise</Radio.Button>
                      <Radio.Button
                        style={{
                          borderTopRightRadius: "10px",
                          borderEndEndRadius: "10px",
                        }}
                        value="Sunset"
                      >
                        Sunset
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <span className="input-title">SET TIME</span>
                  <Form.Item name="time_picker">
                    <TimePicker
                      defaultOpenValue={moment("00:00", "HH:mm")}
                      format="HH:mm"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <span className="input-title">REPEAT</span>
                  <Form.Item name="place_time">
                    <Radio.Group defaultValue="Time" size="large">
                      <Radio.Button
                        style={{
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                        value="Daily"
                      >
                        Daily
                      </Radio.Button>
                      <Radio.Button value="Weekly">Weekly</Radio.Button>
                      <Radio.Button value="Monthly">Monthly</Radio.Button>
                      <Radio.Button value="Yearly">Yearly</Radio.Button>
                      <Radio.Button
                        style={{
                          borderTopRightRadius: "10px",
                          borderEndEndRadius: "10px",
                        }}
                        value="Once"
                      >
                        Once
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="day_status">
                    <Checkbox.Group>
                      <Checkbox value="Mon" style={{ lineHeight: "32px" }}>
                        Mon
                      </Checkbox>
                      <Checkbox value="Tue" style={{ lineHeight: "32px" }}>
                        Tue
                      </Checkbox>
                      <Checkbox value="Wed" style={{ lineHeight: "32px" }}>
                        Wed
                      </Checkbox>
                      <Checkbox value="Thu" style={{ lineHeight: "32px" }}>
                        Thu
                      </Checkbox>
                      <Checkbox value="Fri" style={{ lineHeight: "32px" }}>
                        Fri
                      </Checkbox>
                      <Checkbox value="Sat" style={{ lineHeight: "32px" }}>
                        Sat
                      </Checkbox>
                      <Checkbox value="Sun" style={{ lineHeight: "32px" }}>
                        Sun
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Button className="button-form-close" onClick={handleCancel}>
              Close
            </Button>
            <Button
              className="button-form-save"
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Form>
        </Modal>
        <Modal
          width={495}
          title={<h1 className="form-title">Device Settings</h1>}
          centered
          visible={isModalVisible}
          footer={null}
          closable={false}
          getContainer={false}
        >
          <Input
            style={{
              width: "320px",
              margin: "0px 15px 15px 40px",
            }}
            placeholder="Search Areas"
            prefix={<SearchOutlined className="search-icon" />}
          />
          <Form
            className="modal-form-device"
            form={addZoneForm}
            onFinish={onFinish}
          >
            <Card
              style={{
                width: "320px",
                height: "45px",
                margin: "10px 0px 0px 40px",
              }}
            >
              <Space>
                <h2
                  style={{
                    margin: "-11px 180px 0px 0px",
                  }}
                >
                  Office
                </h2>
                <UpOutlined style={{ fontSize: "20px" }} />
              </Space>
            </Card>

            <Form.Item name="zone" style={{ margin: "20px 0px 0px 50px" }}>
              <Checkbox.Group value={checkedList} onChange={onChange}>
                <Space direction="vertical">
                  Zones
                  <Checkbox value="Row 1">Row 1</Checkbox>
                  <Checkbox value="Row 2">Row 2</Checkbox>
                  <Checkbox value="Row 3">Row 3</Checkbox>
                </Space>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item name="parking" style={{ margin: "20px 0px 0px 50px" }}>
              <Checkbox.Group value={checkedList} onChange={onChange}>
                <Space direction="vertical">
                  Scenes
                  <Checkbox value="All off - Parking Lot">
                    All off - Parking Lot
                  </Checkbox>
                  <Checkbox value="All on - Parking Lot">
                    All on - Parking Lot
                  </Checkbox>
                </Space>
              </Checkbox.Group>
            </Form.Item>
            <Button
              style={{
                width: "130px",
                margin: "120px 20px 0px 60px",
              }}
              onClick={handleCancel}
            >
              Close
            </Button>
            <Button
              style={{
                width: "130px",
              }}
              type="primary"
              htmlType="submit"
            >
              Done
            </Button>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Schedule;
