import React from "react";
import { useAuth } from "./useAuth";
import { Card,Spin, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Auth-Service
import { removeUserSession } from "../AuthService";

// Redux-Settings
import { logout } from "../../redux/userSlice";

export function withAuth(Component){
    
    return React.memo(
        function(props){
            const [session, loading] = useAuth()
            const dispatch = useDispatch()
            const history = useHistory()
            const handleLogout = () => {
                removeUserSession()
                dispatch(logout())
                history.push('/login')
            } 
            if(loading){
                return(
                    <>
                        <Card bordered={true} hoverable={false}>
                            <Spin
                                size="large"
                                tip="Loading..."
                                spinning={loading}
                                style={{
                                    height: "100vh",
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}
                            />
                        </Card>
                    </>
                )
            }
            
            if( !loading && !session){

                return(
                    <Card 
                        style={{
                            height: "100vh",
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                       <h2>Unauthorized of Token expired</h2>
                       <Button
                            style={{
                                width: "130px",
                                margin: "30px auto",
                                borderRadius: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                            onClick={handleLogout}
                        >
                            Goto Logout
                        </Button>
                    </Card>
                )
            }

            return <Component {...props} session={session} />;
        }
    )
}