import React, { useState } from "react";

import {
  Form,
  Button,
  Input,
  Modal,
  Space,
  Card,
  Select,
  Checkbox,
  Slider,
  Row,
  Col,
} from "antd";
import {
  PlusCircleFilled,
  PlusOutlined,
  SearchOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { renameLocation } from "../../redux/locationSlice";


const { Option } = Select;
const AddScene = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalSetting, setModalSetting] = useState(false);
  const [checkedList, setCheckedList] = useState([]);

  const [addZoneForm] = Form.useForm();
  //   const onChange = (list) => {
  //     console.log(list);
  //     setCheckedList(list);
  //     setIndeterminate(!!list.length && list.length < plainOptions.length);
  //     setCheckAll(list.length === plainOptions.length);
  //   };
  const dispatch = useDispatch();

  const onFinishSetting = (value) => {
    setModalSetting(false);
    console.log(value);
    // dispatch(
    //   renameLocation({
    //     id: props.id,
    //     areaName: value.areaName,
    //   })
    // );
  };

  const onFinish = (value) => {
    setModalSetting(false);
    console.log(value);
    // dispatch(
    //   renameLocation({
    //     id: props.id,
    //     areaName: value.areaName,
    //   })
    // );
    setModalSetting(true);
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalSetting(false);
  };

  return (
    <>
      <Button type="link" icon={<PlusCircleFilled />} onClick={showModal} />
      <Modal
        width={495}
        title={<h1 className="form-title">Device Settings</h1>}
        centered
        visible={isModalVisible}
        footer={null}
        closable={false}
        getContainer={false}
      >
        <Input
          style={{
            width: "320px",
            margin: "0px 15px 15px 40px",
          }}
          placeholder="Search Areas"
          prefix={<SearchOutlined className="search-icon" />}
        />
        <Form
          className="modal-form-device"
          form={addZoneForm}
          onFinish={onFinish}
        >
          <Card
            style={{
              width: "320px",
              height: "45px",
              margin: "10px 0px 0px 40px",
            }}
          >
            <Space>
              <h2
                style={{
                  position: "absolute",
                  margin: "-33px 180px 0px 0px",
                }}
              >
                Area #1
              </h2>
              {/* <UpOutlined
                style={{
                  fontSize: "20px",
                  position: "absolute",
                  textAlign: "left",
                  width: "100%",
                  margin: "-33px 180px 0px 0px",
                }}
              /> */}
            </Space>
          </Card>

          <Form.Item name="zone" style={{ margin: "20px 0px 0px 50px" }}>
            <Checkbox.Group value={checkedList}>
              <Space direction="vertical">
                Zones
                <Checkbox value="Row 1">Zone A</Checkbox>
                <Checkbox value="Row 2">Zone B</Checkbox>
              </Space>
            </Checkbox.Group>
          </Form.Item>
          <Button
            style={{
              width: "130px",
              margin: "120px 20px 0px 60px",
            }}
            onClick={handleCancel}
          >
            Close
          </Button>
          <Button
            style={{
              width: "130px",
            }}
            type="primary"
            htmlType="submit"
          >
            Done
          </Button>
        </Form>
      </Modal>
      <Modal centered visible={isModalSetting} footer={null}>
        <Card>
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinishSetting}
            autoComplete="off"
          >
            <br />
            <br />
            <Form.List name="schedule_event">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Card key={key} style={{ marginBottom: "10px" }}>
                        <Row>
                          <Col
                            style={{
                              borderRight: "1px solid grey",
                            }}
                            span={15}
                          >
                            Zone A<br />
                            Area #1
                            <Slider />
                          </Col>
                          <Col style={{ marginLeft: "10px" }} span={8}>
                            <Row
                              style={{
                                textAlign: "center",
                                margin: "auto",
                              }}
                            >
                              <Col style={{ marginRight: "10px" }} span={15}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "first"]}
                                  fieldKey={[fieldKey, "first"]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={5}>
                                <MinusCircleOutlined
                                  onClick={() => remove(name)}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add zone
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button
                style={{ width: "130px", margin: "0px 20px 0px 20px" }}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
              <Button
                style={{ width: "130px", margin: "0px 20px 0px 20px" }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </>
  );
};

export default AddScene;
