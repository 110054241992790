import { createSlice } from "@reduxjs/toolkit";

const areaSlice = createSlice({
  name: "area",
  initialState: {
    areaList:[],
    areaSingle: [],
    assignedUserList: [],
    assignedAreaId: null,
    areaSettings: {
      brightness_level: 0,
      max_trim_level: 0,
      color_temperature: 50
    },
    areaOccupancy: {
      vacant_time: 0,
      dim_to_level: 0,
      hold_time_id: 1
    },
    areaDaylight: {
      vacant_time: 0,
      dim_to_level: 0,
      hold_time_id: 1,
      sensitivity: 0,
      lux_min: 0,
      lux_max: 0,
      max_trim_level: 0
    }
  },
  reducers: {
    addArea: (state, action) => {
      const newArea = {
        id: action.payload.id,
        area_name: action.payload.area_name,
        location_id: action.payload.location_id,
        created_at:  action.payload.created_at,
        updated_at: action.payload.updated_at
      };
      state.areaList.push(newArea);
    },
    setAreaList: (state, action) => {
      state.areaList = action.payload;
    },
    setSingleArea: (state, action) => {
      state.areaSingle = action.payload;
    },
    updateArea: (state, action) => {
      const index = state.areaList.findIndex((item) => item.id === action.payload.id);
      state.areaList[index] = action.payload;
    },
    deleteArea: (state, action) => {
      state.areaList = state.areaList?.filter((item) => item.id !== action.payload);
    },
    setAssignedUser: (state, action) => {
      state.assignedUserList = action.payload;
    },
    setAssignedAreaId: (state, action) => {
      state.assignedAreaId = action.payload;
    },
    setAreaSettings: (state, action) => {
      state.areaSettings = {
        ...state.areaSettings,
        brightness_level: action.payload.brightness_level,
        max_trim_level: action.payload.max_trim_level,
        color_temperature: action.payload.color_temperature
      }
    },
    setAreaOccupancy: (state, action) => {
      state.areaOccupancy = {
        ...state.areaOccupancy,
        vacant_time: action.payload.vacant_time,
        dim_to_level: action.payload.dim_to_level,
        hold_time_id: action.payload.hold_time_id
      }
    },
    setAreaDaylight: (state, action) => {
      state.areaDaylight = {
        ...state.areaDaylight,
        vacant_time: action.payload.vacant_time,
        dim_to_level: action.payload.dim_to_level,
        hold_time_id: action.payload.hold_time_id,
        sensitivity: action.payload.sensitivity,
        lux_min: action.payload.lux_min,
        lux_max: action.payload.lux_max,
        max_trim_level: action.payload.max_trim_level,
      }
    }
  }
});

export const {
    addArea,
    setAreaList,
    setSingleArea,
    updateArea,
    deleteArea,
    setAssignedUser,
    setAssignedAreaId,
    setAreaSettings,
    setAreaDaylight,
    setAreaOccupancy
} = areaSlice.actions;

export const selectAreaList = (state) => state.areas.areaList;
export const selectSingleArea = (state) => state.areas.areaSingle;
export const selectAreaAssignedUserList = (state) => state.areas.assignedUserList;
export const selectAreaAssignedId = (state) => state.areas.assignedAreaId
export const selectAreaSettings = (state) => state.areas.areaSettings
export const selectAreaOccupancy = (state) => state.areas.areaOccupancy
export const selectAreaDaylight = (state) => state.areas.areaDaylight
export default areaSlice.reducer;
