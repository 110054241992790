import React, { useState, useEffect } from "react";
import {
  Form,
  Card,
  Table,
  Dropdown,
  Row,
  Col,
  DatePicker,
  Button,
  Select,
  Menu,
  Slider,
  Input,
  Radio,
  Modal
} from "antd";
import moment from "moment";
import { SlidersOutlined, PlusCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";


import BarGraph from "../../../components/Statistic/BarGraph";

import RedAlert from "./../../../assets/images/red_alert.png";
import BlueAlert from "./../../../assets/images/blue_alert.png";
import GreyAlert from "./../../../assets/images/grey_alert.png";

// Auth-Service
import { getToken } from "../../../utils/AuthService";

// Api-Service
import { 
  zoneSettingsCreate,
  zoneSettings,
  zoneOccupancyCreate,
  zoneDaylightCreate,
  zoneOccupancyDaylightGet
 } from "../../../services/zone.service";

// Table-UI
import DeviceTable from "../../../components/Device/DeviceTable"

// From-UI
import DeviceForm from "../../../components/Device/DeviceForm";

// Custom-Hooks
import useDebounce from "../../../utils/Custom-hooks/useDebounce";

// Redux-Functions
import { 
  selectSingleZone,
  setZoneSettings,
  setZoneDaylight,
  setZoneOccupancy,
  selectZoneSettings,
  selectZoneOccupancy,
  selectZoneDaylight
} from "../../../redux/zoneSlice";

import { addLocation, deleteLocation, selectLocationList } from "../../../redux/locationSlice";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Zone = (props) => {

const [isModalAddDevice, setModalAddDevice] = useState(false);
const [addZoneForm] = Form.useForm();
let singleZoneData = useSelector(selectSingleZone);
let locations = useSelector(selectLocationList);
const dispatch = useDispatch();
const token = getToken();

// Settings and Control Functioning
let zoneSettingsStoreValue = useSelector(selectZoneSettings);
let zoneOccupencyStoreValue = useSelector(selectZoneOccupancy);
let zoneDaylightStoreValue = useSelector(selectZoneDaylight);

const [sense, setOnSensor] = useState("occupancy");
const [isCreateSettings, setIsCreateSettings] = useState(false);
const [isCreateOccupency, setIsCreateOccupency] = useState(false);
const [isCreateDaylight, setIsCreateDaylight] = useState(false);

const [zoneSettingsValue, setZoneSettingsValue] = useState({
  brightness_level: zoneSettingsStoreValue.brightness_level,
  max_trim_level: zoneSettingsStoreValue.max_trim_level,
  color_temperature: zoneSettingsStoreValue.color_temperature
})
const [zoneOccupencyValue, setZoneOccupencyValue] = useState({
  vacant_time: zoneOccupencyStoreValue.vacant_time, 
  dim_to_level: zoneOccupencyStoreValue.dim_to_level, 
  hold_time_id: zoneOccupencyStoreValue.hold_time_id 
})

const [zoneDaylightValue, setZoneDaylightValue] = useState({
  vacant_time: zoneDaylightStoreValue.vacant_time, 
  dim_to_level: zoneDaylightStoreValue.dim_to_level, 
  hold_time_id: zoneDaylightStoreValue.hold_time_id,
  sensitivity: zoneDaylightStoreValue.sensitivity,
  lux_min: zoneDaylightStoreValue.lux_min,
  lux_max: zoneDaylightStoreValue.lux_max,
  max_trim_level: zoneDaylightStoreValue.max_trim_level
})

const debouncedZoneSettings = useDebounce(zoneSettingsValue, 3000)
const debouncedZoneOccupency = useDebounce(zoneOccupencyValue, 3000)
const debouncedZoneDaylight = useDebounce(zoneDaylightValue, 4000)

useEffect( async() => {
  const result = await zoneSettings({
    token,
    zone_id: singleZoneData?.id
  })
  if(result?.status){
    dispatch(setZoneSettings(
      {
        brightness_level: result?.data?.brightness_level,
        max_trim_level: result?.data?.max_trim_level,
        color_temperature: result?.data?.color_temperature
      }
    ))
  }
},[])

useEffect(async() => {
  if(sense === 'occupancy'){
    const result = await zoneOccupancyDaylightGet({
      token,
      zone_id: singleZoneData?.id,
      type: sense
    })
    if(result?.status){
      dispatch(
        setZoneOccupancy({
          vacant_time: result?.data?.vacant_time,
          dim_to_level: result?.data?.dim_to_level,
          hold_time_id: result?.data?.hold_time_id
        })
      )
    }
  }
  if(sense === 'daylight'){
    const result = await zoneOccupancyDaylightGet({
      token,
      zone_id: singleZoneData?.id,
      type: sense
    })
    if(result?.status){
      dispatch(
        setZoneDaylight({
          vacant_time: result?.data?.vacant_time,
          dim_to_level: result?.data?.dim_to_level,
          hold_time_id: result?.data?.hold_time_id,
          sensitivity: result?.data?.sensitivity,
          lux_min: result?.data?.lux_min,
          lux_max: result?.data?.lux_max,
          max_trim_level: result?.data?.max_trim_level,
        })
      )
    }
  }
},[sense])

useEffect(async() => {
  if(isCreateSettings){
    if(debouncedZoneSettings){
      const {
        brightness_level,
        max_trim_level,
        color_temperature
      } = debouncedZoneSettings;
      const result = await zoneSettingsCreate({
        token,
        zone_id : singleZoneData?.id,
        brightness_level,
        max_trim_level,
        color_temperature
      })
      if(result?.status){
        const result = await zoneSettings({
          token,
          zone_id: singleZoneData?.id
        })
        if(result?.status){
          dispatch(setZoneSettings(
            {
              brightness_level: result?.data?.brightness_level,
              max_trim_level: result?.data?.max_trim_level,
              color_temperature: result?.data?.color_temperature
            }
          ))
        }
      }
    }
  }
},[debouncedZoneSettings])

useEffect(async() => {
  if(isCreateOccupency){
    if(debouncedZoneOccupency){
      const {
        vacant_time,
        dim_to_level,
        hold_time_id
      } = debouncedZoneOccupency;
      const result = await zoneOccupancyCreate({
        token,
        zone_id : singleZoneData?.id,
        type: sense,
        vacant_time,
        dim_to_level,
        hold_time_id
      })
      if(result?.status){
        const result = await zoneOccupancyDaylightGet({
          token,
          zone_id: singleZoneData?.id,
          type: sense
        })
        if(result?.status){
          dispatch(
            setZoneOccupancy({
              vacant_time: result?.data?.vacant_time,
              dim_to_level: result?.data?.dim_to_level,
              hold_time_id: result?.data?.hold_time_id
            })
          )
        }
      }
    }
  }
},[debouncedZoneOccupency])

useEffect(async() => {
  if(isCreateDaylight){
    if(debouncedZoneDaylight){
      const {
        vacant_time,
        dim_to_level,
        hold_time_id,
        sensitivity,
        lux_min,
        lux_max,
        max_trim_level
      } = debouncedZoneDaylight;
      const result = await zoneDaylightCreate({
        token,
        zone_id : singleZoneData?.id,
        type: sense,
        vacant_time,
        dim_to_level,
        hold_time_id,
        sensitivity,
        lux_min,
        lux_max,
        max_trim_level
      })
      if(result?.status){
        const result = await zoneOccupancyDaylightGet({
          token,
          zone_id: singleZoneData?.id,
          type: sense
        })
        if(result?.status){
          dispatch(
            setZoneDaylight({
              vacant_time: result?.data?.vacant_time,
              dim_to_level: result?.data?.dim_to_level,
              hold_time_id: result?.data?.hold_time_id,
              sensitivity: result?.data?.sensitivity,
              lux_min: result?.data?.lux_min,
              lux_max: result?.data?.lux_max,
              max_trim_level: result?.data?.max_trim_level,
            })
          )
        }
      }
    }
  }
},[debouncedZoneDaylight])

function onSelectTemperature (value){
  setIsCreateSettings(true)
  setZoneSettingsValue({
    ...zoneSettingsValue,
    color_temperature: parseInt(value)
  })
}

function onSelectOccupencyHoldTime(value){
  setIsCreateOccupency(true)
  setZoneOccupencyValue({
    ...zoneOccupencyValue,
    hold_time_id: value
  })
}

function onSelectDaylightHoldTime(value){
  setIsCreateDaylight(true)
  setZoneDaylightValue({
    ...zoneDaylightValue,
    hold_time_id: value
  })
}

const showModalAddDevice = () => {
  setModalAddDevice(true);
};

const columns = [
  {
    title: "Area",
    dataIndex: "areaName",
    defaultSortOrder: "descend",
    filters: [
      {
        text: "Area #1",
        value: "Area #1",
      },
      {
        text: "Area #2",
        value: "Area #2",
      },
      {
        text: "Area #3",
        value: "Area #3",
      },
    ],
    sorter: (a, b) => {
      a = a.areaName || "";
      b = b.areaName || "";
      return a.localeCompare(b);
    },
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Zone",
    dataIndex: "zoneName",
    defaultSortOrder: "descend",
    filters: [
      {
        text: "Zone A",
        value: "Zone A",
      },
      {
        text: "Zone B",
        value: "Zone B",
      },
      {
        text: "Zone C",
        value: "Zone C",
      },
    ],
    sorter: (a, b) => {
      a = a.zoneName || "";
      b = b.zoneName || "";
      return a.localeCompare(b);
    },
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Device",
    dataIndex: "deviceName",
    defaultSortOrder: "descend",
    filters: [
      {
        text: "Dimmer",
        value: "Dimmer",
      },
      {
        text: "Light Sensor",
        value: "Light Sensor",
      },
      {
        text: "Controller",
        value: "Controller",
      },
    ],
    sorter: (a, b) => {
      a = a.deviceName || "";
      b = b.deviceName || "";
      return a.localeCompare(b);
    },
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Status",
    dataIndex: "status",
    defaultSortOrder: "descend",
    filters: [
      {
        text: "Not Connected",
        value: "Not Connected",
      },
      {
        text: "Malfunction",
        value: "Malfunction",
      },
    ],
    sorter: (a, b) => {
      a = a.status || "";
      b = b.status || "";
      return a.localeCompare(b);
    },
    sortDirections: ["descend", "ascend"],
    render: (record) => {
      if (record === "Connected") {
        return (
          <>
            <img src={BlueAlert} style={{ height: "23px" }} /> Connected
          </>
        );
      } else if (record === "Not Connected") {
        return (
          <>
            <img src={RedAlert} style={{ height: "23px" }} /> Not Connected
          </>
        );
      } else if (record === "Mulfunction") {
        return (
          <>
            <img src={GreyAlert} style={{ height: "23px" }} /> Mulfunction
          </>
        );
      }
    },
  },
  {
    title: "Days Out",
    dataIndex: "days_out",
    sorter: (a, b) => {
      a = a.days_out || "";
      b = b.days_out || "";
      return a.localeCompare(b);
    },
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Dropdown overlay={selector(record)}>
        {/* < rotate={90} /> */}
        <Button type="default">Check Device</Button>
      </Dropdown>
    ),
  },
];

const location = [
  {
    title: "Area",
    dataIndex: "areaName",
    defaultSortOrder: "descend",
    filters: [
      {
        text: "Area #1",
        value: "Area #1",
      },
      {
        text: "Area #2",
        value: "Area #2",
      },
      {
        text: "Area #3",
        value: "Area #3",
      },
    ],
    sorter: (a, b) => {
      a = a.areaName || "";
      b = b.areaName || "";
      return a.localeCompare(b);
    },
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Total Zones",
    dataIndex: "totalZone",
    sorter: (a, b) => {
      a = a.totalZone || "";
      b = b.totalZone || "";
      return a.localeCompare(b);
    },
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Energy Use Today",
    dataIndex: "energy_use",
    sorter: (a, b) => {
      a = a.energy_use || "";
      b = b.energy_use || "";
      return a.localeCompare(b);
    },
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Energy Use Last 7",
    dataIndex: "energy_use_7days",
    sorter: (a, b) => {
      a = a.energy_use_7days || "";
      b = b.energy_use_7days || "";
      return a.localeCompare(b);
    },
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Energy Use Last 30",
    dataIndex: "energy_use_30days",
    sorter: (a, b) => {
      a = a.energy_use_30days || "";
      b = b.energy_use_30days || "";
      return a.localeCompare(b);
    },
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Energy Use YTD",
    dataIndex: "energy_use_ytd",
    sorter: (a, b) => {
      a = a.energy_use_ytd || "";
      b = b.energy_use_ytd || "";
      return a.localeCompare(b);
    },
    sortDirections: ["descend", "ascend"],
  },
];

let selector = (record) => (
  <Menu>
    <Menu.Item key="1">Edit</Menu.Item>
    <Menu.Item onClick={() => console.log("Hello world")} key="2">
      Delete
    </Menu.Item>
  </Menu>
);

  function formatter(value) {
    return `${value}%`;
  }

  const onSensor = (e) => {
    setOnSensor(e.target.value);
  };

  const holdTimeData = [
    {
      value: 1,
      title: "Always On"
    },
    {
      value: 2,
      title: "Always Off"
    },
    {
      value: 3,
      title: "Timer"
    }
  ]

  return (
    <div>
      <div className="content-layout">
        <>
          <h1>{singleZoneData?.zone_name}</h1>
          <Row gutter={[30, 0]}>
            <Col span={12}>
              <Card>
                <h1>Setting</h1>
                <h4>
                  Color Temperature &nbsp;&nbsp;
                  <Select defaultValue="100" 
                    style={{ width: 120 }}
                    defaultValue={zoneSettingsStoreValue.color_temperature}
                    onChange={onSelectTemperature} 
                 >
                    <Option value="50">50</Option>
                    <Option value="70">70</Option>
                    <Option value="100">100</Option>
                  </Select>
                </h4>
                <Form
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  autoComplete="off"
                  style={{ marginTop: "50px", marginLeft: "10px" }}
                >
                  BRIGHTNESS
                  <Form.Item name="brightness">
                    <Slider
                      className="slider"
                      defaultValue={zoneSettingsStoreValue.brightness_level}
                      tipFormatter={formatter}
                      onChange={(value) => {
                        setIsCreateSettings(true)
                        setZoneSettingsValue({
                          ...zoneSettingsValue,
                          brightness_level: parseInt(value)
                        })
                      }}
                    />
                  </Form.Item>
                  MAX TRIM LEVEL
                  <Form.Item name="max_trim_level">
                    <Slider
                      className="slider"
                      defaultValue={zoneSettingsStoreValue.max_trim_level}
                      tipFormatter={formatter}
                      onChange={(value) => {
                        setIsCreateSettings(true)
                        setZoneSettingsValue({
                          ...zoneSettingsValue,
                          max_trim_level: parseInt(value)
                        })
                      }}
                    />
                  </Form.Item>
                </Form>
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <h1>Sensor Control</h1>
                <Form form={addZoneForm}>
                  <Radio.Group
                    defaultValue="Last State"
                    onChange={onSensor}
                    size="large"
                  >
                    <Radio.Button
                      style={{
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                      value="occupancy"
                    >
                      Occupancy
                    </Radio.Button>
                    <Radio.Button
                      style={{
                        borderTopRightRadius: "10px",
                        borderEndEndRadius: "10px",
                      }}
                      value="daylight"
                    >
                      Daylight
                    </Radio.Button>
                  </Radio.Group>
                  <br />
                  <br />
                  {sense === "daylight" ? (
                    <>
                      <span className="input-title">SENSITIVITY</span>
                      <Slider 
                        className="input-sensitivity"
                        min={0}
                        max={100}
                        defaultValue={zoneDaylightStoreValue?.sensitivity}
                        onChange={(value) => {
                          setIsCreateDaylight(true)
                          setZoneDaylightValue({
                            ...zoneDaylightValue,
                            sensitivity: parseInt(value)
                          })
                        }}
                      />

                      <span className="input-title">Max Trim Level</span>
                      <Slider 
                        className="input-timeout" 
                        min={0} 
                        max={100} 
                        defaultValue={zoneDaylightStoreValue?.max_trim_level}
                        onChange={(value) => {
                          setIsCreateDaylight(true)
                          setZoneDaylightValue({
                            ...zoneDaylightValue,
                            max_trim_level: parseInt(value)
                          })
                        }}
                        />

                      <Row gutter={[6, 6]} style={{ marginTop: "20px" }}>
                        <Col span={7}>
                          <Form.Item name="zone_area">
                            <span className="input-title">LUX MIN</span>
                            <Input 
                              className="input-device-id" 
                              defaultValue={zoneDaylightStoreValue?.lux_min}
                              onChange={(e) => {
                                setIsCreateDaylight(true)
                                setZoneDaylightValue({
                                  ...zoneDaylightValue,
                                  lux_min: e.target.value
                                })
                              }}
                             />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item name="zone_area">
                            <span className="input-title">LUX MAX</span>
                            <Input 
                              className="input-device-id" 
                              defaultValue={zoneDaylightStoreValue?.lux_max}
                              onChange={(e) => {
                                setIsCreateDaylight(true)
                                setZoneDaylightValue({
                                  ...zoneDaylightValue,
                                  lux_max: e.target.value
                                })
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[6, 6]} style={{ marginTop: "10px" }}>
                        <Col span={7}>
                          <Form.Item name="zone_area">
                            <span className="input-title">VACANT TIME</span>
                            <Input 
                              className="input-device-id" 
                              defaultValue={zoneDaylightStoreValue?.vacant_time}
                              onChange={(e) => {
                                setIsCreateDaylight(true)
                                setZoneDaylightValue({
                                  ...zoneDaylightValue,
                                  vacant_time: e.target.value
                                })
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item name="zone_area">
                            <span className="input-title">DIME TO LEVEL</span>
                            <Input 
                              className="input-device-id" 
                              defaultValue={zoneDaylightStoreValue?.dim_to_level}
                              onChange={(e) => {
                                setIsCreateDaylight(true)
                                setZoneDaylightValue({
                                  ...zoneDaylightValue,
                                  dim_to_level: e.target.value
                                })
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <span className="input-title">HOLD TIME</span>
                          <Form.Item name="zone_area">
                            <Select 
                              showSearch
                              defaultValue={zoneDaylightStoreValue?.hold_time_id}
                              style={{ width: 120 }} 
                              onChange={onSelectDaylightHoldTime} 
                            >
                              {holdTimeData.map((item,index) => (
                                <Option key={index} value={item.value}>{item.title}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row gutter={[6, 6]} style={{ marginTop: "20px" }}>
                      <Col span={7}>
                        <Form.Item name="zone_area">
                          <span className="input-title">VACANT TIME</span>
                          <Input 
                            className="input-device-id" 
                            onChange={(e) => {
                              setIsCreateOccupency(true)
                              setZoneOccupencyValue({
                                ...zoneOccupencyValue,
                                vacant_time: e.target.value
                              })
                            }}
                            defaultValue={zoneOccupencyStoreValue?.vacant_time}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item name="zone_area">
                          <span className="input-title">DIME TO LEVEL</span>
                          <Input 
                          className="input-device-id" 
                            onChange={(e) => {
                              setIsCreateOccupency(true)
                              setZoneOccupencyValue({
                                ...zoneOccupencyValue,
                                dim_to_level: e.target.value
                              })
                            }}
                            defaultValue={zoneOccupencyStoreValue?.dim_to_level}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <span className="input-title">HOLD TIME</span>
                        <Form.Item name="zone_area">
                        <Select 
                            showSearch
                            defaultValue={zoneOccupencyStoreValue?.hold_time_id}
                            style={{ width: 120 }} 
                            onChange={onSelectOccupencyHoldTime} 
                           
                          >
                            {holdTimeData.map((item,index) => (
                              <Option key={index} value={item.value}>{item.title}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Form>
              </Card>
            </Col>
          </Row>
          <br />
          <br />
          <div>
            <h1 style={{ fontWeight: "bold", color: "#054792" }}>
              Zones Energy Usage
            </h1>
            <Row>
              <Col span={5}>
                <Card
                  style={{
                    borderStartStartRadius: "10px",
                    borderEndStartRadius: "10px",
                    height: "100%",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Current demand</h5>
                    <h1>20 kw.h</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card style={{ height: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Last 7 days</h5>
                    <h1>25 kw.h</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card style={{ height: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Last 30 days</h5>
                    <h1>5 kw.h</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card
                  style={{
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>
                      YTD <br />
                      (2020 Total Consumption)
                    </h5>
                    <h1>49 kw.h</h1>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>

          <br />

          <div>
            <h1 style={{ fontWeight: "bold", color: "#054792" }}>
              Zones Energy Costs
            </h1>
            <Row>
              <Col span={5}>
                <Card
                  style={{
                    borderStartStartRadius: "10px",
                    borderEndStartRadius: "10px",
                    height: "100%",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Current demand</h5>
                    <h1>$20.00</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card style={{ height: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Last 7 days</h5>
                    <h1>$50.00</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card style={{ height: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Last 30 days</h5>
                    <h1>$135.00</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card
                  style={{
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>
                      YTD <br />
                      (2020 Total Consumption)
                    </h5>
                    <h1>$230.00</h1>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          <br />
          <div>
            <h1 style={{ fontWeight: "bold", color: "#054792" }}>
              Attention Required
            </h1>
            <Table
              columns={columns}
              dataSource={locations}
              pagination={false}
            />
          </div>
          <br />
          <div>
            <div style={{
                display: "flex",
                justifyContent: "space-between"
              }}>
                <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                  List of Device
                </h1>
                <div>
                  <h1 style={{ fontWeight: "bold", color: "#096dd9", display: "inline-flex" }}>
                    Add Device
                  </h1>
                  <Button
                  type="link"
                  onClick={() => showModalAddDevice()}
                  icon={<PlusCircleFilled />} />
                </div>
            </div>
            <DeviceTable deviceData={singleZoneData?.dashboard_data?.list_of_devices?.devices} />
          </div>
          <br />
          <div>
            <Row>
              <Col span={21}>
                <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                  Summary of Total Energy Use
                </h1>
              </Col>
              <Col span={3}>
                <a style={{ fontWeight: "bold", textAlign: "end" }}>
                  Export Report
                </a>
              </Col>
            </Row>
            <Card>
              <Row>
                <Col span={16}>
                  <h5>SELECT DATE RANGE</h5>
                  <RangePicker
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  />
                </Col>
                <Col span={4}>
                  <h5>LOCATION</h5>
                  <Select defaultValue="Location #1" style={{ width: 120 }}>
                    <Option value="Location #1">Location #1</Option>
                    <Option value="Location #2">Location #2</Option>
                    <Option value="Location #3">Location #3</Option>
                  </Select>
                </Col>
                <Col span={4}>
                  <h5>AREA</h5>
                  <Select defaultValue="Area #1" style={{ width: 120 }}>
                    <Option value="Area #1">Area #1</Option>
                    <Option value="Area #2">Area #2</Option>
                    <Option value="Area #3">Area #3</Option>
                  </Select>
                </Col>
              </Row>
              <br />
              <br />
              <BarGraph />
            </Card>
          </div>
        </>
      </div>
      <Modal
        width={400}
        centered
        visible={isModalAddDevice}
        footer={null}
        closable={false}
        style={{
          borderRadius: "8px",
        }}
      >
        <DeviceForm setModal={setModalAddDevice} />
      </Modal>
    </div>
  );
};

export default Zone;
