import React, { useState, useEffect } from "react";
import { Form, Slider, Button, Space } from "antd";

// Master Override Service
import { 
  masterOverrideUpdateService,
} from "../../services/masterOverride.service";

// Redux Settings
import { 
  updateMasterOverride
} from "../../redux/locationSlice";

const MasterOverrideSlider = ({
  filterdData, 
  setLoading, 
  setError, 
  setIsModalVisible,
  dispatch,
  token,
  locationInfo
}) => {
  const [defValue, setDefValue] = useState({
    brightness_level: 0,
    max_trim_level: 0
  });

  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     setDefValue({
  //       brightness_level : filterdData[0]?.brightness_level,
  //       max_trim_level : filterdData[0]?.max_trim_level,
  //     });
  //     setLoading(false)
  //   }, 1000);
  // },[filterdData]);

  const onFinish = async (values) => {
    setLoading(true)
    const { brightness_level, max_trim_level } = values;
    if( brightness_level && max_trim_level ){
      const result = await masterOverrideUpdateService({
        token,
        location_id: locationInfo?.id,
        brightness_level,
        max_trim_level,
        setLoading,
        setError
      })
      dispatch(
        updateMasterOverride(result?.data)
      );
      setLoading(false);
      setIsModalVisible(false);
    }else{
      setLoading(false)
      setError('Please select brightness and trim level')
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  function formatter(value) {
    return `${value}%`;
  }
  return (
    <>
        {filterdData.length > 0 ? (
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            autoComplete="off"
            style={{ marginTop: "50px", marginLeft: "40px" }}
          >
            BRIGHTNESS with data
            <Form.Item name="brightness_level">
              <Slider
                className="slider"
                defaultValue = {parseInt(filterdData[0]?.brightness_level)}
                tipFormatter = {formatter}
              />
            </Form.Item>
            MAX TRIM LEVEL with data
            <Form.Item name="max_trim_level">
              <Slider
                className="slider"
                defaultValue = {parseInt(filterdData[0]?.max_trim_level)}
                tipFormatter = {formatter}
              />
            </Form.Item>
            <Space>
              <Form.Item>
                <Button
                  style={{ width: "130px", margin: "0px 20px 0px 20px" }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  style={{
                    width: "130px",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </Space>
          </Form>
        ) : (
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            autoComplete="off"
            style={{ marginTop: "50px", marginLeft: "40px" }}
          >
            BRIGHTNESS without data
            <Form.Item name="brightness_level">
              <Slider
                className="slider"
                //defaultValue= {brightness_level_value}
                tipFormatter={formatter}
              />
            </Form.Item>
            MAX TRIM LEVEL without data
            <Form.Item name="max_trim_level">
              <Slider
                className="slider"
                //defaultValue= {max_trim_level_value}
                tipFormatter={formatter}
              />
            </Form.Item>
            <Space>
              <Form.Item>
                <Button
                  style={{ width: "130px", margin: "0px 20px 0px 20px" }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  style={{
                    width: "130px",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </Space>
          </Form>
        )}
    </>
  )
};

export default MasterOverrideSlider;
