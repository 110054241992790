import axios from "axios"

const url = process.env.REACT_APP_APP_API_ENDPOINT

export const masterOverrideGetService = async ({
        location_id,
        setError, 
        setLoading,
        token
    }) => {
    try {
        let result = await axios.get(url + '/api/v1/master-override',{
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            },
            params: {
                location_id: location_id
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response)
    }
}

export const masterOverrideUpdateService = async ({
    location_id,
    brightness_level, 
    max_trim_level, 
    setError, 
    setLoading,
    token
}) => {
try {
    let result = await axios.post(url + '/api/v1/master-override',{
        location_id,
        brightness_level, 
        max_trim_level
    },{
        headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": "application/json"
        }
    })
    if(result.status){
        return result.data
    }
}catch(err){
    setLoading(false)
    setError(err.response.data.message)
}
}