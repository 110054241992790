import { Column } from "@antv/g2plot";
import React, { useEffect, useState } from "react";

const data = [
  {
    type: "Jan",
    sales: 38,
  },
  {
    type: "Feb",
    sales: 52,
  },
  {
    type: "Mar",
    sales: 61,
  },
  {
    type: "Apr",
    sales: 145,
  },
  {
    type: "May",
    sales: 48,
  },
];

const BarGraph = () => {
  useEffect(() => {
    const columnPlot = new Column("container-10", {
      data,
      xField: "type",
      yField: "sales",
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
        color: '#a8ddb5',
      meta: {
        type: {
          alias: "类别",
        },
        sales: {
          alias: "销售额",
        },
      },
      minColumnWidth: 60,
      maxColumnWidth: 60,
    });
    columnPlot.render();
  }, []);
  return <div id="container-10"></div>;
};
export default BarGraph;
