import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "locations ",
  initialState: {
    timeZoneList: [
      {
        name: 'Eastern Time',
        value: 'America/New_York'
      },
      {
        name: 'Central Time',
        value: 'America/Chicago'
      },
      {
        name: 'Mountain Time',
        value: 'America/Denver'
      },
      {
        name: 'Mountain Time (no DST)',
        value: 'America/Phoenix'
      },
      {
        name: 'Pacific Time',
        value: 'America/Los_Angeles'
      },
      {
        name: 'Alaska Time',
        value: 'America/Anchorage'
      },
      {
        name: 'Hawaii-Aleutian',
        value: 'America/Adak'
      },
      {
        name: 'Hawaii-Aleutian Time (no DST)',
        value: 'Pacific/Honolulu'
      }
    ], 
    locationList:[],
    assignedUserList: [],
    assignedLocationId: null,
    masterOverrrideList: [],
    singleLocation:{},
  },
  reducers: {
    addLocation: (state, action) => {
      const newLocation = {
        id: action.payload.id,
        location_name: action.payload.location_name,
        lane_1: action.payload.lane_1,
        lane_2:  action.payload.lane_2,
        city: action.payload.city,
        zip: action.payload.zip,
        timezone: action.payload.timezone,
        state: action.payload.state
      };
      state.locationList.push(newLocation);
    },
    setLocationList: (state, action) => {
      state.locationList = action.payload;
    },
    setSingleLocation: (state, action) => {
      state.singleLocation = action.payload
    },
    setAssignedUser: (state, action) => {
      state.assignedUserList = action.payload;
    },
    setAssignedLocationId: (state, action) => {
      state.assignedLocationId = action.payload;
    },
    updateLocation: (state, action) => {
      const index = state.locationList.findIndex((item) => item.id === action.payload.id);
      state.locationList[index] = action.payload;
    },
    deleteLocation: (state, action) => {
      state.locationList = state.locationList?.filter((todo) => todo.id !== action.payload);
    },
    setTimeZoneList: (state, action) => {
      state.timeZoneList = action.payload;
    },
    addDevice: (state, action) => {
      const index = state.locationList.findIndex((item) => item.id === action.payload.id);
      state.locationList[index].deviceName = action.payload.deviceName;
      state.locationList[index].totalDevice = 1;
    },
    addZone: (state, action) => {
      const index = state.locationList.findIndex((item) => item.id === action.payload.id);
      state.locationList[index].zoneName = action.payload.zoneName;
    },
    renameLocation: (state, action) => {
      const index = state.locationList.findIndex((item) => item.id === action.payload.id);
      state.locationList[index].areaName = action.payload.areaName;
      state.locationList[index].totalArea = 1;
    },
    updateMasterOverride: (state, action) => {
      const index = state.masterOverrrideList.findIndex((item) => item?.location_id === action.payload?.location_id);
      if(index !== -1){
        state.masterOverrrideList[index] = action.payload;
      }else{
        state.masterOverrrideList.push(action.payload);
      }
    }
  },
});

export const {
  addLocation,
  setLocationList,
  setSingleLocation,
  renameLocation,
  deleteLocation,
  updateLocation,
  updateMasterOverride,
  addDevice,
  addZone,
  setTimeZoneList,
  setAssignedUser,
  setAssignedLocationId
} = locationSlice.actions;

export const selectLocationList = (state) => state.locations.locationList;
export const selectSingleLocation = (state) => state.locations.singleLocation;
export const selectsetAssignedUserList = (state) => state.locations.assignedUserList;
export const selectMasterOverrrideList = (state) => state.locations.masterOverrrideList;
export const selectsetAssignedUserLocationId = (state) => state.locations.assignedLocationId
export const selectTimeZoneList = (state) => state.locations.timeZoneList;

export default locationSlice.reducer;
