import { createSlice } from "@reduxjs/toolkit";

const deviceSlice = createSlice({
  name: "device",
  initialState: {
    deviceList: []
  },
  reducers: {
    addDevice: (state, action) => {
      const newDevice = {
        id: action.payload.id,
        device_name: action.payload.device_name,
        location_id: action.payload.location_id,
        zone_id: action.payload.zone_id,
        area_id: action.payload.area_id,
        created_at: action.payload.created_at,
        updated_at: action.payload.updated_at
      };
      state.deviceList.push(newDevice);
    },
    setDeviceList: (state, action) => {
      state.deviceList = action.payload;
    },
    updateDevice: (state, action) => {
      const index = state.deviceList.findIndex((item) => item.id === action.payload.id);
      state.deviceList[index] = action.payload;
    },
    deleteDevice: (state, action) => {
      state.deviceList = state.deviceList?.filter((item) => item.id !== action.payload);
    }
  },
});

export const { 
    setDeviceList,
    addDevice,
    deleteDevice,
    updateDevice
} = deviceSlice.actions;

export const selectDeviceList = (state) => state.devices.deviceList;

export default deviceSlice.reducer;
