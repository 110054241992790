import React, { useState } from "react";

import { Form, Button, Input, Modal, Space } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { renameLocation } from "../../redux/locationSlice";
import AreaForm from "../../components/Area/AreaForm";

const AddNewArea = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [addZoneForm] = Form.useForm();

  const dispatch = useDispatch();
  const onFinish = (value) => {
    console.log(value);
    dispatch(
      renameLocation({
        id: props.id,
        areaName: value.areaName,
      })
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  console.log(props)
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Space direction="vertical" size={20}>
          <img src="https://i.ibb.co/0cSbn29/not-found.png" alt="not-found" />
          <h2 style={{ fontWeight: "bold" }}>Empty Location</h2>
          <p>Brand New Location has been created</p>
          <Button
            type="primary"
            onClick={showModal}
            icon={<PlusCircleFilled />}
          >
            Add Area
          </Button>
        </Space>
      </div>

      <Modal width={400} centered visible={isModalVisible} footer={null}>
        <AreaForm 
        setModal={setIsModalVisible} />
      </Modal>
    </>
  );
};

export default AddNewArea;
