import React, { useState  } from "react";
import { useDispatch } from "react-redux";

import {
    Form,
    Button,
    Input,
    Card,
    Spin
} from "antd";

// Authentication-Service
import { getToken, getUser } from "../../utils/AuthService";

// User-Service
import { profileUpdateService } from "../../services/authentication.service";

// User-auth
import { updateUserSession } from "../../utils/AuthService";

const UserUpdateForm = ({ setModal }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [profileUpdateForm] = Form.useForm();
    
    const dispatch = useDispatch();
    const token = getToken();
    const user = getUser();

    const handleCancel = () => {
        setModal(false)
        profileUpdateForm.resetFields();
    };

    const handleUserUpdate = async(values) => {
        
        profileUpdateForm.resetFields();
        setLoading(true);
        const { name, email } = values;
        const result = await profileUpdateService({
            token,
            name,
            email,
            setError,
            setLoading
        });
        
        if (result?.status) {
            updateUserSession(result?.user)
            setLoading(false)
            setModal(false);
        }
    }

    if (error) {
        setTimeout(
            () => setError(null), 
            3000
        );
        return (
            <p style={{
                color: "red",
                height: "50vh",
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                fontSize: '18px'
            }}>
                {error}
            </p>
        )
    }

    return (
        <>
            {loading === false ? (
                <Form form={profileUpdateForm} onFinish={(val) => handleUserUpdate(val)}>
                    Update User Name*
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please Update name",
                            }
                        ]}
                    >
                        
                        <Input defaultValue={user?.name} placeholder="Update User Name" />
                    </Form.Item>
                    Update User Email*
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please Update Email",
                            }
                        ]}
                    >
                        <Input defaultValue={user?.email} placeholder="Update Email" />
                    </Form.Item>
                    <Button
                        style={{
                            width: "130px",
                            margin: "0px 20px 0px 30px",
                            borderRadius: "8px",
                        }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            width: "130px",
                            borderRadius: "8px",
                        }}
                        type="primary"
                        htmlType="submit"
                    >
                        Update Profile
                    </Button>
                </Form>
            ) : (
                <Card bordered={true} hoverable={false}>
                    <Spin
                        size="large"
                        tip="Loading..."
                        spinning={loading}
                        style={{
                            height: "50vh",
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    />
                </Card>
            )}
        </>
    )
}

export default UserUpdateForm

