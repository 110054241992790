import React, { useState, useEffect } from "react";

import { Form, Slider, Button, Card, Spin, Menu, Input, Modal, Empty, Space } from "antd";

import {
  SlidersOutlined,
  RightOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

//Auth service
import { getToken } from "../../utils/AuthService";

// Master Override Service
import { 
  masterOverrideUpdateService, 
  masterOverrideGetService 
} from "../../services/masterOverride.service";

// Redux Settings
import { updateMasterOverride, selectMasterOverrrideList } from "../../redux/locationSlice";

import MasterOverrideSlider from "./MasterOverrideSlider";

const MasterOverride = ({locationInfo}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [masterValue, setMasterValue] = useState({
    brightness_level: 0,
    max_trim_level: 0
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch();
  const token = getToken();
  let brightness_level_value = 0;
  let max_trim_level_value = 0;

  const masterOverrideData = useSelector(selectMasterOverrrideList)
  const masterOverrideFilteredData = masterOverrideData?.filter((x) => x?.location_id === locationInfo?.id)

  useEffect(async() => {
    if(locationInfo?.length !== 0 && masterOverrideFilteredData?.length < 1){
      setLoading(true)
      const result = await masterOverrideGetService({
        token,
        location_id: locationInfo?.id,
        setLoading,
        setError
      })
      //console.log('api calling')
      if(result?.data.id !== null){
        dispatch(
          updateMasterOverride(result?.data)
        );
      }
      setLoading(false)
    } 
  },[locationInfo])

  // useEffect(() => {
  //   console.log('Hooks work fine')
  //   console.log(masterOverrideFilteredData)
  //   if(masterOverrideFilteredData?.length > 0){
  //     brightness_level_value = masterOverrideFilteredData[0]?.brightness_level
  //     max_trim_level_value = masterOverrideFilteredData[0]?.max_trim_level
  //   } 
  // },[masterOverrideFilteredData])


  // if(masterOverrideFilteredData?.length > 0){
  //     brightness_level_value = masterOverrideFilteredData[0]?.brightness_level
  //     max_trim_level_value = masterOverrideFilteredData[0]?.max_trim_level
  // } 

  // console.log('brightness_level_value', brightness_level_value)
  // console.log('max_trim_level_value', max_trim_level_value)

  // const onFinish = async (values) => {
  //   setLoading(true)
  //   const { brightness_level, max_trim_level } = values;
  //   if( brightness_level && max_trim_level ){
  //     const result = await masterOverrideUpdateService({
  //       token,
  //       location_id: locationInfo?.id,
  //       brightness_level,
  //       max_trim_level,
  //       setLoading,
  //       setError
  //     })
  //     dispatch(
  //       updateMasterOverride(result?.data)
  //     );
  //     setLoading(false);
  //     setIsModalVisible(false);
  //   }else{
  //     setLoading(false)
  //     setError('Please select brightness and trim level')
  //   }
  // };
  
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function formatter(value) {
    return `${value}%`;
  }

  if(error){
    setTimeout(
        () => setError(null), 
        3000
    );
  }
  
  return (
    <>
      
      <Button style={{ height: "107px" }} onClick={showModal}>
        <div style={{ textAlign: "center" }}>
          <SlidersOutlined rotate={90} style={{ fontSize: "30px" }} />
          <h5 style={{ color: "#1d83f7" }}>Master Override</h5>
        </div>
      </Button>

      <Modal width={500} centered visible={isModalVisible} footer={null}>
        {loading === false &&
          error === null ? (
          <MasterOverrideSlider 
            filterdData={masterOverrideFilteredData}
            setLoading={setLoading}
            setError={setError}
            setIsModalVisible={setIsModalVisible}
            dispatch={dispatch}
            token={token}
            locationInfo={locationInfo}
          />
          ) : loading === true && error === null ? (
          <Card bordered={true} hoverable={false}>
            <Spin 
                size="large" 
                tip="Loading..." 
                spinning={loading}
                style={{ 
                height: "100%", 
                display: 'flex', 
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center'
                }}
            />
          </Card>
        ) : loading === false && error !== null ? (
          <p style={{ 
            color: "red",
            height: "100%", 
            display: 'flex', 
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            fontSize: '18px'
          }}>
            {error}
            <Button
                style={{
                width: "130px",
                margin: "20px 20px 0px 30px",
                borderRadius: "8px",
                }}
                onClick={() => setIsModalVisible(false)}
            >
                Cancel
            </Button>
          </p>
        ) : ("")}
      </Modal>
    </>
  );
};

export default MasterOverride;
