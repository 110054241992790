import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// Ant Design
import { 
    Form, 
    Input, 
    Button, 
    Select,
    Card,
    Spin
 } from "antd";
import { useDispatch } from "react-redux";

// Auth-Service
import { getToken } from '../../utils/AuthService';

// Api-Service
import { userCreateService, userListService } from '../../services/user.service';

// Redux-Settings
import { 
    createUser,
    setUserList,
    selectUserRoleList 
} from '../../redux/userSlice';

const CreateUserForm = ({setIsUserFromModalVisible}) => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [addUserFrom] = Form.useForm();
    const token = getToken();
    const dispatch = useDispatch();
    let userRoleListData = useSelector(selectUserRoleList)?.map(item => item.name);

    const handleUserCreate = async (values) => {
        setLoading(true)
        const {
            email, 
            name, 
            password, 
            password_confirmation,
            role
        } = values
        addUserFrom.resetFields()
        const result = await userCreateService({
            token,
            setError,
            setLoading,
            email, 
            name, 
            role: [role],
            password, 
            password_confirmation
        })
        if(result?.data){
            //dispatch(createUser(result?.data))
            const result = await userListService({
                token,
                setLoading,
                setError
            })
            if(result?.data){
                dispatch(setUserList(result?.data))
            }
            setIsUserFromModalVisible(false)
        }
        setLoading(false)
    }
    
    if(error){
        setTimeout(
            () => setError(null), 
            3000
        );
        return(
        <>
            <p style={{ 
                color: "red",
                height: "50vh", 
                display: 'flex', 
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                fontSize: '18px'
            }}>
                {error}
            </p>
            <Button
                style={{
                    width: "130px",
                    margin: "30px auto",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
                onClick={() => setIsUserFromModalVisible(false)}
            >
                Cancel
            </Button>
        </>
        )
    } 
    return(
        <>
            {loading === false ? (
                <Form 
                    form={addUserFrom} 
                    onFinish={(val) => handleUserCreate(val)}
                >
                Name
                <Form.Item 
                name="name"
                rules={[
                    {
                        required: true,
                        message: "Enter Name please",
                    }
                    ]}
                >
                    <Input placeholder="Enter Name" />
                </Form.Item>
                Email
                <Form.Item 
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Enter valid email please",
                        }
                    ]}
                >
                    <Input 
                        placeholder="Enter email" 
                        type="email"
                    />
                </Form.Item>
                Password
                <Form.Item 
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Enter password",
                        }
                    ]}
                >
                    <Input 
                        placeholder="Enter password" 
                        type="password"
                    />
                </Form.Item>
                Confirm Password
                <Form.Item 
                    name="password_confirmation"
                    rules={[
                        {
                            required: true,
                            message: "Confirm password",
                        }
                    ]}
                >
                    <Input 
                        placeholder="Confirm password" 
                        type="password"
                    />
                </Form.Item>
                Role
                <Form.Item 
                    name="role"
                    rules={[
                        {
                            required: true,
                            message: "Role",
                        }
                    ]}
                >
                    <Select
                        style={{
                            width: '100%',
                            marginLeft: "0px",
                            marginRight: "0px",
                        }}
                        placeholder="Change User Role"
                    >
                        {userRoleListData?.map((item,index) =>
                            <Select.Option value={item} key={index}>
                                <span>{item}</span>
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>

                <Button
                    style={{
                    width: "130px",
                    margin: "0px 20px 0px 30px",
                    borderRadius: "8px",
                    }}
                    onClick={() => setIsUserFromModalVisible(false)}
                >
                    Cancel
                </Button>
                <Button
                    style={{
                    width: "130px",
                    borderRadius: "8px",
                    }}
                    type="primary"
                    htmlType="submit"
                >
                    Create
                </Button>
                </Form>
                ):
                (
                    <Card bordered={true} hoverable={false}>
                        <Spin 
                            size="large" 
                            tip="Loading..." 
                            spinning={loading}
                            style={{ 
                            height: "50vh", 
                            display: 'flex', 
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center'
                            }}
                        />
                    </Card>
                )
            }
        </>
    )
}

export default CreateUserForm;