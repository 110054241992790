import axios from "axios"
const url = process.env.REACT_APP_APP_API_ENDPOINT


export const deviceListService = async ({token, setLoading, setError}) => {
    try {
        let result = await axios.get(url + '/api/v1/devices', {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const deviceCreateService = async ({
        token,
        zone_id,
        device_name,
        setError,
        setLoading
    }) => {
    try {
        let result = await axios.post(url + '/api/v1/devices', 
        { 
            zone_id,
            device_name,
            bluetooth_enabled:1,
            auto_detected:0,
            device_category_id:1,
            device_type_id:1,
            is_connected:1

        },{
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.status){
            console.log(result)
            return result.data
        }
    }catch(err){
        console.log(err.response)
        setLoading(false)
        setError(err.response.data.message)
    }
}


export const deviceUpdateService = async ({
    token,
    id,
    device_name,
    setError,
    setLoading
    }) => {
    try {
        let result = await axios.put(url + `/api/v1/devices/${id}`, 
        { 
            device_name
        },{
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const deviceDeleteService = async ({token,id,setLoading,setError}) => {
    try {
        let result = await axios.delete(url + `/api/v1/devices/${id}`, {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const deviceFindByIDService = async ({token,id,setLoading,setError}) => {
    try {
        let result = await axios.get(url + `/api/v1/devices/${id}`, {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}