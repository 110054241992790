import axios from "axios"
import { login, logout } from "../redux/userSlice";
import { onResetAppUtility } from "../redux/appUtilitySlice";
import { 
    setUserSession, 
    removeUserSession, 
    setTemporarySession,
    removeTemporarySession
} from "../utils/AuthService";

const url = process.env.REACT_APP_APP_API_ENDPOINT


export const loginService = async ({email, password, dispatch, userType, setError, setLoading, history}) => {
    try {
        let result = await axios.post( url + '/api/v1/login', {
            email,
            password
        })
        const {status, token, user} = result?.data
        console.log(user)
        if(status){
            setUserSession(token, user)
            if(user?.role === userType?.super_admin){
                history.push('/user')
            }
            dispatch(
                login({
                    user: user,
                    token: token
                })
            )
            dispatch(onResetAppUtility())
        }
    }catch(err){
        setLoading(false)
        setError(err?.response?.data?.message)
    }
}

export const logoutService = async ({token,  dispatch, history, setError,setLoading}) => {
    try {
        let result = await axios.post(url + '/api/v1/logout', {}, { 
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result?.status){
            removeUserSession()
            dispatch(logout())
            history.push("/login")
        }
    }catch(err){
        setLoading(false)
        setError("Uexpected error happened with logout")
    }
}


export const profileService = async ({token,setLoading,setError }) => {
    try {
        let result = await axios.get(url + '/api/v1/profile', {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        const { status, user } = result?.data;
        if(result?.data.status){
            return result?.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}


export const changePasswordService =  async ({
    token, 
    old_password, 
    password, 
    password_confirmation,
    setLoading,
    setError,
    history
}) => {
    try {
        let result = await axios.post(url + '/api/v1/change-password', 
        {
            old_password, 
            password, 
            password_confirmation 
        },{
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        
        if(result?.data?.status){
            setLoading(false)
            history.push('/login')
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
        
    }
}

export const forgetPasswordService =  async ({
    email, 
    setLoading, 
    setError,
    history
}) => {
    try {
        let result = await axios.post(url + '/api/v1/forget-password', {email})
        if(result?.data?.token){
            setLoading(false)
            setTemporarySession(result?.data)
            history.push("/reset_password")
            return true
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const resetPasswordService = async ({
    email,
    token, 
    password, 
    password_confirmation,
    setLoading,
    setError,
    history
}) => {
    try {
        let result = await axios.post(url + '/api/v1/reset-password', 
        {
            email,
            token,
            password, 
            password_confirmation 
        },{
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result?.data?.msg){
            setLoading(false)
            history.push("/login")
            removeTemporarySession()
            return true
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const profileUpdateService = async ({token, email, name, setError, setLoading, dispatch}) => {
    try {
        let result = await axios.post(url + '/api/v1/update-profile', {
            email,
            name
        },{
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result?.data?.status){
            return result?.data
        }
    }catch(err){
        setLoading(false)
        setError("Sorry can't update user profile")
    }
}


