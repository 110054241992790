import React, { useState } from "react";
import axios from "axios";

// import { getToken } from "../../../utils/AuthService";
import {
  Form,
  Card,
  Table,
  Dropdown,
  Row,
  Col,
  DatePicker,
  Button,
  Select,
  Menu,
  Input,
  Radio,
  Slider,
} from "antd";
import moment from "moment";
import { SlidersOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { addLocation, deleteLocation, selectLocationList } from "../../../redux/locationSlice";

import BarGraph from "../../../components/Statistic/BarGraph";

import RedAlert from "./../../../assets/images/red_alert.png";
import BlueAlert from "./../../../assets/images/blue_alert.png";
import GreyAlert from "./../../../assets/images/grey_alert.png";
import DeviceControl from "../../../components/DeviceControl";

// Table-UI
import DeviceTable from "../../../components/Device/DeviceTable"

const { RangePicker } = DatePicker;
const { Option } = Select;
const Device = (props) => {
  const [addZoneForm] = Form.useForm();

  const location = [
    {
      title: "Area",
      dataIndex: "areaName",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Area #1",
          value: "Area #1",
        },
        {
          text: "Area #2",
          value: "Area #2",
        },
        {
          text: "Area #3",
          value: "Area #3",
        },
      ],
      sorter: (a, b) => {
        a = a.areaName || "";
        b = b.areaName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Zones",
      dataIndex: "zoneName",
      sorter: (a, b) => {
        a = a.zoneName || "";
        b = b.zoneName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Schedule Name",
      dataIndex: "scheduleName",
      sorter: (a, b) => {
        a = a.scheduleName || "";
        b = b.scheduleName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Schedule Time",
      dataIndex: "scheduleTime",
      sorter: (a, b) => {
        a = a.scheduleTime || "";
        b = b.scheduleTime || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Device",
      dataIndex: "deviceName",
      sorter: (a, b) => {
        a = a.deviceName || "";
        b = b.deviceName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Schedule Details",
      key: "action",
      render: (text, record) => (
        <Dropdown overlay={selector(record)}>
          {/* < rotate={90} /> */}
          <Button type="default">Adjust</Button>
        </Dropdown>
      ),
    },
  ];

  const dispatch = useDispatch();

  let locations = useSelector(selectLocationList);

  //   const handleCreate = (event) => {
  //     event.preventDefault();
  //     dispatch(
  //       addLocation({
  //         locationName: event.locationName,
  //       })
  //     );
  //   };

  //   const handleDelete = (e) => {
  //     dispatch(
  //       deleteLocation({
  //         id: e,
  //       })
  //     );
  //   };

  let selector = (record) => (
    <Menu>
      <Menu.Item key="1">Edit</Menu.Item>
      <Menu.Item onClick={() => console.log("Hello world")} key="2">
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="content-layout">
        <>
          <h1 style={{ fontWeight: "bold", color: "#054792" }}>Device Info</h1>
          <Card style={{ padding: "20px" }}>
            <Row gutter={[6, 6]} style={{ marginTop: "20px" }}>
              <Col span={4}>
                <Form.Item name="zone_area">
                  <span className="input-title">DEVICE BRAND & MODEL</span>
                  <br />
                  <Input className="input-device-id" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="zone_area">
                  <span className="input-title">INSTALLED DATE</span>
                  <br />
                  <Input className="input-device-id" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="zone_area">
                  <span className="input-title">WARRANTY END DATE</span>
                  <br />
                  <Input className="input-device-id" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item name="zone_area">
                  <span className="input-title">DEVICE TYPE</span>
                  <br />
                  <Input className="input-device-id" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item name="zone_area">
                  <span className="input-title">STATUS</span>
                  <br />
                  <Input className="input-device-id" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[6, 6]} style={{ marginTop: "10px" }}>
              <Col span={4}>
                <Form.Item name="zone_area">
                  <span className="input-title">MIN OUTPUT WATT</span>
                  <br />
                  <Input className="input-device-id" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="zone_area">
                  <span className="input-title">MAX OUTPUT WATT</span>
                  <br />
                  <Input className="input-device-id" />
                </Form.Item>
              </Col>
            </Row>
            <span className="input-title">DEVICE MODE</span>
            <br />
            <Radio.Group defaultValue="Last State" size="large">
              <Radio.Button
                style={{
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                value="Dimmer"
              >
                Dimmer
              </Radio.Button>
              <Radio.Button
                style={{
                  borderTopRightRadius: "10px",
                  borderEndEndRadius: "10px",
                }}
                value="Scene"
              >
                Scene
              </Radio.Button>
            </Radio.Group>
            <br />
            <br />
            <h4>
              COLOR TEMPERATURE &nbsp;&nbsp;
              <br />
              <Select defaultValue="100K" style={{ width: 120 }}>
                <Option value="100">100K</Option>
                <Option value="200">200K</Option>
                <Option value="300">300K</Option>
              </Select>
            </h4>
            <br />
            <span className="input-title">BRIGHTNESS</span>
            <Slider className="input-sensitivity" min={0} max={100} />
            <br />
            <span className="input-title">MAX TRIM LEVEL</span>
            <Slider className="input-timeout" min={0} max={100} />
          </Card>
          <br />
          <DeviceControl />
          <br />
          <div>
            <h1 style={{ fontWeight: "bold", color: "#054792" }}>
              List of Device
            </h1>
           <DeviceTable />
          </div>
          <br />
          <br />
          <div>
            <h1 style={{ fontWeight: "bold", color: "#054792" }}>
              Devices Energy Usage
            </h1>
            <Row>
              <Col span={5}>
                <Card
                  style={{
                    borderStartStartRadius: "10px",
                    borderEndStartRadius: "10px",
                    height: "100%",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Current demand</h5>
                    <h1>20 kw.h</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card style={{ height: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Last 7 days</h5>
                    <h1>25 kw.h</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card style={{ height: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Last 30 days</h5>
                    <h1>5 kw.h</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card
                  style={{
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>
                      YTD <br />
                      (2020 Total Consumption)
                    </h5>
                    <h1>49 kw.h</h1>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>

          <br />

          <div>
            <h1 style={{ fontWeight: "bold", color: "#054792" }}>
              Device Energy Costs
            </h1>
            <Row>
              <Col span={5}>
                <Card
                  style={{
                    borderStartStartRadius: "10px",
                    borderEndStartRadius: "10px",
                    height: "100%",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Current demand</h5>
                    <h1>$20.00</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card style={{ height: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Last 7 days</h5>
                    <h1>$50.00</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card style={{ height: "100%" }}>
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>Last 30 days</h5>
                    <h1>$135.00</h1>
                  </div>
                </Card>
              </Col>
              <Col span={5}>
                <Card
                  style={{
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <h5 style={{ color: "#818181" }}>
                      YTD <br />
                      (2020 Total Consumption)
                    </h5>
                    <h1>$230.00</h1>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          <br />
          <div>
            <Row>
              <Col span={21}>
                <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                  Summary of Total Energy Use
                </h1>
              </Col>
              <Col span={3}>
                <a style={{ fontWeight: "bold", textAlign: "end" }}>
                  Export Report
                </a>
              </Col>
            </Row>
            <Card>
              <Row>
                <Col span={12}>
                  <h5>SELECT DATE RANGE</h5>
                  <RangePicker
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  />
                </Col>
                <Col span={4}>
                  <h5>AREA</h5>
                  <Select defaultValue="Area #1" style={{ width: 120 }}>
                    <Option value="Area #1">Area #1</Option>
                    <Option value="Area #2">Area #2</Option>
                    <Option value="Area #3">Area #3</Option>
                  </Select>
                </Col>
                <Col span={4}>
                  <h5>ZONE</h5>
                  <Select defaultValue="ZONE #1" style={{ width: 120 }}>
                    <Option value="ZONE #1">ZONE #1</Option>
                    <Option value="ZONE #2">ZONE #2</Option>
                    <Option value="ZONE #3">ZONE #3</Option>
                  </Select>
                </Col>
                <Col span={4}>
                  <h5>DEVICE</h5>
                  <Select defaultValue="DEVICE #1" style={{ width: 120 }}>
                    <Option value="DEVICE #1">DEVICE #1</Option>
                    <Option value="DEVICE #2">DEVICE #2</Option>
                    <Option value="DEVICE #3">DEVICE #3</Option>
                  </Select>
                </Col>
              </Row>
              <br />
              <br />
              <BarGraph />
            </Card>
          </div>
        </>
      </div>
    </div>
  );
};

export default Device;
