import React, { useState, useMemo  } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Form,
    Button,
    Input,
    Card,
    Spin
} from "antd";

// Authentication-Service
import { getToken } from "../../utils/AuthService";

// API-Service
import { 
    zoneCreateService,
    zoneUpdateService
} from "../../services/zone.service";
import { areaSingleService } from "../../services/area.service";

// Redux-Functoins
import { 
    updateZone,
    addZone
} from "../../redux/zoneSlice";
import { setSingleArea } from "../../redux/areaSlice";
import { onAreaSelect, locationController } from "../../redux/appUtilitySlice";

const ZoneForm = ({ setModal, tableRecord = null }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [zoneForm] = Form.useForm();
    const { selectedZone, selectedArea } = useSelector(locationController)
    const dispatch = useDispatch();
    const token = getToken();
    const tableRecordData = useMemo(() => tableRecord = tableRecord, [tableRecord]);

    const handleZoneCreate = async (values) => {
        setLoading(true)
        const { 
            zone_name
        } = values;
        zoneForm.resetFields();
        const result = await zoneCreateService({
            token,
            area_id: selectedArea,
            zone_name,
            setError,
            setLoading
        })
        if (result?.data) {
            const result = await areaSingleService({
                setError,
                setLoading,
                token,
                id: selectedArea
            })
            if(result?.status){
                dispatch(setSingleArea(result?.data))
                //dispatch(onAreaSelect(selectedArea))
            }
            setLoading(false)
            setModal(false)
        }
    }

    const handleCancel = () => {
        setModal(false)
        zoneForm.resetFields();
    };

    const handleZoneUpdate = async(values) => {
        zoneForm.resetFields();
        setLoading(true);
        const { 
            zone_name
        } = values;
        const result = await zoneUpdateService({
            id: tableRecordData.id,
            token,
            zone_name,
            setError,
            setLoading
        });
        if (result?.data) {
            const result = await areaSingleService({
                setError,
                setLoading,
                token,
                id: selectedArea
            })
            if(result?.status){
                dispatch(setSingleArea(result?.data))
               //dispatch(onAreaSelect(selectedArea))
            }
            setLoading(false);
            setModal(false);
        }
    }

    if (error) {
        setTimeout(
            () => setError(null), 
            3000
        );
        return (
            <>
            <p style={{
                color: "red",
                height: "50vh",
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                fontSize: '18px'
            }}>
                {error}
            </p>
            <Button
                style={{
                    width: "130px",
                    margin: "30px auto",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
                onClick={handleCancel}
            >
                Cancel
            </Button>
            </>
        )
    }

    if (tableRecord !== null) {
        return (
            <>
                {loading === false ? (
                <Form form={zoneForm} onFinish={(val) => handleZoneUpdate(val)}>
                    Update Zone Name*
                    <Form.Item
                        name="zone_name"
                        rules={[
                            {
                                required: true,
                                message: "Enter zone name",
                            }
                        ]}
                    >
                        <Input defaultValue={tableRecord.zone_name} placeholder="Give zone name" />
                    </Form.Item>
                
                    <Button
                        style={{
                            width: "130px",
                            margin: "0px 20px 0px 30px",
                            borderRadius: "8px",
                        }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            width: "130px",
                            borderRadius: "8px",
                        }}
                        type="primary"
                        htmlType="submit"
                    >
                        Update
                    </Button>
            </Form>
        ) : (
            <Card bordered={true} hoverable={false}>
                <Spin
                    size="large"
                    tip="Loading..."
                    spinning={loading}
                    style={{
                        height: "50vh",
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                />
            </Card>
        )}
            </>
        )
    }

    return (
        <>
            {loading === false ? (
                <Form form={zoneForm} onFinish={(val) => handleZoneCreate(val)}>
                    New Zone Name*
                    <Form.Item
                        name="zone_name"
                        rules={[
                            {
                                required: true,
                                message: "Please select zone",
                            }
                        ]}
                    >
                        <Input placeholder="Insert Zone" />
                    </Form.Item>

                    <Button
                        style={{
                            width: "130px",
                            margin: "0px 20px 0px 30px",
                            borderRadius: "8px",
                        }}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            width: "130px",
                            borderRadius: "8px",
                        }}
                        type="primary"
                        htmlType="submit"
                    >
                        Create
                    </Button>
                </Form>
            ) : (
                <Card bordered={true} hoverable={false}>
                    <Spin
                        size="large"
                        tip="Loading..."
                        spinning={loading}
                        style={{
                            height: "50vh",
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    />
                </Card>
            )}
        </>
    )
}

export default ZoneForm

