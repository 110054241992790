import React, { useState } from "react";

// Ant Design
import { Form, Input, Button, Row, Col, Card, Spin } from "antd";
import { UserOutlined, LoginOutlined } from "@ant-design/icons";

import { Link, useHistory } from "react-router-dom";
import "antd/dist/antd.css";

// File
import "./login.css";
import { forgetPasswordService } from "../../services/authentication.service";
export default function ForgotPassword(props) {
  let [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const handleSubmit = (values) => {
    setLoading(true);
    forgetPasswordService(
      {
        email: values.email,
        setLoading,
        setError,
        history
      }
    )
  };

  return (
    <Row type="flex" justify="space-around" align="middle" className="wrapper">
      <Col sm={24} md={10} lg={6}>
        <div style={{ textAlign: "center", color: "white" }}>
          <h2 style={{ color: "white", fontWeight: "bold" }}>
            Set Your Favourites!
          </h2>
          <p>
            Gather the lamp you use the most so that you can access them
            quickly.
          </p>{" "}
          <br />
        </div>
        {loading === false ? (
          <Card
            bordered={true}
            hoverable={false}
            style={{ padding: "30px", borderRadius: "10px" }}
          >
            <h2 style={{ fontWeight: "bold" }}>Forgot Password?!</h2>
            <p>
              To reset your password, enter your email addressand we will send
              you an email with
            </p>{" "}
            <br />
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={(val) => handleSubmit(val)}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Enter your email",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  style={{ width: "250px" }}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item>
                {error && (
                  <>
                    <small style={{ color: "red" }}>{error}</small>
                    <br />
                  </>
                )}
                <br />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: "100%" }}
                >
                  <LoginOutlined /> Submit
                </Button>
                <br />
                <br />
                <Button className="login-form-button" style={{ width: "100%" }}>
                  <Link to="/login">Cancel</Link>
                </Button>
              </Form.Item>
            </Form>
          </Card>
        ) : (
          <Card bordered={true} hoverable={false}>
            <Spin size="large" tip="Loading..." spinning={loading} />
          </Card>
        )}
      </Col>
    </Row>
  );
}
