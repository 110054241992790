import { createSlice } from "@reduxjs/toolkit";

const appUtilitySlice = createSlice({
  name: "apputility",
  initialState: {
    locationController: {
        selectedLocation: null,
        selectedArea: null,
        selectedZone: null,
        selectedDevice: null,
        isLocationAddButton: true,
        isAreaAddButton: false,
        isZoneAddButton: false,
        isAreaBox: false,
        isZoneBox: false,
        isDeviceBox: false,
        hideDropdown: false
    }
  },
  reducers: {
    onResetAppUtility: (state) =>{
      state.locationController = {
        selectedLocation: null,
        selectedArea: null,
        selectedZone: null,
        selectedDevice: null,
        isLocationAddButton: true,
        isAreaAddButton: false,
        isZoneAddButton: false,
        isAreaBox: false,
        isZoneBox: false,
        isDeviceBox: false,
        hideDropdown: false
      }
    },
    onResetLocation: (state, action) =>{
      state.locationController = {
        selectedLocation: action.payload,
        selectedArea: null,
        selectedZone: null,
        selectedDevice: null,
        isLocationAddButton: true,
        isAreaAddButton: false,
        isZoneAddButton: false,
        isAreaBox: false,
        isZoneBox: false,
        isDeviceBox: false,
        hideDropdown: false
      }
    },
    onLocationSelect: (state, action) => {
        state.locationController = {
            ...state.locationController,
            selectedLocation: action.payload,
            selectedArea: null,
            selectedZone: null,
            selectedDevice: null,
            isLocationAddButton: true,
            hideDropdown: true,
            isAreaAddButton: false,
            isZoneAddButton: false,
            isAreaBox: false,
            isDeviceBox: false,
            isZoneBox: false
        }
    },
    onResetArea: (state, action) => {
      state.locationController = {
          selectedLocation: null,
          selectedArea: action.payload,
          selectedZone: null,
          selectedDevice: null,
          isLocationAddButton: false,
          isAreaAddButton: true,
          isZoneAddButton: false,
          isAreaBox: true,
          isZoneBox: false,
          isDeviceBox: false,
          hideDropdown: true
      }
    },
    onAreaSelect: (state, action) => {
      state.locationController = {
          ...state.locationController,
          selectedArea: action.payload,
          selectedZone: null,
          selectedDevice: null,
          isLocationAddButton: false,
          isAreaAddButton: true,
          isZoneAddButton: false,
          isAreaBox: true,
          isZoneBox: false,
          isDeviceBox: false,
          hideDropdown: true
      }
    },
    onResetZone: (state, action) => {
      state.locationController = {
          selectedLocation: null,
          selectedArea: null,
          selectedZone: action.payload,
          selectedDevice: null,
          isLocationAddButton: false,
          isAreaAddButton: false,
          isZoneAddButton: true,
          isAreaBox: false,
          isZoneBox: true,
          isDeviceBox: false,
          hideDropdown: true
      }
    },
    onZoneSelect: (state, action) => {
      state.locationController = {
          ...state.locationController,
          selectedZone: action.payload,
          selectedDevice: null,
          isAreaAddButton: false,
          isZoneAddButton: true,
          hideDropdown: true,
          isZoneBox: true,
          isDeviceBox: false
      }
    },
    onDeviceSelect: (state, action) => {
      state.locationController = {
          ...state.locationController,
          selectedDevice: action.payload,
          isZoneAddButton: false,
          isDeviceBox: true,
          hideDropdown: true
      }
    },
  },
});

export const { 
  onResetAppUtility,
  onResetLocation,
  onLocationSelect,
  onResetArea,
  onAreaSelect,
  onResetZone,
  onZoneSelect,
  onDeviceSelect
} = appUtilitySlice.actions;

export const locationController = (state) => state.appUtility.locationController;

export default appUtilitySlice.reducer;
