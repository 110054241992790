import axios from "axios"

const url = process.env.REACT_APP_APP_API_ENDPOINT


export const locationListService = async ({token,setError,setLoading}) => {
    try {
        let result = await axios.get(url + '/api/v1/locations', {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.data.status){
            return result.data
        }
    }catch(err){
        console.log(err.response)
        setLoading(false)
        setError("location list service not working")
    }
}

export const locationSingleService = async ({token,id,setError,setLoading}) => {
    try {
        let result = await axios.get(url + `/api/v1/locations/${id}`, {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.data.status){
            return result.data
        }
    }catch(err){
        setLoading(false)
       // setError(err.response.data.message)
        setError("location single service not working")
    }
}

export const locationCreateService = async ({
    token,
    city, 
    lane_1,
    lane_2,
    location_name,
    state,
    timezone,
    zip,
    setError,
    setLoading
    }) => {
    try {
        let result = await axios.post(url + '/api/v1/locations', 
        { 
            city, 
            lane_1,
            lane_2,
            location_name,
            state,
            timezone,
            zip
        },{
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.data){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const locationUpdateService = async ({
    token,
    id,
    city, 
    lane_1,
    lane_2,
    location_name,
    state,
    timezone,
    zip,
    setError,
    setLoading
    }) => {
    try {
        let result = await axios.put(url + `/api/v1/locations/${id}`, 
        { 
            city, 
            lane_1,
            lane_2,
            location_name,
            state,
            timezone,
            zip
        },{
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.data){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.errors.location_name[0])
    }
}

export const locationDeleteService = async ({ token, id, setLoading, setError }) => {
    try {
        let result = await axios.delete(url + `/api/v1/locations/${id}`, {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.data){
            return result.data
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}

export const locationFindByIDService  = async ({token,id,setLoading,setError}) => {
    try {
        let result = await axios.get(url + `/api/v1/locations/${id}`, {
            headers: {
                "Authorization" : `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
        if(result.data){
            console.log(result.data)
        }
    }catch(err){
        setLoading(false)
        setError(err.response.data.message)
    }
}