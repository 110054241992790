import React, { useState, useEffect, useCallback } from "react";

import {
  Form,
  Card,
  Table,
  Dropdown,
  Row,
  Col,
  DatePicker,
  Button,
  Select,
  Menu,
} from "antd";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Table-UI
import LocationTable from "../../components/Location/LocationTable"
import AreaTable from "../../components/Area/AreaTable";
import ZoneTable from "../../components/Zone/ZoneTable";
import DeviceTable from "../../components/Device/DeviceTable";

import BarGraph from "../../components/Statistic/BarGraph";

import RedAlert from "./../../assets/images/red_alert.png";
import BlueAlert from "./../../assets/images/blue_alert.png";
import GreyAlert from "./../../assets/images/grey_alert.png";

// Auth-Service
import {  getToken, getUser } from "../../utils/AuthService";

// API-Service
import { locationListService } from "../../services/location.service";
import { areaListService } from "../../services/area.service";
import { zoneListService } from "../../services/zone.service";

// Redux-Settings
import { selectUserType } from "../../redux/userSlice";
import { setLocationList, selectLocationList } from "../../redux/locationSlice";
import { setAreaList, selectAreaList } from "../../redux/areaSlice";
import { setZoneList, selectZoneList } from "../../redux/zoneSlice";

const { RangePicker } = DatePicker;
const { Option } = Select;
const Dashboard = (props) => {
  
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const areaColumn = [
    {
      title: "Location",
      dataIndex: "locationName",
      filters: [
        {
          text: "Location #1",
          value: "Location #1",
        },
        {
          text: "Location #2",
          value: "Location #2",
        },
        {
          text: "Location #3",
          value: "Location #3",
        },
      ],
      onFilter: (value, record) => record.locationName.indexOf(value) === 0,
      sorter: (a, b) => {
        a = a.locationName || "";
        b = b.locationName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Area",
      dataIndex: "areaName",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Area #1",
          value: "Area #1",
        },
        {
          text: "Area #2",
          value: "Area #2",
        },
        {
          text: "Area #3",
          value: "Area #3",
        },
      ],
      sorter: (a, b) => {
        a = a.areaName || "";
        b = b.areaName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Zone",
      dataIndex: "zoneName",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Zone A",
          value: "Zone A",
        },
        {
          text: "Zone B",
          value: "Zone B",
        },
        {
          text: "Zone C",
          value: "Zone C",
        },
      ],
      sorter: (a, b) => {
        a = a.zoneName || "";
        b = b.zoneName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Device",
      dataIndex: "deviceName",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Dimmer",
          value: "Dimmer",
        },
        {
          text: "Light Sensor",
          value: "Light Sensor",
        },
        {
          text: "Controller",
          value: "Controller",
        },
      ],
      sorter: (a, b) => {
        a = a.deviceName || "";
        b = b.deviceName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Not Connected",
          value: "Not Connected",
        },
        {
          text: "Malfunction",
          value: "Malfunction",
        },
      ],
      sorter: (a, b) => {
        a = a.status || "";
        b = b.status || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
      render: (record) => {
        if (record === "Connected") {
          return (
            <>
              <img src={BlueAlert} style={{ height: "23px" }} /> Connected
            </>
          );
        } else if (record === "Not Connected") {
          return (
            <>
              <img src={RedAlert} style={{ height: "23px" }} /> Not Connected
            </>
          );
        } else if (record === "Mulfunction") {
          return (
            <>
              <img src={GreyAlert} style={{ height: "23px" }} /> Mulfunction
            </>
          );
        }
      },
    },
    {
      title: "Days Out",
      dataIndex: "days_out",
      sorter: (a, b) => {
        a = a.days_out || "";
        b = b.days_out || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Dropdown overlay={selector(record)}>
          {/* < rotate={90} /> */}
          <Button type="default">Check Device</Button>
        </Dropdown>
      ),
    },
  ];

  const dispatch = useDispatch();
  const locations = useSelector(selectLocationList);
  const areas = useSelector(selectAreaList);
  const zones = useSelector(selectZoneList);
  let userType = useSelector(selectUserType);

  const history = useHistory();
  const user = getUser();
  const token = getToken();

  const handleDelete = (record) => {
    //console.log(record)
  };

  useEffect(async() => {
    setLoading(true)
    if(user?.role === userType?.admin || 
      user?.role === userType?.location_owner){
      const result = await locationListService({
        token,
        setError,
        setLoading
      })
      dispatch(
        setLocationList(result?.data)
      )
    }

    if(user?.role === userType?.admin || 
      user?.role === userType?.location_owner || 
      user?.role === userType?.area_owner ){
      const result = await areaListService({
        token,
        setError,
        setLoading
      })
      dispatch(
        setAreaList(result?.data)
      )
    }

    if(user?.role === userType?.admin || 
      user?.role === userType?.location_owner || 
      user?.role === userType?.area_owner || 
      user?.role === userType?.zone_owner){
      const result = await zoneListService({
        token,
        setError,
        setLoading
      })
      dispatch(
        setZoneList(result?.data)
      )
    }
    setLoading(false)
  },[])

  let selector = (record) => (
    <Menu>
      <Menu.Item key="1">Edit</Menu.Item>
      <Menu.Item onClick={() => handleDelete(record)} key="2">
        Delete
      </Menu.Item>
    </Menu>
  );

  function handleChange(value) {
   // console.log(`selected ${value}`);
  }

  return (
    <div className="content-layout">
      <div>
        <Row>
          <Col span={5}>
            <Card
              style={{
                borderStartStartRadius: "10px",
                borderEndStartRadius: "10px",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h5 style={{ color: "#1d83f7" }}>Total of locations</h5>
                <h1>{locations?.length}</h1>
              </div>
            </Card>
          </Col>
          <Col span={5}>
            <Card>
              <div style={{ textAlign: "center" }}>
                <h5 style={{ color: "#1d83f7" }}>Total of areas</h5>
                <h1>{areas?.length}</h1>
              </div>
            </Card>
          </Col>
          <Col span={5}>
            <Card
              style={{
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h5 style={{ color: "#1d83f7" }}>Total of zones</h5>
                <h1>{zones?.length}</h1>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <br />

      <div>
        <h1 style={{ fontWeight: "bold", color: "#054792" }}>
          Total Energy Usage
        </h1>
        <Row>
          <Col span={5}>
            <Card
              style={{
                borderStartStartRadius: "10px",
                borderEndStartRadius: "10px",
                height: "100%",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h5 style={{ color: "#818181" }}>Current demand</h5>
                <h1>20 kw.h</h1>
              </div>
            </Card>
          </Col>
          <Col span={5}>
            <Card style={{ height: "100%" }}>
              <div style={{ textAlign: "center" }}>
                <h5 style={{ color: "#818181" }}>Last 7 days</h5>
                <h1>25 kw.h</h1>
              </div>
            </Card>
          </Col>
          <Col span={5}>
            <Card style={{ height: "100%" }}>
              <div style={{ textAlign: "center" }}>
                <h5 style={{ color: "#818181" }}>Last 30 days</h5>
                <h1>5 kw.h</h1>
              </div>
            </Card>
          </Col>
          <Col span={5}>
            <Card
              style={{
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h5 style={{ color: "#818181" }}>
                  YTD <br />
                  (2020 Total Consumption)
                </h5>
                <h1>49 kw.h</h1>
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <br />

      <div>
        <h1 style={{ fontWeight: "bold", color: "#054792" }}>
          Total Energy Costs
        </h1>
        <Row>
          <Col span={5}>
            <Card
              style={{
                borderStartStartRadius: "10px",
                borderEndStartRadius: "10px",
                height: "100%",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h5 style={{ color: "#818181" }}>Current demand</h5>
                <h1>$20.00</h1>
              </div>
            </Card>
          </Col>
          <Col span={5}>
            <Card style={{ height: "100%" }}>
              <div style={{ textAlign: "center" }}>
                <h5 style={{ color: "#818181" }}>Last 7 days</h5>
                <h1>$50.00</h1>
              </div>
            </Card>
          </Col>
          <Col span={5}>
            <Card style={{ height: "100%" }}>
              <div style={{ textAlign: "center" }}>
                <h5 style={{ color: "#818181" }}>Last 30 days</h5>
                <h1>$135.00</h1>
              </div>
            </Card>
          </Col>
          <Col span={5}>
            <Card
              style={{
                borderTopRightRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h5 style={{ color: "#818181" }}>
                  YTD <br />
                  (2020 Total Consumption)
                </h5>
                <h1>$230.00</h1>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <br />
      <div>
        <h1 style={{ fontWeight: "bold", color: "#054792" }}>
          Attention Required
        </h1>
        <Table columns={areaColumn} dataSource={areas} pagination={false} />
      </div>
      <br />

      { user?.role === userType?.admin || user?.role === userType?.location_owner ? 
        (<div>
          <h1 style={{ fontWeight: "bold", color: "#054792" }}>
            List of Locations
          </h1>
          <LocationTable/>
          <br />
        </div>) : ("")
      }

      { user?.role === userType?.admin || 
      user?.role === userType?.location_owner || 
      user?.role === userType?.area_owner ?
        (<div>
          <h1 style={{ fontWeight: "bold", color: "#054792" }}>
            List of Areas
          </h1>
          <AreaTable/>
          <br />
        </div> ) : ("")
      }

       { user?.role === userType?.admin || 
       user?.role === userType?.location_owner ||
       user?.role === userType?.area_owner ||
       user?.role === userType?.zone_owner ? 
        (<div>
          <h1 style={{ fontWeight: "bold", color: "#054792" }}>
            List of Zones
          </h1>
          <ZoneTable/>
          <br />
        </div>) : ("")
      }
      <br />
      <div>
        <Row>
          <Col span={21}>
            <h1 style={{ fontWeight: "bold", color: "#054792" }}>
              Summary of Total Energy Use
            </h1>
          </Col>
          <Col span={3}>
            <a style={{ fontWeight: "bold", textAlign: "end" }}>
              Export Report
            </a>
          </Col>
        </Row>
        <Card>
          <Row>
            <Col span={16}>
              <h5>SELECT DATE RANGE</h5>
              <RangePicker
                ranges={{
                  Today: [moment(), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
              />
            </Col>
            <Col span={4}>
              <h5>LOCATION</h5>
              <Select
                defaultValue="Location #1"
                style={{ width: 120 }}
                onChange={handleChange}
              >
                <Option value="Location #1">Location #1</Option>
                <Option value="Location #2">Location #2</Option>
                <Option value="Location #3">Location #3</Option>
              </Select>
            </Col>
            <Col span={4}>
              <h5>AREA</h5>
              <Select defaultValue="Area #1" style={{ width: 120 }}>
                <Option value="Area #1">Area #1</Option>
                <Option value="Area #2">Area #2</Option>
                <Option value="Area #3">Area #3</Option>
              </Select>
            </Col>
          </Row>
          <br />
          <br />
          <BarGraph />
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
