import { createSlice } from "@reduxjs/toolkit";

const zoneSlice = createSlice({
  name: "zones",
  initialState: {
    zoneList: [],
    zoneSingle: [],
    assignedUserList: [],
    assignedZoneId: null,
    zoneSettings: {
      brightness_level: 0,
      max_trim_level: 0,
      color_temperature: 50
    },
    zoneOccupancy: {
      vacant_time: 0,
      dim_to_level: 0,
      hold_time_id: 1
    },
    zoneDaylight: {
      vacant_time: 0,
      dim_to_level: 0,
      hold_time_id: 1,
      sensitivity: 0,
      lux_min: 0,
      lux_max: 0,
      max_trim_level: 0
    }
  },
  reducers: {
    addZone: (state, action) => {
      const newZone = {
        id: action.payload.id,
        zone_name: action.payload.zone_name,
        location_id: action.payload.location_id,
        Zone_id: action.payload.Zone_id,
        created_at: action.payload.created_at,
        updated_at: action.payload.updated_at
      };
      state.zoneList.push(newZone);
    },
    setZoneList: (state, action) => {
      state.zoneList = action.payload;
    },
    setSingleZone: (state, action) => {
      state.zoneSingle = action.payload;
    },
    updateZone: (state, action) => {
      const index = state.zoneList.findIndex((item) => item.id === action.payload.id);
      state.zoneList[index] = action.payload;
    },
    deleteZone: (state, action) => {
      state.zoneList = state.zoneList?.filter((item) => item.id !== action.payload);
    },
    setAssignedUser: (state, action) => {
      state.assignedUserList = action.payload;
    },
    setAssignedZoneId: (state, action) => {
      state.assignedZoneId = action.payload;
    },
    setZoneSettings: (state, action) => {
      state.zoneSettings = {
        ...state.ZoneSettings,
        brightness_level: action.payload.brightness_level,
        max_trim_level: action.payload.max_trim_level,
        color_temperature: action.payload.color_temperature
      }
    },
    setZoneOccupancy: (state, action) => {
      state.zoneOccupancy = {
        ...state.zoneOccupancy,
        vacant_time: action.payload.vacant_time,
        dim_to_level: action.payload.dim_to_level,
        hold_time_id: action.payload.hold_time_id
      }
    },
    setZoneDaylight: (state, action) => {
      state.zoneDaylight = {
        ...state.zoneDaylight,
        vacant_time: action.payload.vacant_time,
        dim_to_level: action.payload.dim_to_level,
        hold_time_id: action.payload.hold_time_id,
        sensitivity: action.payload.sensitivity,
        lux_min: action.payload.lux_min,
        lux_max: action.payload.lux_max,
        max_trim_level: action.payload.max_trim_level,
      }
    }
    // renameZoneTitle: (state, action) => {
    //   const index = state.findIndex((item) => item.id === action.payload.id);
    //   state[index].title = action.payload.title;
    // },
    // deleteZone: (state, action) => {
    //   return state.find(
    //     (item) => 
    //       (item.id !== action.payload.id)
    //     // item.id !== action.payload.id
    //   );
    // },
  },
});

export const { 
  setZoneList,
  setSingleZone,
  addZone,
  deleteZone,
  updateZone,
  setAssignedZoneId,
  setAssignedUser,
  setZoneSettings,
  setZoneDaylight,
  setZoneOccupancy
} = zoneSlice.actions;

export const selectZoneList = (state) => state.zones.zoneList;
export const selectSingleZone = (state) => state.zones.zoneSingle;
export const selectZoneAssignedUserList = (state) => state.zones.assignedUserList;
export const selectZoneAssignedId = (state) => state.zones.assignedZoneId
export const selectZoneSettings = (state) => state.zones.zoneSettings
export const selectZoneOccupancy = (state) => state.zones.zoneOccupancy
export const selectZoneDaylight = (state) => state.zones.zoneDaylight
export default zoneSlice.reducer;
