import React, { useState, useEffect } from "react";

import {
  Form,
  Card,
  Table,
  Dropdown,
  Row,
  Col,
  DatePicker,
  Button,
  Select,
  Menu,
  Space,
  Spin,
  Modal
} from "antd";
import {
  PlusCircleFilled
} from "@ant-design/icons";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";

import BarGraph from "../../components/Statistic/BarGraph";
import RedAlert from "./../../assets/images/red_alert.png";
import BlueAlert from "./../../assets/images/blue_alert.png";
import GreyAlert from "./../../assets/images/grey_alert.png";
import AddNewArea from "./AddNewArea";
import AddNewLocation from "./AddNewLocation";
import MasterOverride from "./MasterOverride";

// Table-UI
import LocationTable from "../../components/Location/LocationTable";
import AreaTable from "../../components/Area/AreaTable";
import ZoneTable from "../../components/Zone/ZoneTable";
import DeviceTable from "../../components/Device/DeviceTable";

// Services-Settings
import { getUser,getToken  } from "../../utils/AuthService";
import { areaListService } from "../../services/area.service";

// Form-UI
import AreaForm from "../../components/Area/AreaForm"

// Redux-Settings
import { selectUserType } from "../../redux/userSlice";
import { setAreaList, selectAreaList } from "../../redux/areaSlice";
import { selectLocationList, selectSingleLocation } from "../../redux/locationSlice";

const { RangePicker } = DatePicker;
const { Option } = Select;
const Location = ({locationInfo}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [addZoneForm] = Form.useForm();
  const [isAddAreaModal, setAddAreaModal] = useState(false);
  const dispatch = useDispatch();
  const token = getToken()
  const user = getUser();
  let areas = useSelector(selectAreaList);
  let locations = useSelector(selectLocationList);
  let singleLocationData = useSelector(selectSingleLocation);
  let userType = useSelector(selectUserType);
  
  //const filteredArea = areas.filter((x) => x.id === selectedLocation);
  // console.log(areas)
  // useEffect(async() => {
  //   setLoading(true)
  //   const result = await areaListService({
  //       token,
  //       setLoading,
  //       setError
  //   })
  //   if(result?.data){
  //       dispatch(
  //         setAreaList(result?.data)
  //       )
  //       setLoading(false)
  //   }
  // },[])

  const areaColumn = [
    {
      title: "Location",
      dataIndex: "locationName",
      filters: [
        {
          text: "Location #1",
          value: "Location #1",
        },
        {
          text: "Location #2",
          value: "Location #2",
        },
        {
          text: "Location #3",
          value: "Location #3",
        },
      ],
      onFilter: (value, record) => record.locationName.indexOf(value) === 0,
      sorter: (a, b) => {
        a = a.locationName || "";
        b = b.locationName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Area",
      dataIndex: "areaName",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Area #1",
          value: "Area #1",
        },
        {
          text: "Area #2",
          value: "Area #2",
        },
        {
          text: "Area #3",
          value: "Area #3",
        },
      ],
      sorter: (a, b) => {
        a = a.areaName || "";
        b = b.areaName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Zone",
      dataIndex: "zoneName",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Zone A",
          value: "Zone A",
        },
        {
          text: "Zone B",
          value: "Zone B",
        },
        {
          text: "Zone C",
          value: "Zone C",
        },
      ],
      sorter: (a, b) => {
        a = a.zoneName || "";
        b = b.zoneName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Device",
      dataIndex: "deviceName",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Dimmer",
          value: "Dimmer",
        },
        {
          text: "Light Sensor",
          value: "Light Sensor",
        },
        {
          text: "Controller",
          value: "Controller",
        },
      ],
      sorter: (a, b) => {
        a = a.deviceName || "";
        b = b.deviceName || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      defaultSortOrder: "descend",
      filters: [
        {
          text: "Not Connected",
          value: "Not Connected",
        },
        {
          text: "Malfunction",
          value: "Malfunction",
        },
      ],
      sorter: (a, b) => {
        a = a.status || "";
        b = b.status || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
      render: (record) => {
        if (record === "Connected") {
          return (
            <>
              <img src={BlueAlert} style={{ height: "23px" }} /> Connected
            </>
          );
        } else if (record === "Not Connected") {
          return (
            <>
              <img src={RedAlert} style={{ height: "23px" }} /> Not Connected
            </>
          );
        } else if (record === "Mulfunction") {
          return (
            <>
              <img src={GreyAlert} style={{ height: "23px" }} /> Mulfunction
            </>
          );
        }
      },
    },
    {
      title: "Days Out",
      dataIndex: "days_out",
      sorter: (a, b) => {
        a = a.days_out || "";
        b = b.days_out || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Dropdown overlay={selector(record)}>
          {/* < rotate={90} /> */}
          <Button type="default">Check Device</Button>
        </Dropdown>
      ),
    },
  ];

  const handleDelete = (record) => {
    console.log(record)
  };

  const showAddAreaModal = () => {
    setAddAreaModal(true);
  };

  let selector = (record) => (
    <Menu>
      <Menu.Item key="1">Edit</Menu.Item>
      <Menu.Item onClick={() => handleDelete(record)} key="2">
        Delete
      </Menu.Item>
    </Menu>
  );
  
  if(error){
    setTimeout(
      () => setError(null), 
      3000
    );
      return(
          <p style={{ 
          color: "red",
          height: "100vh", 
          display: 'flex', 
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          fontSize: '18px'
          }}>
          {error}
          </p>
      )
  }
  return (
    <div>
      {loading === false ? (
      <div className="content-layout">
        { singleLocationData?.dashboard_data?.list_of_areas?.areas?.length <= 0   ? (
          <AddNewArea id={singleLocationData?.id} />
        ) : locations?.length <= 0 ? (
            <AddNewLocation />
        ) : (
          <>
            <Space size={30}>
              <Card>
                <div style={{ textAlign: "center" }}>
                  <h5 style={{ color: "#1d83f7" }}>Total of areas</h5>
                  <h1>{singleLocationData?.dashboard_data?.no_of_regions?.total_areas}</h1>
                </div>
              </Card>
              <Card>
                <div style={{ textAlign: "center" }}>
                  <h5 style={{ color: "#1d83f7" }}>Total of zones</h5>
                  <h1>{singleLocationData?.dashboard_data?.no_of_regions?.total_zones}</h1>
                </div>
              </Card>
              {
                user?.role === userType?.admin  && 
                <MasterOverride locationInfo={singleLocationData} />
              }
            </Space>
            <br />
            <br />
            <div>
              <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                Total Energy Usage
              </h1>
              <Row>
                <Col span={5}>
                  <Card
                    style={{
                      borderStartStartRadius: "10px",
                      borderEndStartRadius: "10px",
                      height: "100%",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <h5 style={{ color: "#818181" }}>Current demand</h5>
                      <h1>20 kw.h</h1>
                    </div>
                  </Card>
                </Col>
                <Col span={5}>
                  <Card style={{ height: "100%" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5 style={{ color: "#818181" }}>Last 7 days</h5>
                      <h1>25 kw.h</h1>
                    </div>
                  </Card>
                </Col>
                <Col span={5}>
                  <Card style={{ height: "100%" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5 style={{ color: "#818181" }}>Last 30 days</h5>
                      <h1>5 kw.h</h1>
                    </div>
                  </Card>
                </Col>
                <Col span={5}>
                  <Card
                    style={{
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <h5 style={{ color: "#818181" }}>
                        YTD <br />
                        (2020 Total Consumption)
                      </h5>
                      <h1>49 kw.h</h1>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>

            <br />

            <div>
              <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                Total Energy Costs
              </h1>
              <Row>
                <Col span={5}>
                  <Card
                    style={{
                      borderStartStartRadius: "10px",
                      borderEndStartRadius: "10px",
                      height: "100%",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <h5 style={{ color: "#818181" }}>Current demand</h5>
                      <h1>$20.00</h1>
                    </div>
                  </Card>
                </Col>
                <Col span={5}>
                  <Card style={{ height: "100%" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5 style={{ color: "#818181" }}>Last 7 days</h5>
                      <h1>$50.00</h1>
                    </div>
                  </Card>
                </Col>
                <Col span={5}>
                  <Card style={{ height: "100%" }}>
                    <div style={{ textAlign: "center" }}>
                      <h5 style={{ color: "#818181" }}>Last 30 days</h5>
                      <h1>$135.00</h1>
                    </div>
                  </Card>
                </Col>
                <Col span={5}>
                  <Card
                    style={{
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <h5 style={{ color: "#818181" }}>
                        YTD <br />
                        (2020 Total Consumption)
                      </h5>
                      <h1>$230.00</h1>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
            <br />
            <div>
              <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                Attention Required
              </h1>
              <Table
                columns={areaColumn}
                dataSource={areas}
                pagination={false}
              />
            </div>
            <br />

            {/* {user?.role === 'admin' && 
              <div>
                <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                  List of Locations
                </h1>
                <LocationTable/>
              </div>
            }
           */}
           
            {user?.role === userType?.admin || user?.role === userType?.location_owner ? 
              (<div>
                <div style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}>
                  <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                    List of Areas
                  </h1>
                  <div>
                    <h1 style={{ fontWeight: "bold", color: "#096dd9", display: "inline-flex" }}>
                      Add Area
                    </h1>
                  <Button
                    type="link"
                    onClick={() => showAddAreaModal()}
                    icon={<PlusCircleFilled />} />
                    </div>
                </div>
                <AreaTable areasData={singleLocationData?.dashboard_data?.list_of_areas?.areas}  />
              </div>):("")
            }

            {/* {
              user?.role === userType?.area_owner && 
              <div>
                <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                  List of Zones
                </h1>
                <ZoneTable />
              </div>
            }

            {
              user?.role === userType?.zone_owner && 
              <div>
                <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                  List of Devices
                </h1>
                <DeviceTable />
              </div>
            } */}
            <br />
            <div>
              <Row>
                <Col span={21}>
                  <h1 style={{ fontWeight: "bold", color: "#054792" }}>
                    Summary of Total Energy Use
                  </h1>
                </Col>
                <Col span={3}>
                  <a style={{ fontWeight: "bold", textAlign: "end" }}>
                    Export Report
                  </a>
                </Col>
              </Row>
              <Card>
                <Row>
                  <Col span={16}>
                    <h5>SELECT DATE RANGE</h5>
                    <RangePicker
                      ranges={{
                        Today: [moment(), moment()],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                      }}
                    />
                  </Col>
                  <Col span={4}>
                    <h5>LOCATION</h5>
                    <Select defaultValue="Location #1" style={{ width: 120 }}>
                      <Option value="Location #1">Location #1</Option>
                      <Option value="Location #2">Location #2</Option>
                      <Option value="Location #3">Location #3</Option>
                    </Select>
                  </Col>
                  <Col span={4}>
                    <h5>AREA</h5>
                    <Select defaultValue="Area #1" style={{ width: 120 }}>
                      <Option value="Area #1">Area #1</Option>
                      <Option value="Area #2">Area #2</Option>
                      <Option value="Area #3">Area #3</Option>
                    </Select>
                  </Col>
                </Row>
                <br />
                <br />
                <BarGraph />
              </Card>
            </div>
          </>
        )}
      </div>
      ) : (
          <Card bordered={true} hoverable={false}>
            <Spin 
                size="large" 
                tip="Loading..." 
                spinning={loading}
                style={{ 
                height: "50vh", 
                display: 'flex', 
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center'
                }}
            />
          </Card>
      )}
      <Modal
        title="Area Create Form"
        width={400}
        centered
        visible={isAddAreaModal}
        footer={null}
        closable={false}
        style={{
          borderRadius: "8px",
        }}
      >
        <AreaForm setModal={setAddAreaModal} /> 
      </Modal>
    </div>
  );
};

export default Location;
